import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    errors: null,
    isLoading: false,
    isSucceed: null,
    isSucceedUserRegister: null,
    userInfo: null,
    isSucceedUserInfo: null,
    addressInfo: null,
    isSucceedAddressInfo: null,
};
const registerStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const registerSuccess = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const registerFail = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const registerReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};

const userRegisterStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const userRegisterSuccess = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedUserRegister: action.isSucceedUserRegister,
    });
};
const userRegisterFail = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const userRegisterReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedUserRegister: action.isSucceedUserRegister,
    });
};

const saveUserInfo = (state, action) => {
    return updateObject(state, {
        userInfo: action.userInfo,
        isSucceedUserInfo: action.isSucceedUserInfo
    });
};
const saveUserInfoReset = (state, action) => {
    return updateObject(state, {
        userInfo: action.userInfo,
        isSucceedUserInfo: action.isSucceedUserInfo
    });
};
const saveAddressInfo = (state, action) => {
    return updateObject(state, {
        addressInfo: action.addressInfo,
        isSucceedAddressInfo: action.isSucceedAddressInfo
    });
};
const saveAddressInfoReset = (state, action) => {
    return updateObject(state, {
        addressInfo: action.addressInfo,
        isSucceedAddressInfo: action.isSucceedAddressInfo
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REGISTER_START:
            return registerStart(state,action);
        case actionTypes.REGISTER_SUCCESS:
            return registerSuccess(state,action);
        case actionTypes.REGISTER_FAIL:
            return registerFail(state,action);
        case actionTypes.REGISTER_RESET:
            return registerReset(state,action);
        case actionTypes.USER_REGISTER_START:
            return userRegisterStart(state, action);
        case actionTypes.USER_REGISTER_SUCCESS:
            return userRegisterSuccess(state, action);
        case actionTypes.USER_REGISTER_FAIL:
            return userRegisterFail(state, action);
        case actionTypes.USER_REGISTER_RESET:
            return userRegisterReset(state, action);
        case actionTypes.SAVE_USER_INFO:
            return saveUserInfo(state, action);
        case actionTypes.SAVE_USER_INFO_RESET:
            return saveUserInfoReset(state, action);
        case actionTypes.SAVE_ADDRESS_INFO:
            return saveAddressInfo(state, action);
        case actionTypes.SAVE_ADDRESS_INFO_RESET:
            return saveAddressInfoReset(state, action);
        default:
            return state;
    };
};

export default reducer;