import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAssign: null,
    isSucceedForGet: null,
};
const assignSurveyStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const assignSurveySuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAssign: action.isSucceedForAssign,
    });
};
const assignSurveyFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const assignSurveyReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAssign: action.isSucceedForAssign,
    });
};
const getByDepartmentCodeStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByDepartmentCodeSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getByDepartmentCodeFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByDepartmentCodeReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ASSIGN_SURVEY_TO_DEPARTMENT_START:
            return assignSurveyStart(state,action);
        case actionTypes.ASSIGN_SURVEY_TO_DEPARTMENT_SUCCESS:
            return assignSurveySuccess(state,action);
        case actionTypes.ASSIGN_SURVEY_TO_DEPARTMENT_FAIL:
            return assignSurveyFail(state,action);
        case actionTypes.ASSIGN_SURVEY_TO_DEPARTMENT_RESET:
            return assignSurveyReset(state,action);
        case actionTypes.GET_DEPARTMENT_SURVEY_START:
            return getByDepartmentCodeStart(state, action);
        case actionTypes.GET_DEPARTMENT_SURVEY_SUCCESS:
            return getByDepartmentCodeSuccess(state, action);
        case actionTypes.GET_DEPARTMENT_SURVEY_FAIL:
            return getByDepartmentCodeFail(state, action);
        case actionTypes.GET_DEPARTMENT_SURVEY_RESET:
            return getByDepartmentCodeReset(state, action);
        default:
            return state;
    };
};
export default reducer;