import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForGetByCode: null,
    isSucceedForGetBySubCode: null,
    isSucceedForGetRootDocuments: null,
    isSucceedForGetUpDocuments: null,
    isSucceedForDelete: null,
};
const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const getDocumentDriveByCodeStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getDocumentDriveByCodeSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByCode: action.isSucceedForGetByCode,
    });
};
const getDocumentDriveByCodeFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getDocumentDriveByCodeReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByCode: action.isSucceedForGetByCode,
    });
};
const getDocumentDriveBySubCodeStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getDocumentDriveBySubCodeSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetBySubCode: action.isSucceedForGetBySubCode,
    });
};
const getDocumentDriveBySubCodeFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getDocumentDriveBySubCodeReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetBySubCode: action.isSucceedForGetBySubCode,
    });
};
const getRootDocumentsStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getRootDocumentsSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetRootDocuments: action.isSucceedForGetRootDocuments,
    });
};
const getRootDocumentsFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getRootDocumentsReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetRootDocuments: action.isSucceedForGetRootDocuments,
    });
};
const getUpDocumentsStart = (state, action) => {
    return updateObject(state,{
        isLoading: action.isLoading
    });
};
const getUpDocumentsSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetUpDocuments: action.isSucceedForGetUpDocuments,
    });
};
const getUpDocumentsFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getUpDocumentsReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetUpDocuments: action.isSucceedForGetUpDocuments,
    });
};
const deleteDocumentStart = (state, action) => {
    return updateObject(state,{
        isLoading: action.isLoading
    });
};
const deleteDocumentSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForDelete: action.isSucceedForDelete,
    });
};
const deleteDocumentFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const deleteDocumentReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForDelete: action.isSucceedForDelete,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_DOCUMENT_DRIVE_START:
            return addStart(state,action);
        case actionTypes.ADD_DOCUMENT_DRIVE_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_DOCUMENT_DRIVE_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_DOCUMENT_DRIVE_RESET:
            return addReset(state,action);
        case actionTypes.GET_DOCUMENT_DRIVE_BY_CODE_START:
            return getDocumentDriveByCodeStart(state, action);
        case actionTypes.GET_DOCUMENT_DRIVE_BY_CODE_SUCCESS:
            return getDocumentDriveByCodeSuccess(state, action);
        case actionTypes.GET_DOCUMENT_DRIVE_BY_CODE_FAIL:
            return getDocumentDriveByCodeFail(state, action);
        case actionTypes.GET_DOCUMENT_DRIVE_BY_CODE_RESET:
            return getDocumentDriveByCodeReset(state, action);
        case actionTypes.GET_DOCUMENTS_DRIVE_BY_SUB_CODE_START:
            return getDocumentDriveBySubCodeStart(state, action);
        case actionTypes.GET_DOCUMENTS_DRIVE_BY_SUB_CODE_SUCCESS:
            return getDocumentDriveBySubCodeSuccess(state, action);
        case actionTypes.GET_DOCUMENTS_DRIVE_BY_SUB_CODE_FAIL:
            return getDocumentDriveBySubCodeFail(state, action);
        case actionTypes.GET_DOCUMENTS_DRIVE_BY_SUB_CODE_RESET:
            return getDocumentDriveBySubCodeReset(state, action);
        case actionTypes.GET_ROOT_DOCUMENTS_START:
            return getRootDocumentsStart(state, action);
        case actionTypes.GET_ROOT_DOCUMENTS_SUCCESS:
            return getRootDocumentsSuccess(state, action);
        case actionTypes.GET_ROOT_DOCUMENTS_FAIL:
            return getRootDocumentsFail(state, action);
        case actionTypes.GET_ROOT_DOCUMENTS_RESET:
            return getRootDocumentsReset(state, action);
        case actionTypes.GET_UP_DOCUMENTS_START:
            return getUpDocumentsStart(state, action);
        case actionTypes.GET_UP_DOCUMENTS_SUCCESS:
            return getUpDocumentsSuccess(state, action);
        case actionTypes.GET_UP_DOCUMENTS_FAIL:
            return getUpDocumentsFail(state, action);
        case actionTypes.GET_UP_DOCUMENTS_RESET:
            return getUpDocumentsReset(state, action);
        case actionTypes.DELETE_DOCUMENT_START:
            return deleteDocumentStart(state, action);
        case actionTypes.DELETE_DOCUMENT_SUCCESS:
            return deleteDocumentSuccess(state, action);
        case actionTypes.DELETE_DOCUMENT_FAIL:
            return deleteDocumentFail(state, action);
        case actionTypes.DELETE_DOCUMENT_RESET:
            return deleteDocumentReset(state, action);    
        default:
            return state;
    };
};

export default reducer;