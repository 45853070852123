import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForGetAll: null,
    isSucceedForGet: null,
    isSucceedForPut: null,
    isSucceedForDelete: null,
    isSucceedForGetByKey: null
};
const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const getStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getAllStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getAllSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getAllFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getAllReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const putStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const putSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForPut: action.isSucceedForPut,
    });
};
const putFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const putReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForPut: action.isSucceedForPut,
    });
};
const getByKeyStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByKeySuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByKey: action.isSucceedForGetByKey,
    });
};
const getByKeyFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByKeyReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByKey: action.isSucceedForGetByKey,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_SETTING_START:
            return addStart(state,action);
        case actionTypes.ADD_SETTING_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_SETTING_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_SETTING_RESET:
            return addReset(state,action);
        case actionTypes.GET_SETTING_START:
            return getStart(state, action);
        case actionTypes.GET_SETTING_SUCCESS:
            return getSuccess(state, action);
        case actionTypes.GET_SETTING_FAIL:
            return getFail(state, action);
        case actionTypes.GET_SETTING_RESET:
            return getReset(state, action);
        case actionTypes.GET_ALL_SETTING_START:
            return getAllStart(state, action);
        case actionTypes.GET_ALL_SETTING_SUCCESS:
            return getAllSuccess(state, action);
        case actionTypes.GET_ALL_SETTING_FAIL:
            return getAllFail(state, action);
        case actionTypes.GET_ALL_SETTING_RESET:
            return getAllReset(state, action);
        case actionTypes.PUT_SETTING_START:
            return putStart(state, action);
        case actionTypes.PUT_SETTING_SUCCESS:
            return putSuccess(state, action);
        case actionTypes.PUT_SETTING_FAIL:
            return putFail(state, action);
        case actionTypes.PUT_SETTING_RESET:
            return putReset(state, action);
        case actionTypes.GET_SETTING_BY_KEY_START:
            return getByKeyStart(state, action);
        case actionTypes.GET_SETTING_BY_KEY_SUCCESS:
            return getByKeySuccess(state, action);
        case actionTypes.GET_SETTING_BY_KEY_FAIL:
            return getByKeyFail(state, action);
        case actionTypes.GET_SETTING_BY_KEY_RESET:
            return getByKeyReset(state, action);
        default:
            return state;
    };
};

export default reducer;