import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForGetAll: null,
    isSucceedForGet: null,
    isSucceedForPut: null,
    isSucceedForDelete: null,
    isSucceedForComplete: null,
    isSucceedForGetHalfEducations: null,
    isSucceedForAssignToAllUsers: null
};
const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const getStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getByEducationCodeStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByEducationCodeSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getByEducationCodeFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByEducationCodeReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getByUserCodeStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByUserCodeSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getByUserCodeFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByUserCodeReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const putStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const putSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForPut: action.isSucceedForPut,
    });
};
const putFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const putReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForPut: action.isSucceedForPut,
    });
};
const deleteStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const deleteSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForDelete: action.isSucceedForDelete,
    });
};
const deleteFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const deleteReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForDelete: action.isSucceedForDelete,
    });
};
const completeStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
}
const completeSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        isLoading: action.isLoading,
        isSucceedForComplete: action.isSucceedForComplete,
    });
}
const completeFail = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
    });
}
const completeReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForComplete: action.isSucceedForComplete,
    });
}
const getInCompleteEducationsStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getInCompleteEducationsSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetInCompleteEducations: action.isSucceedForGetInCompleteEducations,
    });
};
const getInCompleteEducationsFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getInCompleteEducationsReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetInCompleteEducations: action.isSucceedForGetInCompleteEducations,
    });
};
const updateHalfEducationStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
}
const updateHalfEducationSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        isLoading: action.isLoading,
        isSucceedForUpdateHalfEducation: action.isSucceedForUpdateHalfEducation,
    });
}
const updateHalfEducationFail = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
    });
}
const updateHalfEducationReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForUpdateHalfEducation: action.isSucceedForUpdateHalfEducation,
    });
}
const getHalfEducationsStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
}
const getHalfEducationsSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetHalfEducations: action.isSucceedForGetHalfEducations,
    });
}
const getHalfEducationsFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
}
const getHalfEducationsReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetHalfEducations: action.isSucceedForGetHalfEducations,
    });
}
const getCompletedEducationsStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
}
const getCompletedEducationsSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetCompletedEducations: action.isSucceedForGetCompletedEducations,
    });
}
const getCompletedEducationsFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
}
const getCompletedEducationsReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetCompletedEducations: action.isSucceedForGetCompletedEducations,
    });
}
const assignToAllUsersStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const assignToAllUsersSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAssignToAllUsers: action.isSucceedForAdd,
    });
};
const assignToAllUsersFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const assignToAllUsersReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAssignToAllUsers: action.isSucceedForAdd,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_USER_EDUCATION_START:
            return addStart(state,action);
        case actionTypes.ADD_USER_EDUCATION_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_USER_EDUCATION_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_USER_EDUCATION_RESET:
            return addReset(state,action);
        case actionTypes.GET_USER_EDUCATION_START:
            return getStart(state, action);
        case actionTypes.GET_USER_EDUCATION_SUCCESS:
            return getSuccess(state, action);
        case actionTypes.GET_USER_EDUCATION_FAIL:
            return getFail(state, action);
        case actionTypes.GET_USER_EDUCATION_RESET:
            return getReset(state, action);
        case actionTypes.GET_USER_EDUCATION_BY_EDUCATION_ID_START:
            return getByEducationCodeStart(state, action);
        case actionTypes.GET_USER_EDUCATION_BY_EDUCATION_ID_SUCCESS:
            return getByEducationCodeSuccess(state, action);
        case actionTypes.GET_USER_EDUCATION_BY_EDUCATION_ID_FAIL:
            return getByEducationCodeFail(state, action);
        case actionTypes.GET_USER_EDUCATION_BY_EDUCATION_ID_RESET:
            return getByEducationCodeReset(state, action);
        case actionTypes.GET_USER_EDUCATION_BY_USER_ID_START:
            return getByUserCodeStart(state, action);
        case actionTypes.GET_USER_EDUCATION_BY_USER_ID_SUCCESS:
            return getByUserCodeSuccess(state, action);
        case actionTypes.GET_USER_EDUCATION_BY_USER_ID_FAIL:
            return getByUserCodeFail(state, action);
        case actionTypes.GET_USER_EDUCATION_BY_USER_ID_RESET:
            return getByUserCodeReset(state, action);
        case actionTypes.PUT_USER_EDUCATION_START:
            return putStart(state, action);
        case actionTypes.PUT_USER_EDUCATION_SUCCESS:
            return putSuccess(state, action);
        case actionTypes.PUT_USER_EDUCATION_FAIL:
            return putFail(state, action);
        case actionTypes.PUT_USER_EDUCATION_RESET:
            return putReset(state, action);
        case actionTypes.DELETE_USER_EDUCATION_START:
            return deleteStart(state, action);
        case actionTypes.DELETE_USER_EDUCATION_SUCCESS:
            return deleteSuccess(state, action);
        case actionTypes.DELETE_USER_EDUCATION_FAIL:
            return deleteFail(state, action);
        case actionTypes.DELETE_USER_EDUCATION_RESET:
            return deleteReset(state, action);
        case actionTypes.COMPLETE_USER_EDUCATION_START:
            return completeStart(state, action);
        case actionTypes.COMPLETE_USER_EDUCATION_SUCCESS:
            return completeSuccess(state, action);
        case actionTypes.COMPLETE_USER_EDUCATION_FAIL:
            return completeFail(state, action);
        case actionTypes.COMPLETE_USER_EDUCATION_RESET:
            return completeReset(state, action);
        case actionTypes.GET_INCOMPLETE_USER_EDUCATIONS_START:
            return getInCompleteEducationsStart(state, action);
        case actionTypes.GET_INCOMPLETE_USER_EDUCATIONS_SUCCESS:
            return getInCompleteEducationsSuccess(state, action);
        case actionTypes.GET_INCOMPLETE_USER_EDUCATIONS_FAIL:
            return getInCompleteEducationsFail(state, action);
        case actionTypes.GET_INCOMPLETE_USER_EDUCATIONS_RESET:
            return getInCompleteEducationsReset(state, action);
        case actionTypes.UPDATE_HALF_USER_EDUCATION_START:
            return updateHalfEducationStart(state, action);
        case actionTypes.UPDATE_HALF_USER_EDUCATION_SUCCESS:
            return updateHalfEducationSuccess(state, action);
        case actionTypes.UPDATE_HALF_USER_EDUCATION_FAIL:
            return updateHalfEducationFail(state, action);
        case actionTypes.UPDATE_HALF_USER_EDUCATION_RESET:
            return updateHalfEducationReset(state, action);
        case actionTypes.GET_HALF_EDUCATIONS_START:
            return getHalfEducationsStart(state, action);
        case actionTypes.GET_HALF_EDUCATIONS_SUCCESS:
            return getHalfEducationsSuccess(state, action);
        case actionTypes.GET_HALF_EDUCATIONS_FAIL:
            return getHalfEducationsFail(state, action);
        case actionTypes.GET_HALF_EDUCATIONS_RESET:
            return getHalfEducationsReset(state, action);
        case actionTypes.GET_COMPLETED_EDUCATIONS_START:
            return getCompletedEducationsStart(state, action);
        case actionTypes.GET_COMPLETED_EDUCATIONS_SUCCESS:
            return getCompletedEducationsSuccess(state, action);
        case actionTypes.GET_COMPLETED_EDUCATIONS_FAIL:
            return getCompletedEducationsFail(state, action);
        case actionTypes.GET_COMPLETED_EDUCATIONS_RESET:
            return getCompletedEducationsReset(state, action);
        case actionTypes.ASSIGN_EDUCATION_TO_ALL_USERS_START:
            return assignToAllUsersStart(state, action);
        case actionTypes.ASSIGN_EDUCATION_TO_ALL_USERS_SUCCESS:
            return assignToAllUsersSuccess(state, action);
        case actionTypes.ASSIGN_EDUCATION_TO_ALL_USERS_FAIL:
            return assignToAllUsersFail(state, action);
        case actionTypes.ASSIGN_EDUCATION_TO_ALL_USERS_RESET:
            return assignToAllUsersReset(state, action);
        default:
            return state;
    };
};

export default reducer;