import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';

import {HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import {NOTIFICATION_HUB_URL} from '../../../../_config/env';

import * as notificationActions from '../../../../store/actions/notification.actions';
import { connect } from 'react-redux';

import linq from 'linq';
import moment from 'moment';

// notification status options
const status = [
    {
        value: '0',
        label: 'Hepsi'
    },
    {
        value: '1',
        label: 'Yeni'
    },
    {
        value: '2',
        label: 'Okundu'
    }
];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = (props) => {
    const theme = useTheme();

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };
    
    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light
    };
    
    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        height: 28
    };


    const [firstLoad, setFirstLoad] = useState(true);
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const [notifications, setNotifications] = useState([]);
    
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const [hubConection] = useState(
        new HubConnectionBuilder()
          .withUrl(NOTIFICATION_HUB_URL, {
            accessTokenFactory: () => localStorage.getItem('@token'),
          })
          .configureLogging(LogLevel.Trace)
          .build(),
      );

    const prevOpen = useRef(open);
    const UnionDistinct = (left, right) => {
        return linq.from(left)
             .union(linq.from(right))
             .distinct(s => JSON.stringify(s));
    }
    linq.prototype.unionDistinct = function(right) {
        return UnionDistinct(this, right);
    }
    const readAllNotifications = () => {
        let notifications = JSON.parse(localStorage.getItem('@notifications'));
        let notificationIds = [];
        linq.from(notifications).forEach(element => {
            notificationIds.push(element.notificationId.toString());
        });
        props.onReadAll(notificationIds);
    } 
    const handleNotifications = (notifications) => {
        let notificationList = linq.from(notifications).toArray();
        let notificationFromStorage = JSON.parse(localStorage.getItem('@notifications'));
        let storageNotifications = linq.from(notificationFromStorage).toArray();
        let testArray = [];
        linq.from(notificationList).forEach(element => {
            if (linq.from(storageNotifications).where(x => x.notificationId === element.notificationId).toArray().length === 0) 
            {
                testArray.push(element);
            }
        });
        let mergeArray = linq.from(storageNotifications).union(testArray).toArray();
        let lastArray= linq.from(mergeArray).where(x => x.isRead == 'false' || x.isRead == 'False').orderByDescending(x => x.createDate).toArray();
        setNotifications(lastArray);
        localStorage.setItem('@notifications', JSON.stringify(lastArray));
    }

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            props.onGetNotifications();
        }
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        if (props.isSucceedForGetAll) {
            props.onGetNotificationsReset();
            handleNotifications(props.payload);
            props.onPush();
        }
        if (props.isSucceedForReadAll) {
            props.onReadAllReset();
            setNotifications([]);
            localStorage.removeItem('@notifications');
            props.onPush();
        }
        // if (props.pushIsSucceed) {
        //     props.pushReset();
        //     setNotifications(JSON.parse(localStorage.getItem('@supportNotifications')));
        // }
        prevOpen.current = open;
        hubConection
        .start()
        .then(result => {
          hubConection
            //.invoke('GetConnectionId', [JSON.stringify(localStorage.getItem('@departmentId')),JSON.stringify(localStorage.getItem('@userId'))]).catch()
            .invoke('GetConnectionId',localStorage.getItem('@userId')).catch()
            .then()
            .catch();
          hubConection.on(
            'SendToChannel',
            (notificationType, departmentCode,managerCode, userCode,profilePhoto, personName,message,isRead,createDate,notificationId) => {
                const newNotification = {
                  notificationType: notificationType,
                  departmentCode: departmentCode,
                  managerCode: managerCode,
                  userId: userCode,
                  profilePhoto: profilePhoto,
                  personName: personName,
                  message: message,
                  isRead: isRead,
                  createDate: createDate,
                  notificationId: notificationId
                };
                let newNotificationList = [];
                newNotificationList.push(newNotification);
                let notificationFromStorage = JSON.parse(localStorage.getItem('@notifications'));
                let storageNotifications = linq.from(notificationFromStorage).toArray();
                let lastArray = [];
                if (storageNotifications.length > 0) {
                    let mergeArray = linq.from(storageNotifications).unionDistinct(newNotificationList).toArray();
                    lastArray = linq.from(mergeArray).orderByDescending(x => x.createDate).toArray();
                    setNotifications(lastArray);
                    localStorage.setItem('@notifications', JSON.stringify(lastArray));
                }else {
                    setNotifications(newNotificationList);
                    localStorage.setItem('@notifications', JSON.stringify(newNotificationList));
                }
                props.onPush();
                //userId,profilePhoto,personName, message,isRead, dateTimeNow, notificationId
            },
          );
        })
        .catch(e => {
          
        });
    }, [open, firstLoad, hubConection, notifications,props.isSucceedForGetAll,props.isSucceedForReadAll]);

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <IconBell stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                {notifications.length > 0 && 
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="subtitle1">Tüm Bildirimler</Typography>
                                                        <Chip
                                                            size="small"
                                                            label={notifications.length}
                                                            sx={{
                                                                color: theme.palette.background.default,
                                                                bgcolor: theme.palette.warning.dark
                                                            }}
                                                        />
                                                    </Stack>
                                                }
                                                {notifications.length === 0 &&
                                                    <Typography variant="subtitle1">Henüz gelen bildiriminiz yok.</Typography>
                                                }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PerfectScrollbar
                                                style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                            >
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item xs={12} p={0}>
                                                        <Divider sx={{ my: 0 }} />
                                                    </Grid>
                                                </Grid>
                                                <NotificationList key={"notificationList"} notifications={notifications} />
                                            </PerfectScrollbar>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    {notifications.length > 0 &&
                                        <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                            <Button onClick={readAllNotifications} size="small" disableElevation>
                                                Hepsini okundu olarak işaretle
                                            </Button>
                                        </CardActions>
                                    }
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                        
                    </Transitions>
                    
                )}
            </Popper>
            
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isSucceedForGetAll: state.notification.isSucceedForGetAll,
        payload: state.notification.payload,
        pushIsSucceed: state.notification.pushIsSucceed,
        isSucceedForReadAll: state.notification.isSucceedForReadAll,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
        onGetNotifications: () =>
            dispatch(
                notificationActions.getAll(),
        ),
        onGetNotificationsReset: () => dispatch(notificationActions.getAllReset()),
        onPush: () => dispatch(notificationActions.pushSuccess()),
        onPushReset: () => dispatch(notificationActions.pushReset()),
        onReadAll: (notificationIds) => dispatch(notificationActions.readAll(notificationIds)),
        onReadAllReset: () => dispatch(notificationActions.readAllReset()),
    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(NotificationSection);
