import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForGivePermissionToDepartment: null,
    isSucceedForGivePermissionToUser: null,
    isSucceedForGetByUserCode: null,
    isSucceedForGivePermissionToAllUsers: null,
};
const givePermissionToUserStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const givePermissionToUserSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGivePermissionToUser: action.isSucceedForGivePermissionToUser,
    });
};
const givePermissionToUserFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const givePermissionToUserReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGivePermissionToUser: action.isSucceedForGivePermissionToUser,
    });
};
const givePermissionToDepartmentStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const givePermissionToDepartmentSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGivePermissionToDepartment: action.isSucceedForGivePermissionToDepartment,
    });
};
const givePermissionToDepartmentFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const givePermissionToDepartmentReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGivePermissionToDepartment: action.isSucceedForGivePermissionToDepartment,
    });
};
const getListByUserStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getListByUserSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByUserCode: action.isSucceedForGetByUserCode,
    });
};
const getListByUserFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getListByUserReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByUserCode: action.isSucceedForGetByUserCode,
    });
};
const givePermissionToAllUsersStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const givePermissionToAllUsersSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGivePermissionToAllUsers: action.isSucceedForGivePermissionToAllUsers,
    });
};
const givePermissionToAllUsersFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const givePermissionToAllUsersReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGivePermissionToAllUsers: action.isSucceedForGivePermissionToAllUsers
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GIVE_PERMISSION_TO_USER_START:
            return givePermissionToUserStart(state,action);
        case actionTypes.GIVE_PERMISSION_TO_USER_SUCCESS:
            return givePermissionToUserSuccess(state,action);
        case actionTypes.GIVE_PERMISSION_TO_USER_FAIL:
            return givePermissionToUserFail(state,action);
        case actionTypes.GIVE_PERMISSION_TO_USER_RESET:
            return givePermissionToUserReset(state,action);
        case actionTypes.GIVE_PERMISSION_TO_DEPARTMENT_START:
            return givePermissionToDepartmentStart(state,action);
        case actionTypes.GIVE_PERMISSION_TO_DEPARTMENT_SUCCESS:
            return givePermissionToDepartmentSuccess(state,action);
        case actionTypes.GIVE_PERMISSION_TO_DEPARTMENT_FAIL:
            return givePermissionToDepartmentFail(state,action);
        case actionTypes.GIVE_PERMISSION_TO_DEPARTMENT_RESET:
            return givePermissionToDepartmentReset(state,action);
        case actionTypes.GET_PROJECT_PERMISSION_BY_USER_START:
            return getListByUserStart(state, action);
        case actionTypes.GET_PROJECT_PERMISSION_BY_USER_SUCCESS:
            return getListByUserSuccess(state, action);
        case actionTypes.GET_PROJECT_PERMISSION_BY_USER_FAIL:
            return getListByUserFail(state, action);
        case actionTypes.GET_PROJECT_PERMISSION_BY_USER_RESET:
            return getListByUserReset(state, action);
        case actionTypes.GIVE_PERMISSION_TO_ALL_USERS_START:
            return givePermissionToAllUsersStart(state, action);
        case actionTypes.GIVE_PERMISSION_TO_ALL_USERS_SUCCESS:
            return givePermissionToAllUsersSuccess(state, action);
        case actionTypes.GIVE_PERMISSION_TO_ALL_USERS_FAIL:
            return givePermissionToAllUsersFail(state, action);
        case actionTypes.GIVE_ANSWER_USER_QUIZ_DETAIL_RESET:
            return givePermissionToAllUsersReset(state, action);
        default:
            return state;
    };
};

export default reducer;