import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForGetAll: null,
    isSucceedForUpdate: null,
    isSucceedForDelete: null,
    isSucceedForUserDocumentPermissions: null,
    isSucceedForGetUserDocumentPermission: null,
    payloadForUserDocumentPermission: null,
};
const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const updateStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const updateSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForUpdate: action.isSucceedForUpdate,
    });
};
const updateFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const updateReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForUpdate: action.isSucceedForUpdate,
    });
};
const deleteStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const deleteSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForDelete: action.isSucceedForDelete,
    });
};
const deleteFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const deleteReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForDelete: action.isSucceedForDelete,
    });
};
const getAllStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getAllSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getAllFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getAllReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getUserDocumentPermissionsStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getUserDocumentPermissionsSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForUserDocumentPermissions: action.isSucceedForUserDocumentPermissions,
    });
};
const getUserDocumentPermissionsFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getUserDocumentPermissionsReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForUserDocumentPermissions: action.isSucceedForUserDocumentPermissions,
    });
};
const getUserDocumentPermissionStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getUserDocumentPermissionSuccess = (state, action) => {
    return updateObject(state, {
        payloadForUserDocumentPermission: action.payloadForUserDocumentPermission,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetUserDocumentPermission: action.isSucceedForGetUserDocumentPermission,
    });
};
const getUserDocumentPermissionFail = (state, action) => {
    return updateObject(state, {
        payloadForUserDocumentPermission: action.payloadForUserDocumentPermission,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getUserDocumentPermissionReset = (state, action) => {
    return updateObject(state, {
        payloadForUserDocumentPermission: action.payloadForUserDocumentPermission,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetUserDocumentPermission: action.isSucceedForGetUserDocumentPermission,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_DOCUMENT_PERMISSION_START:
            return addStart(state,action);
        case actionTypes.ADD_DOCUMENT_PERMISSION_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_DOCUMENT_PERMISSION_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_DOCUMENT_PERMISSION_RESET:
            return addReset(state,action);
        case actionTypes.GET_ALL_DOCUMENT_PERMISSIONS_START:
            return getAllStart(state, action);
        case actionTypes.GET_ALL_DOCUMENT_PERMISSIONS_SUCCESS:
            return getAllSuccess(state, action);
        case actionTypes.GET_ALL_DOCUMENT_PERMISSIONS_FAIL:
            return getAllFail(state, action);
        case actionTypes.GET_ALL_DOCUMENT_PERMISSIONS_RESET:
            return getAllReset(state, action);
        case actionTypes.UPDATE_DOCUMENT_PERMISSION_START:
            return updateStart(state, action);
        case actionTypes.UPDATE_DOCUMENT_PERMISSION_SUCCESS:
            return updateSuccess(state, action);
        case actionTypes.UPDATE_DOCUMENT_PERMISSION_FAIL:
            return updateFail(state, action);
        case actionTypes.UPDATE_DOCUMENT_PERMISSION_RESET:
            return updateReset(state, action);
        case actionTypes.DELETE_DOCUMENT_PERMISSION_START:
            return deleteStart(state, action);
        case actionTypes.DELETE_DOCUMENT_PERMISSION_SUCCESS:
            return deleteSuccess(state, action);
        case actionTypes.DELETE_DOCUMENT_PERMISSION_FAIL:
            return deleteFail(state, action);
        case actionTypes.DELETE_DOCUMENT_PERMISSION_RESET:
            return deleteReset(state, action);
        case actionTypes.GET_USER_DOCUMENT_PERMISSIONS_START:
            return getUserDocumentPermissionsStart(state, action);
        case actionTypes.GET_USER_DOCUMENT_PERMISSIONS_SUCCESS:
            return getUserDocumentPermissionsSuccess(state, action);
        case actionTypes.GET_USER_DOCUMENT_PERMISSIONS_FAIL:
            return getUserDocumentPermissionsFail(state, action);
        case actionTypes.GET_USER_DOCUMENT_PERMISSIONS_RESET:
            return getUserDocumentPermissionsReset(state, action);
        case actionTypes.GET_USER_DOCUMENT_PERMISSION_START:
            return getUserDocumentPermissionStart(state, action);
        case actionTypes.GET_USER_DOCUMENT_PERMISSION_SUCCESS:
            return getUserDocumentPermissionSuccess(state, action);
        case actionTypes.GET_USER_DOCUMENT_PERMISSION_FAIL:
            return getUserDocumentPermissionFail(state, action);
        case actionTypes.GET_USER_DOCUMENT_PERMISSION_RESET:
            return getUserDocumentPermissionReset(state, action);
        default:
            return state;
    };
};

export default reducer;