import dashboard from './dashboard';
import mypages from './mypages';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import definitions from './definitions';
import inbox from './inbox';
import reports from './reports';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard,mypages,inbox, reports, definitions]
};

export default menuItems;
