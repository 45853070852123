import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    isAuthenticated : null,
    isLoading: null,
};

const authStart = (state, action) => {
    return updateObject(state, {
        isAuthenticated: action.isAuthenticated,
        isLoading: action.isLoading,
    });
};
const authSuccess = (state, action) => {
    return updateObject(state, {
        isAuthenticated: action.isAuthenticated,
        isLoading: action.isLoading,
    });
};
const authOut = (state, action) => {
    return updateObject(state, {
        isAuthenticated: action.isAuthenticated,
        isLoading: action.isLoading,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state,action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state,action);
        case actionTypes.AUTH_OUT:
            return authOut(state, action);
        default:
            return state;
    };
};

export default reducer;