import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    token: null,
    expiration: null,
    refreshToken: null,
    userId: null,
    userName: null,
    errors: null,
    isAuthenticated: null,
    isLoading: false,
};

const loginStart = (state, action) => {
    return updateObject(state, {
        isLoading: true,
    });
};
const loginSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        expiration: action.expiration,
        refreshToken: action.refreshToken,
        userId: action.userId,
        userName: action.userName,
        errors: action.errors,
        isAuthenticated: action.isAuthenticated,
        isLoading: action.isLoading,
    });
};
const loginFail = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isAuthenticated: action.isAuthenticated,
        isLoading: action.isLoading,
    });
};
const loginReset = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isAuthenticated: action.isAuthenticated,
        isLoading: action.isLoading,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_START:
            return loginStart(state,action);
        case actionTypes.LOGIN_SUCCESS:
            return loginSuccess(state,action);
        case actionTypes.LOGIN_FAIL:
            return loginFail(state,action);
        case actionTypes.LOGIN_RESET:
            return loginReset(state,action);
        default:
            return state;
    };
};

export default reducer;