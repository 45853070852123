import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceed: false,
    payloadForUpdateProfilePhoto: null,
    isSucceedUpdateProfilePhoto: false,
};
const changePasswordStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const changePasswordSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const changePasswordFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const changePasswordReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const updateProfileStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const updateProfileSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const updateProfileFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const updateProfileReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const updateProfilePhotoStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const updateProfilePhotoSuccess = (state, action) => {
    return updateObject(state, {
        payloadForUpdateProfilePhoto: action.payloadForUpdateProfilePhoto,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedUpdateProfilePhoto: action.isSucceedUpdateProfilePhoto,
    });
};
const updateProfilePhotoFail = (state, action) => {
    return updateObject(state, {
        payloadForUpdateProfilePhoto: action.payloadForUpdateProfilePhoto,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const updateProfilePhotoReset = (state, action) => {
    return updateObject(state, {
        payloadForUpdateProfilePhoto: action.payloadForUpdateProfilePhoto,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedUpdateProfilePhoto: action.isSucceedUpdateProfilePhoto,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_PASSWORD_START:
            return changePasswordStart(state,action);
        case actionTypes.CHANGE_PASSWORD_SUCCESS:
            return changePasswordSuccess(state,action);
        case actionTypes.CHANGE_PASSWORD_FAIL:
            return changePasswordFail(state,action);
        case actionTypes.CHANGE_PASSWORD_RESET:
            return changePasswordReset(state,action);

        case actionTypes.UPDATE_PROFILE_START:
            return updateProfileStart(state, action);
        case actionTypes.UPDATE_PROFILE_SUCCESS:
            return updateProfileSuccess(state, action);
        case actionTypes.UPDATE_PROFILE_FAIL:
            return updateProfileFail(state, action);
        case actionTypes.UPDATE_PROFILE_RESET:
            return updateProfileReset(state, action);

        case actionTypes.UPDATE_PROFILE_PHOTO_START:
            return updateProfilePhotoStart(state, action);
        case actionTypes.UPDATE_PROFILE_PHOTO_SUCCESS:
            return updateProfilePhotoSuccess(state, action);
        case actionTypes.UPDATE_PROFILE_PHOTO_FAIL:
            return updateProfilePhotoFail(state, action);
        case actionTypes.UPDATE_PROFILE_PHOTO_RESET:
            return updateProfilePhotoReset(state, action);
        default:
            return state;
    };
};

export default reducer;