import React from 'react';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    Tooltip,
    IconButton
} from '@mui/material';
import moment from 'moment';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { 
    IconHeartHandshake, 
    IconConfetti } from '@tabler/icons';


// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = (props) => {
    const theme = useTheme();

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        height: 28
    };

    const readNotification = (notificationId) => {
        
    }

    return (
        <List key='lotification-list'
            sx={{
                width: '100%',
                maxWidth: 330,
                minWidth:330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {props.notifications.map((notification) => (
                <React.Fragment key={notification.notificationId}>
                <ListItemWrapper>
                    <ListItem alignItems="center">
                        <ListItemAvatar>
                            <Avatar alt={notification.personName} src={notification.profilePhoto}/>
                        </ListItemAvatar>
                        <ListItemText primary={notification.personName}/>
                        <ListItemSecondaryAction>
                            <Grid container justifyContent="flex-end">
                                <Grid item xs={12}>
                                    {moment(moment.utc().add(3,'hours')).diff(notification.createDate,'minutes') === 0 ? (
                                        <Typography variant="caption" display="block" gutterBottom>Henüz</Typography>
                                    ):(moment(moment.utc().add(3,'hours')).diff(notification.createDate,'minutes') < 60 ?(
                                        <Typography variant="caption" display="block" gutterBottom>{moment(moment.utc().add(3,'hours')).diff(notification.createDate,'minutes')} dk. önce</Typography>
                                        ):(
                                            <Typography variant="caption" display="block" gutterBottom>{moment(moment.utc().add(3,'hours')).diff(notification.createDate,'hours')} saat önce</Typography>
                                        )
                                        
                                    )}
                                </Grid>
                            </Grid>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Grid container direction="column" className="list-container">
                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <Typography variant="subtitle2">{notification.message}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item xs={12}>
                                <Grid item xs={8}>
                                    {(notification.isRead === "false" || notification.isRead === "False") && <Chip label="Okunmadı" sx={chipErrorSX} />}
                                    {(notification.isRead === "true" || notification.isRead === "True") && <Chip label="Okundu" sx={chipSuccessSX} />}
                                </Grid>
                                <Grid item xs={4}>
                                    {notification.notificationType === "Support" && 
                                        <Tooltip title="Teknik Destek Talebi">
                                            <IconButton>
                                                <IconHeartHandshake color='orange' sx={{ fontSize: '1.3rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {notification.notificationType === "Permission" && 
                                        <Tooltip title="İzin Talebi">
                                            <IconButton>
                                                <IconConfetti color = 'green' sx={{ fontSize: '1.3rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ListItemWrapper>
                <Divider /></React.Fragment>
            ))}
        </List>
    );
};

export default NotificationList;
