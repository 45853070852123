import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForGetByUserId: null,
    isSucceedForUpdate: null,
    isSucceedForGetByUserAndStatus: null
 };
 const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const getByUserIdStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByUserIdSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByUserId: action.isSucceedForGetByUserId,
    });
};
const getByUserIdFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByUserIdReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByUserId: action.isSucceedForGetByUserId,
    });
};
const getByUserAndStatusStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByUserAndStatusSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByUserAndStatus: action.isSucceedForGetByUserAndStatus,
    });
};
const getByUserAndStatusFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByUserAndStatusReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByUserAndStatus: action.isSucceedForGetByUserAndStatus,
    });
};
const updateAssignmentStatusStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const updateAssignmentStatusSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForUpdate: action.isSucceedForUpdate,
    });
};
const updateAssignmentStatusFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const updateAssignmentStatusReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForUpdate: action.isSucceedForUpdate,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_USER_SURVEY_ASSIGNMENT_START:
            return addStart(state,action);
        case actionTypes.ADD_USER_SURVEY_ASSIGNMENT_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_USER_SURVEY_ASSIGNMENT_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_USER_SURVEY_ASSIGNMENT_RESET:
            return addReset(state,action);
        case actionTypes.GET_USER_SURVEY_ASSIGNMENT_BY_USER_START:
            return getByUserIdStart(state, action);
        case actionTypes.GET_USER_SURVEY_ASSIGNMENT_BY_USER_SUCCESS:
            return getByUserIdSuccess(state, action);
        case actionTypes.GET_USER_SURVEY_ASSIGNMENT_BY_USER_FAIL:
            return getByUserIdFail(state, action);
        case actionTypes.GET_USER_SURVEY_ASSIGNMENT_BY_USER_RESET:
            return getByUserIdReset(state, action);
        case actionTypes.GET_USER_SURVEY_ASSIGNMENT_BY_USER_AND_BY_STATUS_START:
            return getByUserAndStatusStart(state, action);
        case actionTypes.GET_USER_SURVEY_ASSIGNMENT_BY_USER_AND_BY_STATUS_SUCCESS:
            return getByUserAndStatusSuccess(state, action);
        case actionTypes.GET_USER_SURVEY_ASSIGNMENT_BY_USER_AND_BY_STATUS_FAIL:
            return getByUserAndStatusFail(state, action);
        case actionTypes.GET_USER_SURVEY_ASSIGNMENT_BY_USER_AND_BY_STATUS_RESET:
            return getByUserAndStatusReset(state, action);
        case actionTypes.UPDATE_USER_SURVEY_ASSIGNMENT_STATUS_START:
            return updateAssignmentStatusStart(state, action);
        case actionTypes.UPDATE_USER_SURVEY_ASSIGNMENT_STATUS_SUCCESS:
            return updateAssignmentStatusSuccess(state, action);
        case actionTypes.UPDATE_USER_SURVEY_ASSIGNMENT_STATUS_FAIL:
            return updateAssignmentStatusFail(state, action);
        case actionTypes.UPDATE_USER_SURVEY_ASSIGNMENT_STATUS_RESET:
            return updateAssignmentStatusReset(state, action);
        default:
            return state;
    };
};

export default reducer;
