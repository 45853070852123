import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForGetHistory: null,
};

const getSupportHistoryStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getSupportHistorySuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetHistory: action.isSucceedForGetHistory,
    });
};
const getSupportHistoryFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getSupportHistoryReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetHistory: action.isSucceedForGetHistory,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SUPPORT_HISTORY_START:
            return getSupportHistoryStart(state,action);
        case actionTypes.GET_SUPPORT_HISTORY_SUCCESS:
            return getSupportHistorySuccess(state,action);
        case actionTypes.GET_SUPPORT_HISTORY_FAIL:
            return getSupportHistoryFail(state,action);
        case actionTypes.GET_SUPPORT_HISTORY_RESET:
            return getSupportHistoryReset(state,action);
        default:
            return state;
    };
};

export default reducer;