import { createStore, applyMiddleware, combineReducers } from 'redux';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import thunk from 'redux-thunk';
import authReducer from './reducers/auth.reducer';
import loginReducer from './reducers/login.reducer';
import customizationReducer from './customizationReducer';
import branchOfficeReducer from './reducers/branchoffice.reducer';
import departmentReducer from './reducers/department.reducer';
import departmentCardReducer from './reducers/departmentCard.reducer';
import userReducer from './reducers/user.reducer';
import jobReducer from './reducers/job.reducer';
import permissionTypeReducer from './reducers/permissionType.reducer';
import permissionReducer from './reducers/permission.reducer';
import supportTypeReducer from './reducers/supportType.reducer';
import supportReducer from './reducers/support.reducer';
import supportDetailReducer from './reducers/supportDetail.reducer';
import surveyTypeReducer from './reducers/surveyType.reducer';
import surveyReducer from './reducers/survey.reducer';
import surveyQuestionReducer from './reducers/surveyQuestion.actions';
import surveyAnswerReducer from './reducers/surveyAnswer.reducer';
import educationTypeReducer from './reducers/educationType.reducer';
import educationReducer from './reducers/education.reducer';
import userEducationReducer from './reducers/userEducation.reducer';
import quizReducer from './reducers/quiz.reducer';
import quizQuestionReducer from './reducers/quizQuestion.reducer';
import quizAnswerReducer from './reducers/quizAnswer.reducer';
import quizAssignmentReducer from './reducers/quizAssignment.reducer';
import profileReducer from './reducers/profile.reducer';
import departmentEducationReducer from './reducers/departmentEducation.reducer';
import userQuizDetailReducer from './reducers/userQuizDetail.reducer';
import departmentQuizReducer from './reducers/departmentQuiz.reducer';
import registerReducer from './reducers/register.reducer';
import notificationReducer from './reducers/notification.reducer';
import settingReducer from './reducers/setting.reducer';
import userSurveyReducer from './reducers/userSurvey.reducer';
import documentReducer from './reducers/document.reducer';
import documentPermissionReducer from './reducers/document.permission.reducer';
import dashboardReducer from './reducers/dashboard.reducer';
import announcementReducer from './reducers/announcement.reducer';
import menuReducer from './slices/menu';
import departmentSurveyReducer from './reducers/departmentSurvey.reducer';
import userSurveyAssignmentReducer from './reducers/userSurveyAssignment.reducer';
import documentDriveReducer from './reducers/documentDrive.reducer';
import projectPageReducer from './reducers/projectPages.reducer';
import projectPagePermissionReducer from './reducers/projectPagePermission.reducer';
import reportReducer from './reducers/report.reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

//const store = createStore(reducer);
const rootReducer = combineReducers({
    auth: authReducer,
    login: loginReducer,
    customization: customizationReducer,
    branchOffice: branchOfficeReducer,
    department: departmentReducer,
    departmentCard: departmentCardReducer,
    user: userReducer,
    job: jobReducer,
    permissionType: permissionTypeReducer,
    permission: permissionReducer,
    supportType: supportTypeReducer,
    support: supportReducer,
    supportDetail: supportDetailReducer,
    surveyType: surveyTypeReducer,
    survey: surveyReducer,
    surveyQuestion: surveyQuestionReducer,
    surveyAnswer: surveyAnswerReducer,
    educationType: educationTypeReducer,
    education: educationReducer,
    userEducation: userEducationReducer,
    quiz: quizReducer,
    quizQuestion: quizQuestionReducer,
    quizAnswer: quizAnswerReducer,
    quizAssignment: quizAssignmentReducer,
    profile: profileReducer,
    departmentEducation: departmentEducationReducer,
    userQuizDetail: userQuizDetailReducer,
    departmentQuiz: departmentQuizReducer,
    register: registerReducer,
    notification: notificationReducer,
    menu: menuReducer,
    setting: settingReducer,
    userSurvey: userSurveyReducer,
    document: documentReducer,
    documentPermission: documentPermissionReducer,
    dashboard: dashboardReducer,
    announcement: announcementReducer,
    departmentSurvey: departmentSurveyReducer,
    userSurveyAssignment: userSurveyAssignmentReducer,
    documentDrive: documentDriveReducer,
    projectPage: projectPageReducer,
    projectPagePermission: projectPagePermissionReducer,
    report: reportReducer,     
});
const store = createStore(rootReducer, applyMiddleware(thunk))
const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;
//const persister = 'Free';

export { store, useDispatch,useSelector };
