import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForCalculate: null,
    payloadForCalculate: null,
    isSucceedForApprove: null,
    payloadForApprove: null,
    isSucceedForReject: null,
    payloadForReject: null,
    isSucceedForGetByManager: null,
    payloadForGetByManager: null,
    isSucceedForGetApproved: null,
    payloadForGetApproved: null,
    isSucceedForGetRejected: null,
    payloadForGetRejected: null,
    isSucceedForGetWaiting: null,
    payloadForGetWaiting: null,
    isSucceedForGetAll: null,
    payloadForGetAll: null,
};
const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};

const calculateStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const calculateSuccess = (state, action) => {
    return updateObject(state, {
        payloadForCalculate: action.payloadForCalculate,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForCalculate: action.isSucceedForCalculate,
    });
};
const calculateFail = (state, action) => {
    return updateObject(state, {
        payloadForCalculate: action.payloadForCalculate,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const calculateReset = (state, action) => {
    return updateObject(state, {
        payloadForCalculate: action.payloadForCalculate,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForCalculate: action.isSucceedForCalculate,
    });
};
const approveStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const approveSuccess = (state, action) => {
    return updateObject(state, {
        payloadForApprove: action.payloadForApprove,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForApprove: action.isSucceedForApprove,
    });
};
const approveFail = (state, action) => {
    return updateObject(state, {
        payloadForApprove: action.payloadForApprove,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const approveReset = (state, action) => {
    return updateObject(state, {
        payloadForApprove: action.payloadForApprove,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForApprove: action.isSucceedForApprove,
    });
};
const rejectStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const rejectSuccess = (state, action) => {
    return updateObject(state, {
        payloadForReject: action.payloadForReject,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForReject: action.isSucceedForReject,
    });
};
const rejectFail = (state, action) => {
    return updateObject(state, {
        payloadForReject: action.payloadForReject,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const rejectReset = (state, action) => {
    return updateObject(state, {
        payloadForReject: action.payloadForReject,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForReject: action.isSucceedForReject,
    });
};
const getByManagerStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByManagerSuccess = (state, action) => {
    return updateObject(state, {
        payloadForGetByManager: action.payloadForGetByManager,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByManager: action.isSucceedForGetByManager,
    });
};
const getByManagerFail = (state, action) => {
    return updateObject(state, {
        payloadForGetByManager: action.payloadForGetByManager,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByManagerReset = (state, action) => {
    return updateObject(state, {
        payloadForGetByManager: action.payloadForGetByManager,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByManager: action.isSucceedForGetByManager,
    });
};
const getApprovedStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getApprovedSuccess = (state, action) => {
    return updateObject(state, {
        payloadForGetApproved: action.payloadForGetApproved,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetApproved: action.isSucceedForGetApproved,
    });
};
const getApprovedFail = (state, action) => {
    return updateObject(state, {
        payloadForGetApproved: action.payloadForGetApproved,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getApprovedReset = (state, action) => {
    return updateObject(state, {
        payloadForGetApproved: action.payloadForGetApproved,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetApproved: action.isSucceedForGetApproved,
    });
};
const getRejectedStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getRejectedSuccess = (state, action) => {
    return updateObject(state, {
        payloadForGetRejected: action.payloadForGetRejected,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetRejected: action.isSucceedForGetRejected,
    });
};
const getRejectedFail = (state, action) => {
    return updateObject(state, {
        payloadForGetRejected: action.payloadForGetRejected,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getRejectedReset = (state, action) => {
    return updateObject(state, {
        payloadForGetRejected: action.payloadForGetRejected,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetRejected: action.isSucceedForGetRejected,
    });
};
const getWaitingStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getWaitingSuccess = (state, action) => {
    return updateObject(state, {
        payloadForGetWaiting: action.payloadForGetWaiting,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetWaiting: action.isSucceedForGetWaiting,
    });
};
const getWaitingFail = (state, action) => {
    return updateObject(state, {
        payloadForGetWaiting: action.payloadForGetWaiting,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getWaitingReset = (state, action) => {
    return updateObject(state, {
        payloadForGetWaiting: action.payloadForGetWaiting,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetWaiting: action.isSucceedForGetWaiting,
    });
};
const getAllStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getAllSuccess = (state, action) => {
    return updateObject(state, {
        payloadForGetAll: action.payloadForGetAll,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getAllFail = (state, action) => {
    return updateObject(state, {
        payloadForGetAll: action.payloadForGetAll,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getAllReset = (state, action) => {
    return updateObject(state, {
        payloadForGetAll: action.payloadForGetAll,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_PERMISSION_START:
            return addStart(state,action);
        case actionTypes.ADD_PERMISSION_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_PERMISSION_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_PERMISSION_RESET:
            return addReset(state,action);
        case actionTypes.CALCULATE_PERMISSION_START:
            return calculateStart(state, action);
        case actionTypes.CALCULATE_PERMISSION_SUCCESS:
            return calculateSuccess(state, action);
        case actionTypes.CALCULATE_PERMISSION_FAIL:
            return calculateFail(state, action);
        case actionTypes.CALCULATE_PERMISSION_RESET:
            return calculateReset(state, action);
        case actionTypes.APPROVE_PERMISSION_START:
            return approveStart(state, action);
        case actionTypes.APPROVE_PERMISSION_SUCCESS:
            return approveSuccess(state, action);
        case actionTypes.APPROVE_PERMISSION_FAIL:
            return approveFail(state, action);
        case actionTypes.APPROVE_PERMISSION_RESET:
            return approveReset(state, action);
        case actionTypes.REJECT_PERMISSION_START:
            return rejectStart(state, action);
        case actionTypes.REJECT_PERMISSION_SUCCESS:
            return rejectSuccess(state, action);
        case actionTypes.REJECT_PERMISSION_FAIL:
            return rejectFail(state, action);
        case actionTypes.REJECT_PERMISSION_RESET:
            return rejectReset(state, action);
        case actionTypes.GET_PERMISSION_BY_MANAGER_START:
            return getByManagerStart(state, action);
        case actionTypes.GET_PERMISSION_BY_MANAGER_SUCCESS:
            return getByManagerSuccess(state, action);
        case actionTypes.GET_PERMISSION_BY_MANAGER_FAIL:
            return getByManagerFail(state, action);
        case actionTypes.GET_PERMISSION_BY_MANAGER_RESET:
            return getByManagerReset(state, action);
        case actionTypes.GET_APPROVED_PERMISSION_START:
            return getApprovedStart(state, action);
        case actionTypes.GET_APPROVED_PERMISSION_SUCCESS:
            return getApprovedSuccess(state, action);
        case actionTypes.GET_APPROVED_PERMISSION_FAIL:
            return getApprovedFail(state, action);
        case actionTypes.GET_APPROVED_PERMISSION_FAIL:
            return getApprovedReset(state, action);
        case actionTypes.GET_REJECTED_PERMISSION_START:
            return getRejectedStart(state, action);
        case actionTypes.GET_REJECTED_PERMISSION_SUCCESS:
            return getRejectedSuccess(state, action);
        case actionTypes.GET_REJECTED_PERMISSION_FAIL:
            return getRejectedFail(state, action);
        case actionTypes.GET_REJECTED_PERMISSION_RESET:
            return getRejectedReset(state, action);
        case actionTypes.GET_WAITING_PERMISSION_START:
            return getWaitingStart(state, action);
        case actionTypes.GET_WAITING_PERMISSION_SUCCESS:
            return getWaitingSuccess(state, action);
        case actionTypes.GET_WAITING_PERMISSION_FAIL:
            return getWaitingFail(state, action);
        case actionTypes.GET_WAITING_PERMISSION_RESET:
            return getWaitingReset(state, action);
        case actionTypes.GET_ALL_PERMISSION_START:
            return getAllStart(state, action);
        case actionTypes.GET_ALL_PERMISSION_SUCCESS:
            return getAllSuccess(state, action);
        case actionTypes.GET_ALL_PERMISSION_FAIL:
            return getAllFail(state, action);
        case actionTypes.GET_ALL_PERMISSION_RESET:
            return getAllReset(state, action);
        default:
            return state;
    };
};

export default reducer;