// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports

import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// types
import PropTypes from 'prop-types';


// assets
import { IconMenu2 } from '@tabler/icons';

import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import * as notificationActions from '../../../store/actions/notification.actions';
import { useState, useEffect } from 'react';
//import { useDispatch, useSelector } from 'store';
//import { openDrawer } from 'store/slices/menu';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 28,
      top: 31,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));
const Header = (props) => {
    const theme = useTheme();

    const [notificationCount, setNotificationCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    //const dispatch = useDispatch();
    //const { drawerOpen } = useSelector((state) => state.menu);

    //const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    //const { layout } = useConfig();
    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            let notifications = JSON.parse(localStorage.getItem('@notifications'));
            if (notifications !== null && notifications !== undefined) {
                setNotificationCount(notifications.length);    
            }
        }
        if (props.pushIsSucceed) {
            props.onPushReset();
            let notifications = JSON.parse(localStorage.getItem('@notifications'));
            if (notifications !== null && notifications !== undefined) {
                setNotificationCount(JSON.parse(localStorage.getItem('@notifications')).length);    
            }
            if (notifications === null){
                setNotificationCount(0);
            }
        }
    }, [firstLoad, props]);
    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={props.handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            <SearchSection />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* mega-menu */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <MegaMenuSection />
            </Box> */}

            {/* live customization & localization */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}

            {/* notification & profile */}
            <StyledBadge badgeContent={notificationCount} color="secondary">
                <NotificationSection />
            </StyledBadge>
            <ProfileSection />
            
            {/* mobile header */}
            {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box> */}
        </>
    );
};
Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};
const mapStateToProps = (state) => {
    return {
        pushIsSucceed: state.notification.pushIsSucceed,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
        onPushReset: () => dispatch(notificationActions.pushReset()),
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(Header);
