import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForGetAll: null,
    isSucceedForGet: null,
    isSucceedForPut: null,
    isSucceedForDelete: null,
};
const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const getByQuestionIdStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByQuestionIdSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getByQuestionIdFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByQuestionIdReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const putStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const putSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForPut: action.isSucceedForPut,
    });
};
const putFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const putReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForPut: action.isSucceedForPut,
    });
};
const getByEducationIdStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByEducationIdSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getByEducationIdFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByEducationIdReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getByQuizIdStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByQuizIdSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getByQuizIdFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByQuizIdReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getByQuizAssignmentIdStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByQuizAssignmentIdSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getByQuizAssignmentIdFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByQuizAssignmentIdReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_QUIZ_ANSWER_START:
            return addStart(state,action);
        case actionTypes.ADD_QUIZ_ANSWER_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_QUIZ_ANSWER_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_QUIZ_ANSWER_RESET:
            return addReset(state,action);
        case actionTypes.GET_QUIZ_ANSWER_BY_QUESTION_ID_START:
            return getByQuestionIdStart(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_QUESTION_ID_SUCCESS:
            return getByQuestionIdSuccess(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_QUESTION_ID_FAIL:
            return getByQuestionIdFail(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_QUESTION_ID_RESET:
            return getByQuestionIdReset(state, action);
        case actionTypes.PUT_QUIZ_ANSWER_START:
            return putStart(state, action);
        case actionTypes.PUT_QUIZ_ANSWER_SUCCESS:
            return putSuccess(state, action);
        case actionTypes.PUT_QUIZ_ANSWER_FAIL:
            return putFail(state, action);
        case actionTypes.PUT_QUIZ_ANSWER_RESET:
            return putReset(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_EDUCATION_ID_START:
            return getByEducationIdStart(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_EDUCATION_ID_SUCCESS:
            return getByEducationIdSuccess(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_EDUCATION_ID_FAIL:
            return getByEducationIdFail(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_EDUCATION_ID_RESET:
            return getByEducationIdReset(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_QUIZ_ID_START:
            return getByQuizIdStart(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_QUIZ_ID_SUCCESS:
            return getByQuizIdSuccess(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_QUIZ_ID_FAIL:
            return getByQuizIdFail(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_QUIZ_ID_RESET:
            return getByQuizIdReset(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_QUIZ_ASSIGNMENT_ID_START:
            return getByQuizAssignmentIdStart(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_QUIZ_ASSIGNMENT_ID_SUCCESS:
            return getByQuizAssignmentIdSuccess(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_QUIZ_ASSIGNMENT_ID_FAIL:
            return getByQuizAssignmentIdFail(state, action);
        case actionTypes.GET_QUIZ_ANSWER_BY_QUIZ_ASSIGNMENT_ID_RESET:
            return getByQuizAssignmentIdReset(state, action);
        default:
            return state;
    };
};

export default reducer;