// assets
import { IconCheckupList,IconBooks,IconCertificate,IconHeartHandshake } from '@tabler/icons';
import linq from 'linq';

// constant
const icons = {
    IconCheckupList,
    IconBooks,
    IconCertificate,
    IconHeartHandshake
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

let reports = {
    id: 'reports',
    title: 'Reports',
    caption: 'Reports Caption',
    type: 'group',
    children: [
        {
            id: 'educationreports',
            title: 'EducationReports',
            type: 'collapse',
            icon: icons.IconBooks,

            children: [
                {
                    id: 'educationreport',
                    title: 'EducationReport',
                    type: 'item',
                    url: '/reports/EducationReport',
                    target: true
                }
            ]
        },
        {
            id: 'quizreports',
            title: 'QuizReports',
            type: 'collapse',
            icon: icons.IconCertificate,

            children: [
                {
                    id: 'quizreport',
                    title: 'QuizReport',
                    type: 'item',
                    url: '/reports/QuizReport',
                    target: true
                }
            ]
        },
        {
            id: 'surveyreports',
            title: 'SurveyReports',
            type: 'collapse',
            icon: icons.IconCheckupList,

            children: [
                {
                    id: 'surveyreport',
                    title: 'SurveyReport',
                    type: 'item',
                    url: '/reports/SurveyReport',
                    target: true
                }
            ]
        },
        {
            id: 'supportreports',
            title: 'SupportReports',
            type: 'collapse',
            icon: icons.IconHeartHandshake,

            children: [
                {
                    id: 'supportreport',
                    title: 'SupportReport',
                    type: 'item',
                    url: '/reports/SupportReport',
                    target: true
                }
            ]
        }
    ]
};

let pagePermissions = [];
pagePermissions = linq.from(JSON.parse(localStorage.getItem('@pagePermissions'))).toArray();

if (pagePermissions.length > 0) {
    let temp = pagePermissions.find((item) => item.title === 'Raporlar');
    let mapForIcons = []
    if (temp !== undefined) {
        mapForIcons = temp.children.map(item => ({
            ...item,
            icon: item.icon === "icons.IconCheckupList" && icons.IconCheckupList 
            || item.icon === "icons.IconBooks" && icons.IconBooks || item.icon === "icons.IconCertificate" && icons.IconCertificate 
            || item.icon === "icons.IconHeartHandshake" && icons.IconHeartHandshake 
            //||  item.icon === "icons.IconCheckbox" && icons.IconCheckbox ||
            // item.icon ==="icons.IconCheckupList" && icons.IconCheckupList ||  item.icon ==="icons.IconQuestionMark" && icons.IconQuestionMark || item.icon === "icons.IconCertificate" && icons.IconCertificate ||
            // item.icon ==="icons.IconUsers" && icons.IconUsers || item.icon === "icons.IconConfetti" && icons.IconConfetti || item.icon === "icons.IconBriefcase" && icons.IconBriefcase      
       }));   
       
        temp.children = mapForIcons;
        reports = temp;
    }else {
        reports = {};
    } 
}else{
    reports = {};
}

export default reports;
