import linq from 'linq';
// assets
import { 
    IconBuilding, 
    IconLetterD, 
    IconBooks, 
    IconChefHat, 
    IconHeartHandshake, 
    IconCheckbox, 
    IconCheckupList, 
    IconQuestionMark,
    IconCertificate,
    IconUsers,
    IconConfetti,
    IconBriefcase } from '@tabler/icons';

// constant
const icons = {
    IconBuilding,
    IconLetterD,
    IconBooks,
    IconChefHat,
    IconHeartHandshake,
    IconCheckbox,
    IconCheckupList,
    IconQuestionMark,
    IconCertificate,
    IconUsers,
    IconConfetti,
    IconBriefcase
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

let mypages = {
    id: 'mypages',
    title: 'Sayfalarım',
    type: 'group',
    children: [
        {
            id: 'myeducations',
            title: 'Eğitimlerim',
            type: 'collapse',
            icon: icons.IconBooks,
            children: [
                {
                    id: 'completed-educations',
                    title: 'Tamamladıklarım',
                    type: 'item',
                    url: '/mypages/completedEducation',
                    breadcrumbs: true
                },
                {
                    id: 'complete-educations',
                    title: 'Başlamadıklarım',
                    type: 'item',
                    url: '/mypages/completeEducation',
                    breadcrumbs: true
                },
                {
                    id: 'continue-educations',
                    title: 'Devam Edenler',
                    type: 'item',
                    url: '/mypages/continueEducation',
                    breadcrumbs: true
                },
                {
                    id: 'my-educations',
                    title: 'Tüm Eğitimlerim',
                    type: 'item',
                    url: '/mypages/myEducations',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'quizes',
            title: 'Sınavlarım',
            type: 'collapse',
            icon: icons.IconCertificate,
            children: [
                {
                    id: 'myquizlist',
                    title: 'Tüm Sınavlarım',
                    type: 'item',
                    url: '/mypages/myquizies',
                    breadcrumbs: true
                },
                {
                    id: 'incompletedquiz',
                    title: 'Başlamadıklarım',
                    type: 'item',
                    url: '/mypages/incompletedquiz',
                    breadcrumbs: true
                },
                {
                    id: 'completedquiz',
                    title: 'Tamamladıklarım',
                    type: 'item',
                    url: '/mypages/completedquiz',
                    breadcrumbs: true
                },
                {
                    id: 'continuequiz',
                    title: 'Devam Edenler',
                    type: 'item',
                    url: '/mypages/continuequiz',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'permission',
            title: 'İzinlerim',
            type: 'collapse',
            icon: icons.IconConfetti,
            children: [
                {
                    id: 'requestpermission',
                    title: 'İzin Talebi',
                    type: 'item',
                    url: '/mypages/requestpermission',
                    breadcrumbs: true
                },
                {
                    id: 'mypermissions',
                    title: 'Tüm Taleplerim',
                    type: 'item',
                    url: '/mypages/mypermissions',
                    breadcrumbs: true
                },
                {
                    id: 'approvedpermissions',
                    title: 'Onaylanmış',
                    type: 'item',
                    url: '/mypages/approvedpermissions',
                    breadcrumbs: true
                },
                {
                    id: 'rejectedpermissions',
                    title: 'Reddedilmiş',
                    type: 'item',
                    url: '/mypages/rejectedpermissions',
                    breadcrumbs: true
                },
                {
                    id: 'waitingpermissions',
                    title: 'Bekleyenler',
                    type: 'item',
                    url: '/mypages/waitingpermissions',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'support',
            title: 'Destek Taleplerim',
            type: 'collapse',
            icon: icons.IconHeartHandshake,
            children: [
                {
                    id: 'requestsupport',
                    title: 'Yeni Talep',
                    type: 'item',
                    url: '/mypages/requestsupport',
                    breadcrumbs: true
                },
                {
                    id: 'mysupportrequests',
                    title: 'Tüm Taleplerim',
                    type: 'item',
                    url: '/mypages/mysupportrequests',
                    breadcrumbs: true
                },
                {
                    id: 'mywaitingsupportrequests',
                    title: 'Bekleyenler',
                    type: 'item',
                    url: '/mypages/waitingsupportrequests',
                    breadcrumbs: true
                },
                {
                    id: 'mycontinuesupportrequests',
                    title: 'Devam Edenler',
                    type: 'item',
                    url: '/mypages/continuesupportrequests',
                    breadcrumbs: true
                },
                {
                    id: 'mycompletedsupportrequests',
                    title: 'Tamamlananlar',
                    type: 'item',
                    url: '/mypages/completedsupportrequests',
                    breadcrumbs: true
                },
                {
                    id: 'myrejectedsupportrequests',
                    title: 'Reddedilenler',
                    type: 'item',
                    url: '/mypages/rejectedsupportrequests',
                    breadcrumbs: true
                },
                {
                    id: 'mycanceledsupportrequests',
                    title: 'İptal Ettiklerim',
                    type: 'item',
                    url: '/mypages/canceledsupportrequests',
                    breadcrumbs: true
                }                
            ]
        },
        {
            id: 'surveies',
            title: 'Anket',
            type: 'collapse',
            icon: icons.IconCheckupList,
            children: [
                {
                    id: 'fillsurvey',
                    title: 'Anket Doldur',
                    type: 'item',
                    url: '/mypages/fillsurvey',
                    breadcrumbs: true
                },
                {
                    id: 'filledsurveies',
                    title: 'Yaptığım Anketler',
                    type: 'item',
                    url: '/mypages/filledsurveies',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'documents',
            title: 'Döküman Yönetim',
            type: 'collapse',
            icon: icons.IconBriefcase,
            children: [
                {
                    id: 'documents',
                    title: 'Dökümanlar',
                    type: 'item',
                    url: '/mypages/documents',
                    breadcrumbs: true
                }
            ]
        }
    ]
};

let pagePermissions = [];
pagePermissions = linq.from(JSON.parse(localStorage.getItem('@pagePermissions'))).toArray();

if (pagePermissions.length > 0) {
    let temp = pagePermissions.find((item) => item.title === 'Sayfalarım');
    let mapForIcons = []
    if (temp !== undefined) {
        mapForIcons = temp.children.map(item => ({
            ...item,
            icon: item.icon === "icons.IconBuilding" && icons.IconBuilding || item.icon === "icons.IconLetterD" && icons.IconLetterD ||
            item.icon === "icons.IconBooks" && icons.IconBooks || item.icon === "icons.IconChefHat" && icons.IconChefHat ||     
            item.icon === "icons.IconHeartHandshake" && icons.IconHeartHandshake ||  item.icon === "icons.IconCheckbox" && icons.IconCheckbox ||
            item.icon ==="icons.IconCheckupList" && icons.IconCheckupList ||  item.icon ==="icons.IconQuestionMark" && icons.IconQuestionMark || item.icon === "icons.IconCertificate" && icons.IconCertificate ||
            item.icon ==="icons.IconUsers" && icons.IconUsers || item.icon === "icons.IconConfetti" && icons.IconConfetti || item.icon === "icons.IconBriefcase" && icons.IconBriefcase      
       }));   
       
        temp.children = mapForIcons;
        mypages = temp;
    }else {
        mypages = {};
    } 
}else{
    mypages = {};
}

export default mypages;
