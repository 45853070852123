import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
//import config from './config';


import history from './history';
import { setAuthorizationToken } from './axios/setAuthorizationToken';
import moment from 'moment';

// ==============================|| REACT DOM RENDER  ||============================== //

const token = localStorage.getItem('@token');
const expiration = localStorage.getItem('@expiration');

if (moment(expiration) < moment.now()) {
    localStorage.removeItem('@token');
}else {
    setAuthorizationToken(token);
}


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <BrowserRouter history={history}>
            <App />
        </BrowserRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
