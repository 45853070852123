import linq from 'linq';
// assets
import { 
    IconBuilding, 
    IconLetterD, 
    IconBooks, 
    IconChefHat, 
    IconHeartHandshake, 
    IconCheckbox, 
    IconCheckupList, 
    IconQuestionMark,
    IconCertificate,
    IconUsers,
    IconConfetti,
    IconBriefcase,
    IconSpeakerphone,
    IconLicense } from '@tabler/icons';

// constant
const icons = {
    IconBuilding,
    IconLetterD,
    IconBooks,
    IconChefHat,
    IconHeartHandshake,
    IconCheckbox,
    IconCheckupList,
    IconQuestionMark,
    IconCertificate,
    IconUsers,
    IconConfetti,
    IconBriefcase,
    IconSpeakerphone,
    IconLicense
};


// ==============================|| UTILITIES MENU ITEMS ||============================== //
let definitions ={
    id: 'definitions',
    title: 'Tanımlamalar',
    type: 'group',
    children: [
        {
            id: 'branchoffices',
            title: 'Şubeler',
            type: 'collapse',
            icon: icons.IconBuilding,
            children: [
                {
                    id: 'add-branchoffice',
                    title: 'Yeni Şube',
                    type: 'item',
                    url: '/definitions/branch-office',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'departments',
            title: 'Departmanlar',
            type: 'collapse',
            icon: icons.IconLetterD,
            children: [
                {
                    id: 'add-department',
                    title: 'Yeni Departman',
                    type: 'item',
                    url: '/definitions/department',
                    breadcrumbs: true
                },
                {
                    id: 'add-department-card',
                    title: 'Yeni Departman Kartı',
                    type: 'item',
                    url: '/definitions/departmentcard',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'Education',
            title: 'Eğitim',
            type: 'collapse',
            icon: icons.IconBooks,
            children: [
                {
                    id: 'add-education-type',
                    title: 'Eğitim Tipleri',
                    type: 'item',
                    url: '/definitions/educationtype',
                    breadcrumbs: true
                },
                {
                    id: 'add-education',
                    title: 'Yeni Eğitim',
                    type: 'item',
                    url: '/definitions/education',
                    breadcrumbs: true
                },
                {
                    id: 'add-education-personnel',
                    title: 'Eğitim -> Personel',
                    type: 'item',
                    url: '/definitions/usereducation',
                    breadcrumbs: true
                },
                {
                    id: 'add-education-department',
                    title: 'Eğitim -> Departman',
                    type: 'item',
                    url: '/definitions/departmenteducation',
                    breadcrumbs: true
                },
            ]
        },
        {
            id: 'jobs',
            title: 'Meslekler',
            type: 'collapse',
            icon: icons.IconChefHat,
            children: [
                {
                    id: 'add-job',
                    title: 'Yeni Meslek',
                    type: 'item',
                    url: '/definitions/job',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'permission',
            title: 'İzin',
            type: 'collapse',
            icon: icons.IconConfetti,
            children: [
                {
                    id: 'add-permission-type',
                    title: 'Yeni İzin Tipi',
                    type: 'item',
                    url: '/definitions/permissionType',
                    breadcrumbs: true
                },
                {
                    id: 'adding-past-permission',
                    title: 'Geçmiş İzinleri Ekle',
                    type: 'item',
                    url: '/definitions/addingPastPermission',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'support',
            title: 'Destek',
            type: 'collapse',
            icon: icons.IconHeartHandshake,
            children: [
                {
                    id: 'add-support-type',
                    title: 'Yeni Destek Tipi',
                    type: 'item',
                    url: '/definitions/supportType',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'surveies',
            title: 'Anketler',
            type: 'collapse',
            icon: icons.IconCheckupList,
            children: [
                {
                    id: 'add-survey-type',
                    title: 'Yeni Anket Tipi',
                    type: 'item',
                    url: '/definitions/surveyType',
                    breadcrumbs: true
                },
                {
                    id: 'list-survey-type',
                    title: 'Yeni Anket',
                    type: 'item',
                    url: '/definitions/survey',
                    breadcrumbs: true
                },
                {
                    id: 'add-survey-question',
                    title: 'Anket-Soru Girişi',
                    type: 'item',
                    url: '/definitions/surveyQuestion',
                    breadcrumbs: true
                },
                {
                    id: 'add-survey-answer',
                    title: 'Anket-Cevap Giriş',
                    type: 'item',
                    url: '/definitions/surveyAnswer',
                    breadcrumbs: true
                },
                {
                    id: 'assign-survey-to-user',
                    title: 'Anket -> Kullanıcı',
                    type: 'item',
                    url: '/definitions/assignSurveyToUser',
                    breadcrumbs: true
                },
                {
                    id: 'assign-survey-to-department',
                    title: 'Anket -> Departman',
                    type: 'item',
                    url: '/definitions/assignSurveyToDepartment',
                    breadcrumbs: true
                }                
            ]
        },
        {
            id: 'quizes',
            title: 'Sınavlar',
            type: 'collapse',
            icon: icons.IconCertificate,
            children: [
                {
                    id: 'add-quiz',
                    title: 'Yeni Sınav',
                    type: 'item',
                    url: '/definitions/quiz',
                    breadcrumbs: true
                },
                {
                    id: 'add-quiz-question',
                    title: 'Sınav-Soru Girişi',
                    type: 'item',
                    url: '/definitions/quizQuestion',
                    breadcrumbs: true
                },
                {
                    id: 'add-quiz-answer',
                    title: 'Sınav-Cevap Girişi',
                    type: 'item',
                    url: '/definitions/quizAnswer',
                    breadcrumbs: true
                },
                {
                    id: 'assignment-quiz',
                    title: 'Personel-Sınav Atama',
                    type: 'item',
                    url: '/definitions/assignmentQuiz',
                    breadcrumbs: true
                },
                {
                    id: 'assignment-quiz_to_department',
                    title: 'Departman-Sınav Atama',
                    type: 'item',
                    url: '/definitions/quizAssignmentToDepartment',
                    breadcrumbs: true
                }        
            ]
        },
        {
            id: 'personnels',
            title: 'Personeller',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'new-personnel',
                    title: 'Yeni Personel',
                    type: 'item',
                    url: '/definitions/newPersonnel',
                    breadcrumbs: true
                },
                {
                    id: 'personnels',
                    title: 'Personel Listesi',
                    type: 'item',
                    url: '/definitions/personnels',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'documentmanagement',
            title: 'Döküman Yönetimi',
            type: 'collapse',
            icon: icons.IconBriefcase,
            children: [
                {
                    id: 'document-permissions',
                    title: 'Yetkilendirme',
                    type: 'item',
                    url: '/definitions/documentPermissions',
                    breadcrumbs: true
                },
                {
                    id: 'all-document-permissions',
                    title: 'Tüm Yetkiler',
                    type: 'item',
                    url: '/definitions/alldocumentpermissions',
                    breadcrumbs: true
                }                
            ]
        },
        {
            id: 'announcementmanagement',
            title: 'Duyuru Yönetimi',
            type: 'collapse',
            icon: icons.IconSpeakerphone,
            children: [
                {
                    id: 'announcement',
                    title: 'Duyuru Yayınla',
                    type: 'item',
                    url: '/definitions/announcement',
                    breadcrumbs: false
                },
                {
                    id: 'announcements',
                    title: 'Duyurular',
                    type: 'item',
                    url: '/definitions/announcements',
                    breadcrumbs: true
                }                          
            ]
        },
        {
            id: 'pagepermissions',
            title: 'Ekran Yetkileri',
            type: 'collapse',
            icon: icons.IconLicense,
            children: [
                {
                    id: 'pagepermission',
                    title: 'Yetki Atama',
                    type: 'item',
                    url: '/definitions/pagepermission',
                    breadcrumbs: true
                }
            ]
        }        
    ]
};

let pagePermissions = [];
pagePermissions = linq.from(JSON.parse(localStorage.getItem('@pagePermissions'))).toArray();

if (pagePermissions.length > 0) {
    let temp = pagePermissions.find((item) => item.title === 'Tanımlamalar'); 
    let mapForIcons = []
    if (temp !== undefined) {
        mapForIcons = temp.children.map(item => ({
            ...item,
            icon: item.icon === "icons.IconBuilding" && icons.IconBuilding || item.icon === "icons.IconLetterD" && icons.IconLetterD ||
            item.icon === "icons.IconBooks" && icons.IconBooks || item.icon === "icons.IconChefHat" && icons.IconChefHat ||     
            item.icon === "icons.IconHeartHandshake" && icons.IconHeartHandshake ||  item.icon === "icons.IconCheckbox" && icons.IconCheckbox ||
            item.icon ==="icons.IconCheckupList" && icons.IconCheckupList ||  item.icon ==="icons.IconQuestionMark" && icons.IconQuestionMark || item.icon === "icons.IconCertificate" && icons.IconCertificate ||
            item.icon ==="icons.IconUsers" && icons.IconUsers || item.icon === "icons.IconConfetti" && icons.IconConfetti || item.icon === "icons.IconBriefcase" && icons.IconBriefcase ||
            item.icon ==="icons.IconLicense" && icons.IconLicense  || item.icon === "icons.IconSpeakerphone" && icons.IconSpeakerphone   
       }));   
        temp.children = mapForIcons;
        definitions = temp;
    }else {
        definitions = {};
    }    
}else definitions = {};

export default definitions;
