import axios from 'axios';
import * as actionTypes from '../types';
import {
    GET_ALL_NOTIFICATIONS_API,
    READ_ALL_NOTIFICATIONS_API 
} from '../../_config/env';

export const getAll = () => {
    return (dispatch) => {
      dispatch(getAllStart());
      axios
        .get(GET_ALL_NOTIFICATIONS_API)
        .then((response) => {
          dispatch(getAllSuccess(response));
        })
        .catch(function (error) {
          dispatch(getAllFail(error));
        });
    };
  };
  export const getAllStart = () => {
    return {
      type: actionTypes.GET_ALL_NOTIFICATIONS_START,
      isLoading: true,
    };
  };
  export const getAllSuccess = (data) => {
    return {
      type: actionTypes.GET_ALL_NOTIFICATIONS_SUCCESS,
      payload: data.data.data,
      isLoading: false,
      isSucceedForGetAll: data.data.hasError === false ? true : false,
    };
  };
  export const getAllFail = (error) => {
    return {
      type: actionTypes.GET_ALL_NOTIFICATIONS_FAIL,
      errors: error,
      isLoading: false,
    };
  };
  export const getAllReset = () => {
    return {
      type: actionTypes.GET_ALL_NOTIFICATIONS_RESET,
      //payload: null,
      errors: null,
      isLoading: false,
      isSucceedForGetAll: null,
    };
  };
  export const pushSuccess = () => {
    return {
      type: actionTypes.PUSH_NOTIFICATION_SUCCESS,
      pushIsSucceed: true
    }
  };
  export const pushReset = () => {
    return {
      type: actionTypes.PUSH_NOTIFICATION_RESET,
      pushIsSucceed: null,
    }
  };
  export const readAll = (notificationIds) => {
    return (dispatch) => {
      dispatch(readAllStart());
      axios
        .post(READ_ALL_NOTIFICATIONS_API,{
          notificationIds: notificationIds
        })
        .then((response) => {
          dispatch(readAllSuccess(response));
        })
        .catch(function (error) {
          dispatch(readAllFail(error));
        });
    };
  };
  export const readAllStart = () => {
    return {
      type: actionTypes.READ_ALL_NOTIFICATIONS_START,
      isLoading: true,
    };
  };
  export const readAllSuccess = (data) => {
    return {
      type: actionTypes.READ_ALL_NOTIFICATIONS_SUCCESS,
      isLoading: false,
      isSucceedForReadAll: data.data.hasError === false ? true : false,
    };
  };
  export const readAllFail = (error) => {
    return {
      type: actionTypes.READ_ALL_NOTIFICATIONS_FAIL,
      errors: error,
      isLoading: false,
    };
  };
  export const readAllReset = () => {
    return {
      type: actionTypes.READ_ALL_NOTIFICATIONS_RESET,
      errors: null,
      isLoading: false,
      isSucceedForReadAll: null,
    };
  };
