import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForUpdate: null,
    isSucceedForDelete: null,
    isSucceedForGet: null,
    isSucceedForGetAnnouncements: null,
    isSucceedForGetAnnouncementsByUser: null,
    isSucceedForUpdateStatus: null,
    announcementCodeForUpdate: null,
    isReadyForUpdate: null,
};
const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const updateStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const updateSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForUpdate: action.isSucceedForUpdate,
    });
};
const updateFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const updateReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForUpdate: action.isSucceedForUpdate,
    });
};
const deleteStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const deleteSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForDelete: action.isSucceedForDelete,
    });
};
const deleteFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const deleteReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForDelete: action.isSucceedForDelete,
    });
};
const getAnnouncementStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getAnnouncementSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getAnnouncementFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getAnnouncementReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getAnnouncementsStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getAnnouncementsSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAnnouncements: action.isSucceedForGetAnnouncements,
    });
};
const getAnnouncementsFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getAnnouncementsReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAnnouncements: action.isSucceedForGetAnnouncements,
    });
};
const getAnnouncementsByUserStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getAnnouncementsByUserSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAnnouncementsByUser: action.isSucceedForGetAnnouncementsByUser,
    });
};
const getAnnouncementsByUserFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getAnnouncementsByUserReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAnnouncementsByUser: action.isSucceedForGetAnnouncementsByUser,
    });
};
const updateAnnouncementStatusStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const updateAnnouncementStatusSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForUpdateStatus: action.isSucceedForUpdateStatus,
    });
};
const updateAnnouncementStatusFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const updateAnnouncementStatusReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForUpdateStatus: action.isSucceedForUpdateStatus,
    });
};
const updateAnnouncementForUpdateStart = (state, action) => {
    return updateObject(state, {
        isReadyForUpdate: action.isReadyForUpdate,
        announcementCodeForUpdate: action.announcementCodeForUpdate
    });
};
const updateAnnouncementForUpdateStop = (state, action) => {
    return updateObject(state, {
        isReadyForUpdate: action.isReadyForUpdate,
        announcementCodeForUpdate: action.announcementCodeForUpdate
    });
}
const updateAnnouncementForUpdateReset = (state, action) => {
    return updateObject(state, {
        isReadyForUpdate: action.isReadyForUpdate,
        announcementCodeForUpdate: action.announcementCodeForUpdate
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_ANNOUNCEMENT_START:
            return addStart(state,action);
        case actionTypes.ADD_ANNOUNCEMENT_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_ANNOUNCEMENT_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_ANNOUNCEMENT_RESET:
            return addReset(state,action);
        case actionTypes.GET_ANNOUNCEMENT_START:
            return getAnnouncementStart(state, action);
        case actionTypes.GET_ANNOUNCEMENT_SUCCESS:
            return getAnnouncementSuccess(state, action);
        case actionTypes.GET_ANNOUNCEMENT_FAIL:
            return getAnnouncementFail(state, action);
        case actionTypes.GET_ANNOUNCEMENT_RESET:
            return getAnnouncementReset(state, action);
        case actionTypes.GET_ANNOUNCEMENTS_START:
            return getAnnouncementsStart(state, action);
        case actionTypes.GET_ANNOUNCEMENTS_SUCCESS:
            return getAnnouncementsSuccess(state, action);
        case actionTypes.GET_ANNOUNCEMENTS_FAIL:
            return getAnnouncementsFail(state, action);
        case actionTypes.GET_ANNOUNCEMENTS_RESET:
            return getAnnouncementsReset(state, action);
        case actionTypes.GET_ANNOUNCEMENTS_BY_USER_START:
            return getAnnouncementsByUserStart(state, action);
        case actionTypes.GET_ANNOUNCEMENTS_BY_USER_SUCCESS:
            return getAnnouncementsByUserSuccess(state, action);
        case actionTypes.GET_ANNOUNCEMENTS_BY_USER_FAIL:
            return getAnnouncementsByUserFail(state, action);
        case actionTypes.GET_ANNOUNCEMENTS_BY_USER_RESET:
            return getAnnouncementsByUserReset(state, action);
        case actionTypes.UPDATE_ANNOUNCEMENT_START:
            return updateStart(state, action);
        case actionTypes.UPDATE_ANNOUNCEMENT_SUCCESS:
            return updateSuccess(state, action);
        case actionTypes.UPDATE_ANNOUNCEMENT_FAIL:
            return updateFail(state, action);
        case actionTypes.UPDATE_ANNOUNCEMENT_RESET:
            return updateReset(state, action);
        case actionTypes.DELETE_ANNOUNCEMENT_START:
            return deleteStart(state, action);
        case actionTypes.DELETE_ANNOUNCEMENT_SUCCESS:
            return deleteSuccess(state, action);
        case actionTypes.DELETE_ANNOUNCEMENT_FAIL:
            return deleteFail(state, action);
        case actionTypes.DELETE_ANNOUNCEMENT_RESET:
            return deleteReset(state, action);
        case actionTypes.UPDATE_ANNOUNCEMENT_STATUS_START:
            return updateAnnouncementStatusStart(state, action);
        case actionTypes.UPDATE_ANNOUNCEMENT_STATUS_SUCCESS:
            return updateAnnouncementStatusSuccess(state, action);
        case actionTypes.UPDATE_ANNOUNCEMENT_STATUS_FAIL:
            return updateAnnouncementStatusFail(state, action);
        case actionTypes.UPDATE_ANNOUNCEMENT_STATUS_RESET:
            return updateAnnouncementStatusReset(state, action);
        case actionTypes.READY_ANNOUNCEMENT_FOR_UPDATE_START:
            return updateAnnouncementForUpdateStart(state, action);
        case actionTypes.READY_ANNOUNCEMENT_FOR_UPDATE_STOP:
            return updateAnnouncementForUpdateStop(state, action);
        case actionTypes.READY_ANNOUNCEMENT_FOR_UPDATE_RESET:
            return updateAnnouncementForUpdateReset(state, action);
        default:
            return state;
    };
};

export default reducer;
