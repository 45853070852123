export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_OUT = 'AUTH_OUT';

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_RESET = 'LOGIN_RESET';
export const LOGIN_RESET_WITHOUT_TOKEN = 'LOGIN_RESET_WITHOUT_TOKEN';
export const LOGOUT = 'LOGOUT';

export const ADD_DEPARTMENT_START = "ADD_DEPARTMENT_START";
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_FAIL = "ADD_DEPARTMENT_FAIL";
export const ADD_DEPARTMENT_RESET = "ADD_DEPARTMENT_RESET";

export const GET_DEPARTMENT_START = "GET_DEPARTMENT_START";
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";
export const GET_DEPARTMENT_FAIL = "GET_DEPARTMENT_FAIL";
export const GET_DEPARTMENT_RESET = "GET_DEPARTMENT_RESET";

export const GET_ALL_DEPARTMENT_START = "GET_ALL_DEPARTMENT_START";
export const GET_ALL_DEPARTMENT_SUCCESS = "GET_ALL_DEPARTMENT_SUCCESS";
export const GET_ALL_DEPARTMENT_FAIL = "GET_ALL_DEPARTMENT_FAIL";
export const GET_ALL_DEPARTMENT_RESET = "GET_ALL_DEPARTMENT_RESET";

export const DELETE_DEPARTMENT_START = "DELETE_DEPARTMENT_START";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAIL = "DELETE_DEPARTMENT_FAIL";
export const DELETE_DEPARTMENT_RESET = "DELETE_DEPARTMENT_RESET";

export const PUT_DEPARTMENT_START = "PUT_DEPARTMENT_START";
export const PUT_DEPARTMENT_SUCCESS = "PUT_DEPARTMENT_SUCCESS";
export const PUT_DEPARTMENT_FAIL = "PUT_DEPARTMENT_FAIL";
export const PUT_DEPARTMENT_RESET = "PUT_DEPARTMENT_RESET";

export const ADD_DEPARTMENT_CARD_START = "ADD_DEPARTMENT_CARD_START";
export const ADD_DEPARTMENT_CARD_SUCCESS = "ADD_DEPARTMENT_CARD_SUCCESS";
export const ADD_DEPARTMENT_CARD_FAIL = "ADD_DEPARTMENT_CARD_FAIL";
export const ADD_DEPARTMENT_CARD_RESET = "ADD_DEPARTMENT_CARD_RESET";

export const GET_DEPARTMENT_CARD_START = "GET_DEPARTMENT_CARD_START";
export const GET_DEPARTMENT_CARD_SUCCESS = "GET_DEPARTMENT_CARD_SUCCESS";
export const GET_DEPARTMENT_CARD_FAIL = "GET_DEPARTMENT_CARD_FAIL";
export const GET_DEPARTMENT_CARD_RESET = "GET_DEPARTMENT_CARD_RESET";

export const GET_ALL_DEPARTMENT_CARD_START = "GET_ALL_DEPARTMENT_CARD_START";
export const GET_ALL_DEPARTMENT_CARD_SUCCESS = "GET_ALL_DEPARTMENT_CARD_SUCCESS";
export const GET_ALL_DEPARTMENT_CARD_FAIL = "GET_ALL_DEPARTMENT_CARD_FAIL";
export const GET_ALL_DEPARTMENT_CARD_RESET = "GET_ALL_DEPARTMENT_CARD_RESET";

export const DELETE_DEPARTMENT_CARD_START = "DELETE_DEPARTMENT_CARD_START";
export const DELETE_DEPARTMENT_CARD_SUCCESS = "DELETE_DEPARTMENT_CARD_SUCCESS";
export const DELETE_DEPARTMENT_CARD_FAIL = "DELETE_DEPARTMENT_CARD_FAIL";
export const DELETE_DEPARTMENT_CARD_RESET = "DELETE_DEPARTMENT_CARD_RESET";

export const PUT_DEPARTMENT_CARD_START = "PUT_DEPARTMENT_CARD_START";
export const PUT_DEPARTMENT_CARD_SUCCESS = "PUT_DEPARTMENT_CARD_SUCCESS";
export const PUT_DEPARTMENT_CARD_FAIL = "PUT_DEPARTMENT_CARD_FAIL";
export const PUT_DEPARTMENT_CARD_RESET = "PUT_DEPARTMENT_CARD_RESET";

export const ADD_BRANCH_OFFICE_START = "ADD_BRANCH_OFFICE_START";
export const ADD_BRANCH_OFFICE_SUCCESS = "ADD_BRANCH_OFFICE_SUCCESS";
export const ADD_BRANCH_OFFICE_FAIL = "ADD_BRANCH_OFFICE_FAIL";
export const ADD_BRANCH_OFFICE_RESET = "ADD_BRANCH_OFFICE_RESET";

export const GET_BRANCH_OFFICE_START = "GET_BRANCH_OFFICE_START";
export const GET_BRANCH_OFFICE_SUCCESS = "GET_BRANCH_OFFICE_SUCCESS";
export const GET_BRANCH_OFFICE_FAIL = "GET_BRANCH_OFFICE_FAIL";
export const GET_BRANCH_OFFICE_RESET = "GET_BRANCH_OFFICE_RESET";

export const GET_ALL_BRANCH_OFFICE_START = "GET_ALL_BRANCH_OFFICE_START";
export const GET_ALL_BRANCH_OFFICE_SUCCESS = "GET_ALL_BRANCH_OFFICE_SUCCESS";
export const GET_ALL_BRANCH_OFFICE_FAIL = "GET_ALL_BRANCH_OFFICE_FAIL";
export const GET_ALL_BRANCH_OFFICE_RESET = "GET_ALL_BRANCH_OFFICE_RESET";

export const DELETE_BRANCH_OFFICE_START = "DELETE_BRANCH_OFFICE_START";
export const DELETE_BRANCH_OFFICE_SUCCESS = "DELETE_BRANCH_OFFICE_SUCCESS";
export const DELETE_BRANCH_OFFICE_FAIL = "DELETE_BRANCH_OFFICE_FAIL";
export const DELETE_BRANCH_OFFICE_RESET = "DELETE_BRANCH_OFFICE_RESET";

export const PUT_BRANCH_OFFICE_START = "PUT_BRANCH_OFFICE_START";
export const PUT_BRANCH_OFFICE_SUCCESS = "PUT_BRANCH_OFFICE_SUCCESS";
export const PUT_BRANCH_OFFICE_FAIL = "PUT_BRANCH_OFFICE_FAIL";
export const PUT_BRANCH_OFFICE_RESET = "PUT_BRANCH_OFFICE_RESET";

export const GET_ALL_USER_START = "GET_ALL_USER_START";
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS";
export const GET_ALL_USER_FAIL = "GET_ALL_USER_FAIL";
export const GET_ALL_USER_RESET = "GET_ALL_USER_RESET";

export const ACCEPT_USER_START = "ACCEPT_USER_START";
export const ACCEPT_USER_SUCCESS = "ACCEPT_USER_SUCCESS";
export const ACCEPT_USER_FAIL = "ACCEPT_USER_FAIL";
export const ACCEPT_USER_RESET = "ACCEPT_USER_RESET";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_RESET = "UPDATE_USER_RESET";

export const GET_USER_BY_CODE_START = "GET_USER_BY_CODE_START";
export const GET_USER_BY_CODE_SUCCESS = "GET_USER_BY_CODE_SUCCESS";
export const GET_USER_BY_CODE_FAIL = "GET_USER_BY_CODE_FAIL";
export const GET_USER_BY_CODE_RESET = "GET_USER_BY_CODE_RESET";

export const ADD_JOB_START = "ADD_JOB_START";
export const ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS";
export const ADD_JOB_FAIL = "ADD_JOB_FAIL";
export const ADD_JOB_RESET = "ADD_JOB_RESET";

export const GET_JOB_START = "GET_JOB_START";
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";
export const GET_JOB_FAIL = "GET_JOB_FAIL";
export const GET_JOB_RESET = "GET_JOB_RESET";

export const GET_ALL_JOB_START = "GET_ALL_JOB_START";
export const GET_ALL_JOB_SUCCESS = "GET_ALL_JOB_SUCCESS";
export const GET_ALL_JOB_FAIL = "GET_ALL_JOB_FAIL";
export const GET_ALL_JOB_RESET = "GET_ALL_JOB_RESET";

export const DELETE_JOB_START = "DELETE_JOB_START";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAIL = "DELETE_JOB_FAIL";
export const DELETE_JOB_RESET = "DELETE_JOB_RESET";

export const PUT_JOB_START = "PUT_JOB_START";
export const PUT_JOB_SUCCESS = "PUT_JOB_SUCCESS";
export const PUT_JOB_FAIL = "PUT_JOB_FAIL";
export const PUT_JOB_RESET = "PUT_JOB_RESET";

export const ADD_PERMISSION_TYPE_START = "ADD_PERMISSION_TYPE_START";
export const ADD_PERMISSION_TYPE_SUCCESS = "ADD_PERMISSION_TYPE_SUCCESS";
export const ADD_PERMISSION_TYPE_FAIL = "ADD_PERMISSION_TYPE_FAIL";
export const ADD_PERMISSION_TYPE_RESET = "ADD_PERMISSION_TYPE_RESET";

export const GET_PERMISSION_TYPE_START = "GET_PERMISSION_TYPE_START";
export const GET_PERMISSION_TYPE_SUCCESS = "GET_PERMISSION_TYPE_SUCCESS";
export const GET_PERMISSION_TYPE_FAIL = "GET_PERMISSION_TYPE_FAIL";
export const GET_PERMISSION_TYPE_RESET = "GET_PERMISSION_TYPE_RESET";

export const GET_ALL_PERMISSION_TYPE_START = "GET_ALL_PERMISSION_TYPE_START";
export const GET_ALL_PERMISSION_TYPE_SUCCESS = "GET_ALL_PERMISSION_TYPE_SUCCESS";
export const GET_ALL_PERMISSION_TYPE_FAIL = "GET_ALL_PERMISSION_TYPE_FAIL";
export const GET_ALL_PERMISSION_TYPE_RESET = "GET_ALL_PERMISSION_TYPE_RESET";

export const DELETE_PERMISSION_TYPE_START = "DELETE_PERMISSION_TYPE_START";
export const DELETE_PERMISSION_TYPE_SUCCESS = "DELETE_PERMISSION_TYPE_SUCCESS";
export const DELETE_PERMISSION_TYPE_FAIL = "DELETE_PERMISSION_TYPE_FAIL";
export const DELETE_PERMISSION_TYPE_RESET = "DELETE_PERMISSION_TYPE_RESET";

export const PUT_PERMISSION_TYPE_START = "PUT_PERMISSION_TYPE_START";
export const PUT_PERMISSION_TYPE_SUCCESS = "PUT_PERMISSION_TYPE_SUCCESS";
export const PUT_PERMISSION_TYPE_FAIL = "PUT_PERMISSION_TYPE_FAIL";
export const PUT_PERMISSION_TYPE_RESET = "PUT_PERMISSION_TYPE_RESET";

export const ADD_PERMISSION_START = "ADD_PERMISSION_START";
export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS";
export const ADD_PERMISSION_FAIL = "ADD_PERMISSION_FAIL";
export const ADD_PERMISSION_RESET = "ADD_PERMISSION_RESET";

export const APPROVE_PERMISSION_START = "APPROVE_PERMISSION_START";
export const APPROVE_PERMISSION_SUCCESS = "APPROVE_PERMISSION_SUCCESS";
export const APPROVE_PERMISSION_FAIL = "APPROVE_PERMISSION_FAIL";
export const APPROVE_PERMISSION_RESET = "APPROVE_PERMISSION_RESET";

export const REJECT_PERMISSION_START = "REJECT_PERMISSION_START";
export const REJECT_PERMISSION_SUCCESS = "REJECT_PERMISSION_SUCCESS";
export const REJECT_PERMISSION_FAIL = "REJECT_PERMISSION_FAIL";
export const REJECT_PERMISSION_RESET = "REJECT_PERMISSION_RESET";

export const GET_PERMISSION_BY_MANAGER_START = "GET_PERMISSION_BY_MANAGER_START";
export const GET_PERMISSION_BY_MANAGER_SUCCESS = "GET_PERMISSION_BY_MANAGER_SUCCESS";
export const GET_PERMISSION_BY_MANAGER_FAIL = "GET_PERMISSION_BY_MANAGER_FAIL";
export const GET_PERMISSION_BY_MANAGER_RESET = "GET_PERMISSION_BY_MANAGER_RESET";

export const GET_APPROVED_PERMISSION_START = "GET_APPROVED_PERMISSION_START";
export const GET_APPROVED_PERMISSION_SUCCESS = "GET_APPROVED_PERMISSION_SUCCESS";
export const GET_APPROVED_PERMISSION_FAIL = "GET_APPROVED_PERMISSION_FAIL";
export const GET_APPROVED_PERMISSION_RESET = "GET_APPROVED_PERMISSION_RESET";

export const GET_REJECTED_PERMISSION_START = "GET_REJECTED_PERMISSION_START";
export const GET_REJECTED_PERMISSION_SUCCESS = "GET_REJECTED_PERMISSION_SUCCESS";
export const GET_REJECTED_PERMISSION_FAIL = "GET_REJECTED_PERMISSION_FAIL";
export const GET_REJECTED_PERMISSION_RESET = "GET_REJECTED_PERMISSION_RESET";

export const GET_WAITING_PERMISSION_START = "GET_WAITING_PERMISSION_START";
export const GET_WAITING_PERMISSION_SUCCESS = "GET_WAITING_PERMISSION_SUCCESS";
export const GET_WAITING_PERMISSION_FAIL = "GET_WAITING_PERMISSION_FAIL";
export const GET_WAITING_PERMISSION_RESET = "GET_WAITING_PERMISSION_RESET";

export const GET_ALL_PERMISSION_START = "GET_ALL_PERMISSION_START";
export const GET_ALL_PERMISSION_SUCCESS = "GET_ALL_PERMISSION_SUCCESS";
export const GET_ALL_PERMISSION_FAIL = "GET_ALL_PERMISSION_FAIL";
export const GET_ALL_PERMISSION_RESET = "GET_ALL_PERMISSION_RESET";

export const CALCULATE_PERMISSION_START = "CALCULATE_PERMISSION_START";
export const CALCULATE_PERMISSION_SUCCESS = "CALCULATE_PERMISSION_SUCCESS";
export const CALCULATE_PERMISSION_FAIL = "CALCULATE_PERMISSION_FAIL";
export const CALCULATE_PERMISSION_RESET = "CALCULATE_PERMISSION_RESET";

export const ADD_SUPPORT_TYPE_START = "ADD_SUPPORT_TYPE_START";
export const ADD_SUPPORT_TYPE_SUCCESS = "ADD_SUPPORT_TYPE_SUCCESS";
export const ADD_SUPPORT_TYPE_FAIL = "ADD_SUPPORT_TYPE_FAIL";
export const ADD_SUPPORT_TYPE_RESET = "ADD_SUPPORT_TYPE_RESET";

export const ADD_SUPPORT_START = "ADD_SUPPORT_START";
export const ADD_SUPPORT_SUCCESS = "ADD_SUPPORT_SUCCESS";
export const ADD_SUPPORT_FAIL = "ADD_SUPPORT_FAIL";
export const ADD_SUPPORT_RESET = "ADD_SUPPORT_RESET";

export const GET_SUPPORT_TYPE_START = "GET_SUPPORT_TYPE_START";
export const GET_SUPPORT_TYPE_SUCCESS = "GET_SUPPORT_TYPE_SUCCESS";
export const GET_SUPPORT_TYPE_FAIL = "GET_SUPPORT_TYPE_FAIL";
export const GET_SUPPORT_TYPE_RESET = "GET_SUPPORT_TYPE_RESET";

export const GET_SUPPORT_START = "GET_SUPPORT_START";
export const GET_SUPPORT_SUCCESS = "GET_SUPPORT_SUCCESS";
export const GET_SUPPORT_FAIL = "GET_SUPPORT_FAIL";
export const GET_SUPPORT_RESET = "GET_SUPPORT_RESET";

export const GET_ALL_SUPPORT_TYPE_START = "GET_ALL_SUPPORT_TYPE_START";
export const GET_ALL_SUPPORT_TYPE_SUCCESS = "GET_ALL_SUPPORT_TYPE_SUCCESS";
export const GET_ALL_SUPPORT_TYPE_FAIL = "GET_ALL_SUPPORT_TYPE_FAIL";
export const GET_ALL_SUPPORT_TYPE_RESET = "GET_ALL_SUPPORT_TYPE_RESET";

export const GET_ALL_SUPPORT_START = "GET_ALL_SUPPORT_START";
export const GET_ALL_SUPPORT_SUCCESS = "GET_ALL_SUPPORT_SUCCESS";
export const GET_ALL_SUPPORT_FAIL = "GET_ALL_SUPPORT_FAIL";
export const GET_ALL_SUPPORT_RESET = "GET_ALL_SUPPORT_RESET";

export const GET_SUPPORTS_BY_DEPARTMENT_ID_START = "GET_SUPPORTS_BY_DEPARTMENT_ID_START";
export const GET_SUPPORTS_BY_DEPARTMENT_ID_SUCCESS = "GET_SUPPORTS_BY_DEPARTMENT_ID_SUCCESS";
export const GET_SUPPORTS_BY_DEPARTMENT_ID_FAIL = "GET_SUPPORTS_BY_DEPARTMENT_ID_FAIL";
export const GET_SUPPORTS_BY_DEPARTMENT_ID_RESET = "GET_SUPPORTS_BY_DEPARTMENT_ID_RESET";

export const DELETE_SUPPORT_TYPE_START = "DELETE_SUPPORT_TYPE_START";
export const DELETE_SUPPORT_TYPE_SUCCESS = "DELETE_SUPPORT_TYPE_SUCCESS";
export const DELETE_SUPPORT_TYPE_FAIL = "DELETE_SUPPORT_TYPE_FAIL";
export const DELETE_SUPPORT_TYPE_RESET = "DELETE_SUPPORT_TYPE_RESET";

export const PUT_SUPPORT_TYPE_START = "PUT_SUPPORT_TYPE_START";
export const PUT_SUPPORT_TYPE_SUCCESS = "PUT_SUPPORT_TYPE_SUCCESS";
export const PUT_SUPPORT_TYPE_FAIL = "PUT_SUPPORT_TYPE_FAIL";
export const PUT_SUPPORT_TYPE_RESET = "PUT_SUPPORT_TYPE_RESET";

export const PUT_SUPPORT_START = "PUT_SUPPORT_START";
export const PUT_SUPPORT_SUCCESS = "PUT_SUPPORT_SUCCESS";
export const PUT_SUPPORT_FAIL = "PUT_SUPPORT_FAIL";
export const PUT_SUPPORT_RESET = "PUT_SUPPORT_RESET";

export const UPDATE_SUPPORT_STATUS_START = "UPDATE_SUPPORT_STATUS_START";
export const UPDATE_SUPPORT_STATUS_SUCCESS = "UPDATE_SUPPORT_STATUS_SUCCESS";
export const UPDATE_SUPPORT_STATUS_FAIL = "UPDATE_SUPPORT_STATUS_FAIL";
export const UPDATE_SUPPORT_STATUS_RESET = "UPDATE_SUPPORT_STATUS_RESET";

export const GET_SUPPORT_HISTORY_START = "GET_SUPPORT_HISTORY_START";
export const GET_SUPPORT_HISTORY_SUCCESS = "GET_SUPPORT_HISTORY_SUCCESS";
export const GET_SUPPORT_HISTORY_FAIL = "GET_SUPPORT_HISTORY_FAIL";
export const GET_SUPPORT_HISTORY_RESET = "GET_SUPPORT_HISTORY_RESET";

export const ADD_SURVEY_TYPE_START = "ADD_SURVEY_TYPE_START";
export const ADD_SURVEY_TYPE_SUCCESS = "ADD_SURVEY_TYPE_SUCCESS";
export const ADD_SURVEY_TYPE_FAIL = "ADD_SURVEY_TYPE_FAIL";
export const ADD_SURVEY_TYPE_RESET = "ADD_SURVEY_TYPE_RESET";

export const GET_SURVEY_TYPE_START = "GET_SURVEY_TYPE_START";
export const GET_SURVEY_TYPE_SUCCESS = "GET_SURVEY_TYPE_SUCCESS";
export const GET_SURVEY_TYPE_FAIL = "GET_SURVEY_TYPE_FAIL";
export const GET_SURVEY_TYPE_RESET = "GET_SURVEY_TYPE_RESET";

export const GET_ALL_SURVEY_TYPE_START = "GET_ALL_SURVEY_TYPE_START";
export const GET_ALL_SURVEY_TYPE_SUCCESS = "GET_ALL_SURVEY_TYPE_SUCCESS";
export const GET_ALL_SURVEY_TYPE_FAIL = "GET_ALL_SURVEY_TYPE_FAIL";
export const GET_ALL_SURVEY_TYPE_RESET = "GET_ALL_SURVEY_TYPE_RESET";

export const DELETE_SURVEY_TYPE_START = "DELETE_SURVEY_TYPE_START";
export const DELETE_SURVEY_TYPE_SUCCESS = "DELETE_SURVEY_TYPE_SUCCESS";
export const DELETE_SURVEY_TYPE_FAIL = "DELETE_SURVEY_TYPE_FAIL";
export const DELETE_SURVEY_TYPE_RESET = "DELETE_SURVEY_TYPE_RESET";

export const PUT_SURVEY_TYPE_START = "PUT_SURVEY_TYPE_START";
export const PUT_SURVEY_TYPE_SUCCESS = "PUT_SURVEY_TYPE_SUCCESS";
export const PUT_SURVEY_TYPE_FAIL = "PUT_SURVEY_TYPE_FAIL";
export const PUT_SURVEY_TYPE_RESET = "PUT_SURVEY_TYPE_RESET";

export const ADD_SURVEY_START = "ADD_SURVEY_START";
export const ADD_SURVEY_SUCCESS = "ADD_SURVEY_SUCCESS";
export const ADD_SURVEY_FAIL = "ADD_SURVEY_FAIL";
export const ADD_SURVEY_RESET = "ADD_SURVEY_RESET";

export const GET_SURVEY_START = "GET_SURVEY_START";
export const GET_SURVEY_SUCCESS = "GET_SURVEY_SUCCESS";
export const GET_SURVEY_FAIL = "GET_SURVEY_FAIL";
export const GET_SURVEY_RESET = "GET_SURVEY_RESET";

export const GET_ALL_SURVEY_START = "GET_ALL_SURVEY_START";
export const GET_ALL_SURVEY_SUCCESS = "GET_ALL_SURVEY_SUCCESS";
export const GET_ALL_SURVEY_FAIL = "GET_ALL_SURVEY_FAIL";
export const GET_ALL_SURVEY_RESET = "GET_ALL_SURVEY_RESET";

export const GET_ALL_BY_SURVEY_TYPE_START = "GET_ALL_BY_SURVEY_TYPE_START";
export const GET_ALL_BY_SURVEY_TYPE_SUCCESS = "GET_ALL_BY_SURVEY_TYPE_SUCCESS";
export const GET_ALL_BY_SURVEY_TYPE_FAIL = "GET_ALL_BY_SURVEY_TYPE_FAIL";
export const GET_ALL_BY_SURVEY_TYPE_RESET = "GET_ALL_BY_SURVEY_TYPE_RESET";

export const DELETE_SURVEY_START = "DELETE_SURVEY_START";
export const DELETE_SURVEY_SUCCESS = "DELETE_SURVEY_SUCCESS";
export const DELETE_SURVEY_FAIL = "DELETE_SURVEY_FAIL";
export const DELETE_SURVEY_RESET = "DELETE_SURVEY_RESET";

export const PUT_SURVEY_START = "PUT_SURVEY_START";
export const PUT_SURVEY_SUCCESS = "PUT_SURVEY_SUCCESS";
export const PUT_SURVEY_FAIL = "PUT_SURVEY_FAIL";
export const PUT_SURVEY_RESET = "PUT_SURVEY_RESET";

export const ADD_SURVEY_ANSWER_START = "ADD_SURVEY_ANSWER_START";
export const ADD_SURVEY_ANSWER_SUCCESS = "ADD_SURVEY_ANSWER_SUCCESS";
export const ADD_SURVEY_ANSWER_FAIL = "ADD_SURVEY_ANSWER_FAIL";
export const ADD_SURVEY_ANSWER_RESET = "ADD_SURVEY_ANSWER_RESET";

export const GET_SURVEY_ANSWER_START = "GET_SURVEY_ANSWER_START";
export const GET_SURVEY_ANSWER_SUCCESS = "GET_SURVEY_ANSWER_SUCCESS";
export const GET_SURVEY_ANSWER_FAIL = "GET_SURVEY_ANSWER_FAIL";
export const GET_SURVEY_ANSWER_RESET = "GET_SURVEY_ANSWER_RESET";

export const GET_ALL_SURVEY_ANSWER_START = "GET_ALL_SURVEY_ANSWER_START";
export const GET_ALL_SURVEY_ANSWER_SUCCESS = "GET_ALL_SURVEY_ANSWER_SUCCESS";
export const GET_ALL_SURVEY_ANSWER_FAIL = "GET_ALL_SURVEY_ANSWER_FAIL";
export const GET_ALL_SURVEY_ANSWER_RESET = "GET_ALL_SURVEY_ANSWER_RESET";

export const DELETE_SURVEY_ANSWER_START = "DELETE_SURVEY_ANSWER_START";
export const DELETE_SURVEY_ANSWER_SUCCESS = "DELETE_SURVEY_ANSWER_SUCCESS";
export const DELETE_SURVEY_ANSWER_FAIL = "DELETE_SURVEY_ANSWER_FAIL";
export const DELETE_SURVEY_ANSWER_RESET = "DELETE_SURVEY_ANSWER_RESET";

export const PUT_SURVEY_ANSWER_START = "PUT_SURVEY_ANSWER_START";
export const PUT_SURVEY_ANSWER_SUCCESS = "PUT_SURVEY_ANSWER_SUCCESS";
export const PUT_SURVEY_ANSWER_FAIL = "PUT_SURVEY_ANSWER_FAIL";
export const PUT_SURVEY_ANSWER_RESET = "PUT_SURVEY_ANSWER_RESET";

export const GET_ALL_SURVEY_ANSWER_BY_QUESTION_ID_START = "GET_ALL_SURVEY_ANSWER_BY_QUESTION_ID_START";
export const GET_ALL_SURVEY_ANSWER_BY_QUESTION_ID_SUCCESS = "GET_ALL_SURVEY_ANSWER_BY_QUESTION_ID_SUCCESS";
export const GET_ALL_SURVEY_ANSWER_BY_QUESTION_ID_FAIL = "GET_ALL_SURVEY_ANSWER_BY_QUESTION_ID_FAIL";
export const GET_ALL_SURVEY_ANSWER_BY_QUESTION_ID_RESET = "GET_ALL_SURVEY_ANSWER_BY_QUESTION_ID_RESET";

export const GET_ALL_SURVEY_ANSWER_BY_SURVEY_ID_START = "GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_START";
export const GET_ALL_SURVEY_ANSWER_BY_SURVEY_ID_SUCCESS = "GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_SUCCESS";
export const GET_ALL_SURVEY_ANSWER_BY_SURVEY_ID_FAIL = "GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_FAIL";
export const GET_ALL_SURVEY_ANSWER_BY_SURVEY_ID_RESET = "GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_RESET";


export const ADD_SURVEY_QUESTION_START = "ADD_SURVEY_QUESTION_START";
export const ADD_SURVEY_QUESTION_SUCCESS = "ADD_SURVEY_QUESTION_SUCCESS";
export const ADD_SURVEY_QUESTION_FAIL = "ADD_SURVEY_QUESTION_FAIL";
export const ADD_SURVEY_QUESTION_RESET = "ADD_SURVEY_QUESTION_RESET";

export const GET_SURVEY_QUESTION_START = "GET_SURVEY_QUESTION_START";
export const GET_SURVEY_QUESTION_SUCCESS = "GET_SURVEY_QUESTION_SUCCESS";
export const GET_SURVEY_QUESTION_FAIL = "GET_SURVEY_QUESTION_FAIL";
export const GET_SURVEY_QUESTION_RESET = "GET_SURVEY_QUESTION_RESET";

export const GET_ALL_SURVEY_QUESTION_START = "GET_ALL_SURVEY_QUESTION_START";
export const GET_ALL_SURVEY_QUESTION_SUCCESS = "GET_ALL_SURVEY_QUESTION_SUCCESS";
export const GET_ALL_SURVEY_QUESTION_FAIL = "GET_ALL_SURVEY_QUESTION_FAIL";
export const GET_ALL_SURVEY_QUESTION_RESET = "GET_ALL_SURVEY_QUESTION_RESET";

export const DELETE_SURVEY_QUESTION_START = "DELETE_SURVEY_QUESTION_START";
export const DELETE_SURVEY_QUESTION_SUCCESS = "DELETE_SURVEY_QUESTION_SUCCESS";
export const DELETE_SURVEY_QUESTION_FAIL = "DELETE_SURVEY_QUESTION_FAIL";
export const DELETE_SURVEY_QUESTION_RESET = "DELETE_SURVEY_QUESTION_RESET";

export const PUT_SURVEY_QUESTION_START = "PUT_SURVEY_QUESTION_START";
export const PUT_SURVEY_QUESTION_SUCCESS = "PUT_SURVEY_QUESTION_SUCCESS";
export const PUT_SURVEY_QUESTION_FAIL = "PUT_SURVEY_QUESTION_FAIL";
export const PUT_SURVEY_QUESTION_RESET = "PUT_SURVEY_QUESTION_RESET";

export const GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_START = "GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_START";
export const GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_SUCCESS = "GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_SUCCESS";
export const GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_FAIL = "GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_FAIL";
export const GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_RESET = "GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_RESET";

export const ADD_USER_SURVEY_START = "ADD_USER_SURVEY_START";
export const ADD_USER_SURVEY_SUCCESS = "ADD_USER_SURVEY_SUCCESS";
export const ADD_USER_SURVEY_FAIL = "ADD_USER_SURVEY_FAIL";
export const ADD_USER_SURVEY_RESET = "ADD_USER_SURVEY_RESET";

export const GET_USER_SURVEY_BY_USER_ID_START = "GET_USER_SURVEY_BY_USER_ID_START";
export const GET_USER_SURVEY_BY_USER_ID_SUCCESS = "GET_USER_SURVEY_BY_USER_ID_SUCCESS";
export const GET_USER_SURVEY_BY_USER_ID_FAIL = "GET_USER_SURVEY_BY_USER_ID_FAIL";
export const GET_USER_SURVEY_BY_USER_ID_RESET = "GET_USER_SURVEY_BY_USER_ID_RESET";

export const ADD_EDUCATION_TYPE_START = "ADD_EDUCATION_TYPE_START";
export const ADD_EDUCATION_TYPE_SUCCESS = "ADD_EDUCATION_TYPE_SUCCESS";
export const ADD_EDUCATION_TYPE_FAIL = "ADD_EDUCATION_TYPE_FAIL";
export const ADD_EDUCATION_TYPE_RESET = "ADD_EDUCATION_TYPE_RESET";
export const GET_EDUCATION_TYPE_START = "GET_EDUCATION_TYPE_START";
export const GET_EDUCATION_TYPE_SUCCESS = "GET_EDUCATION_TYPE_SUCCESS";
export const GET_EDUCATION_TYPE_FAIL = "GET_EDUCATION_TYPE_FAIL";
export const GET_EDUCATION_TYPE_RESET = "GET_EDUCATION_TYPE_RESET";
export const GET_ALL_EDUCATION_TYPE_START = "GET_ALL_EDUCATION_TYPE_START";
export const GET_ALL_EDUCATION_TYPE_SUCCESS = "GET_ALL_EDUCATION_TYPE_SUCCESS";
export const GET_ALL_EDUCATION_TYPE_FAIL = "GET_ALL_EDUCATION_TYPE_FAIL";
export const GET_ALL_EDUCATION_TYPE_RESET = "GET_ALL_EDUCATION_TYPE_RESET";
export const PUT_EDUCATION_TYPE_START = "PUT_EDUCATION_TYPE_START";
export const PUT_EDUCATION_TYPE_SUCCESS = "PUT_EDUCATION_TYPE_SUCCESS";
export const PUT_EDUCATION_TYPE_FAIL = "PUT_EDUCATION_TYPE_FAIL";
export const PUT_EDUCATION_TYPE_RESET = "PUT_EDUCATION_TYPE_RESET";
export const DELETE_EDUCATION_TYPE_START = "DELETE_EDUCATION_TYPE_START";
export const DELETE_EDUCATION_TYPE_SUCCESS = "DELETE_EDUCATION_TYPE_SUCCESS";
export const DELETE_EDUCATION_TYPE_FAIL = "DELETE_EDUCATION_TYPE_FAIL";
export const DELETE_EDUCATION_TYPE_RESET = "DELETE_EDUCATION_TYPE_RESET";

export const ADD_EDUCATION_START = "ADD_EDUCATION_START";
export const ADD_EDUCATION_UPLOAD_PROGRESS_START = "ADD_EDUCATION_UPLOAD_PROGRESS_START";
export const ADD_EDUCATION_SUCCESS = "ADD_EDUCATION_SUCCESS";
export const ADD_EDUCATION_FAIL = "ADD_EDUCATION_FAIL";
export const ADD_EDUCATION_RESET = "ADD_EDUCATION_RESET";
export const GET_EDUCATION_START = "GET_EDUCATION_START";
export const GET_EDUCATION_SUCCESS = "GET_EDUCATION_SUCCESS";
export const GET_EDUCATION_FAIL = "GET_EDUCATION_FAIL";
export const GET_EDUCATION_RESET = "GET_EDUCATION_RESET";
export const GET_ALL_EDUCATION_START = "GET_ALL_EDUCATION_START";
export const GET_ALL_EDUCATION_SUCCESS = "GET_ALL_EDUCATION_SUCCESS";
export const GET_ALL_EDUCATION_FAIL = "GET_ALL_EDUCATION_FAIL";
export const GET_ALL_EDUCATION_RESET = "GET_ALL_EDUCATION_RESET";
export const PUT_EDUCATION_START = "PUT_EDUCATION_START";
export const PUT_EDUCATION_SUCCESS = "PUT_EDUCATION_SUCCESS";
export const PUT_EDUCATION_FAIL = "PUT_EDUCATION_FAIL";
export const PUT_EDUCATION_RESET = "PUT_EDUCATION_RESET";
export const DELETE_EDUCATION_START = "DELETE_EDUCATION_START";
export const DELETE_EDUCATION_SUCCESS = "DELETE_EDUCATION_SUCCESS";
export const DELETE_EDUCATION_FAIL = "DELETE_EDUCATION_FAIL";
export const DELETE_EDUCATION_RESET = "DELETE_EDUCATION_RESET";

export const ADD_USER_EDUCATION_START = "ADD_USER_EDUCATION_START";
export const ADD_USER_EDUCATION_SUCCESS = "ADD_USER_EDUCATION_SUCCESS";
export const ADD_USER_EDUCATION_FAIL = "ADD_USER_EDUCATION_FAIL";
export const ADD_USER_EDUCATION_RESET = "ADD_USER_EDUCATION_RESET";
export const GET_USER_EDUCATION_START = "GET_USER_EDUCATION_START";
export const GET_USER_EDUCATION_SUCCESS = "GET_USER_EDUCATION_SUCCESS";
export const GET_USER_EDUCATION_FAIL = "GET_USER_EDUCATION_FAIL";
export const GET_USER_EDUCATION_RESET = "GET_USER_EDUCATION_RESET";
export const GET_USER_EDUCATION_BY_EDUCATION_ID_START = "GET_USER_EDUCATION_BY_EDUCATION_ID_START";
export const GET_USER_EDUCATION_BY_EDUCATION_ID_SUCCESS = "GET_USER_EDUCATION_BY_EDUCATION_ID_SUCCESS";
export const GET_USER_EDUCATION_BY_EDUCATION_ID_FAIL = "GET_USER_EDUCATION_BY_EDUCATION_ID_FAIL";
export const GET_USER_EDUCATION_BY_EDUCATION_ID_RESET = "GET_USER_EDUCATION_BY_EDUCATION_ID_RESET";
export const GET_USER_EDUCATION_BY_USER_ID_START = "GET_USER_EDUCATION_BY_USER_ID_START";
export const GET_USER_EDUCATION_BY_USER_ID_SUCCESS = "GET_USER_EDUCATION_BY_USER_ID_SUCCESS";
export const GET_USER_EDUCATION_BY_USER_ID_FAIL = "GET_USER_EDUCATION_BY_USER_ID_FAIL";
export const GET_USER_EDUCATION_BY_USER_ID_RESET = "GET_USER_EDUCATION_BY_USER_ID_RESET";
export const DELETE_USER_EDUCATION_START = "DELETE_USER_EDUCATION_START";
export const DELETE_USER_EDUCATION_SUCCESS = "DELETE_USER_EDUCATION_SUCCESS";
export const DELETE_USER_EDUCATION_FAIL = "DELETE_USER_EDUCATION_FAIL";
export const DELETE_USER_EDUCATION_RESET = "DELETE_USER_EDUCATION_RESET";
export const PUT_USER_EDUCATION_START = "PUT_USER_EDUCATION_START";
export const PUT_USER_EDUCATION_SUCCESS = "PUT_USER_EDUCATION_SUCCESS";
export const PUT_USER_EDUCATION_FAIL = "PUT_USER_EDUCATION_FAIL";
export const PUT_USER_EDUCATION_RESET = "PUT_USER_EDUCATION_RESET";
export const COMPLETE_USER_EDUCATION_START = "COMPLETE_USER_EDUCATION_START";
export const COMPLETE_USER_EDUCATION_SUCCESS = "COMPLETE_USER_EDUCATION_SUCCESS";
export const COMPLETE_USER_EDUCATION_FAIL = "COMPLETE_USER_EDUCATION_FAIL";
export const COMPLETE_USER_EDUCATION_RESET = "COMPLETE_USER_EDUCATION_RESET";
export const GET_INCOMPLETE_USER_EDUCATIONS_START = "GET_INCOMPLETE_USER_EDUCATIONS_START";
export const GET_INCOMPLETE_USER_EDUCATIONS_SUCCESS = "GET_INCOMPLETE_USER_EDUCATIONS_SUCCESS";
export const GET_INCOMPLETE_USER_EDUCATIONS_FAIL = "export const GET_INCOMPLETE_USER_EDUCATIONS_FAIL";
export const GET_INCOMPLETE_USER_EDUCATIONS_RESET = "GET_INCOMPLETE_USER_EDUCATIONS_RESET";
export const UPDATE_HALF_USER_EDUCATION_START = "UPDATE_HALF_USER_EDUCATION_START";
export const UPDATE_HALF_USER_EDUCATION_SUCCESS = "UPDATE_HALF_USER_EDUCATION_SUCCESS";
export const UPDATE_HALF_USER_EDUCATION_FAIL = "UPDATE_HALF_USER_EDUCATION_FAIL";
export const UPDATE_HALF_USER_EDUCATION_RESET = "UPDATE_HALF_USER_EDUCATION_RESET";
export const GET_HALF_EDUCATIONS_START = "GET_HALF_EDUCATIONS_START";
export const GET_HALF_EDUCATIONS_SUCCESS = "GET_HALF_EDUCATIONS_SUCCESS";
export const GET_HALF_EDUCATIONS_FAIL = "GET_HALF_EDUCATIONS_FAIL";
export const GET_HALF_EDUCATIONS_RESET = "GET_HALF_EDUCATIONS_RESET";
export const GET_COMPLETED_EDUCATIONS_START = "GET_COMPLETED_EDUCATIONS_START";
export const GET_COMPLETED_EDUCATIONS_SUCCESS = "GET_COMPLETED_EDUCATIONS_SUCCESS";
export const GET_COMPLETED_EDUCATIONS_FAIL = "GET_COMPLETED_EDUCATIONS_FAIL";
export const GET_COMPLETED_EDUCATIONS_RESET = "GET_COMPLETED_EDUCATIONS_RESET";
export const ASSIGN_EDUCATION_TO_ALL_USERS_START = "ASSIGN_EDUCATION_TO_ALL_USERS_START";
export const ASSIGN_EDUCATION_TO_ALL_USERS_SUCCESS = "ASSIGN_EDUCATION_TO_ALL_USERS_SUCCESS";
export const ASSIGN_EDUCATION_TO_ALL_USERS_FAIL = "ASSIGN_EDUCATION_TO_ALL_USERS_FAIL";
export const ASSIGN_EDUCATION_TO_ALL_USERS_RESET = "ASSIGN_EDUCATION_TO_ALL_USERS_RESET";

export const ADD_QUIZ_START = "ADD_QUIZ_START";
export const ADD_QUIZ_SUCCESS = "ADD_QUIZ_SUCCESS";
export const ADD_QUIZ_FAIL = "ADD_QUIZ_FAIL";
export const ADD_QUIZ_RESET = "ADD_QUIZ_RESET";
export const GET_QUIZ_BY_ID_START = "GET_QUIZ_BY_ID_START";
export const GET_QUIZ_BY_ID_SUCCESS = "GET_QUIZ_BY_ID_SUCCESS";
export const GET_QUIZ_BY_ID_FAIL = "GET_QUIZ_BY_ID_FAIL";
export const GET_QUIZ_BY_ID_RESET = "GET_QUIZ_BY_ID_RESET";
export const GET_QUIZ_BY_EDUCATION_ID_START = "GET_QUIZ_BY_EDUCATION_ID_START";
export const GET_QUIZ_BY_EDUCATION_ID_SUCCESS = "GET_QUIZ_BY_EDUCATION_ID_SUCCESS";
export const GET_QUIZ_BY_EDUCATION_ID_FAIL = "GET_QUIZ_BY_EDUCATION_ID_FAIL";
export const GET_QUIZ_BY_EDUCATION_ID_RESET = "GET_QUIZ_BY_EDUCATION_ID_RESET";
export const PUT_QUIZ_START = "PUT_QUIZ_START";
export const PUT_QUIZ_SUCCESS = "PUT_QUIZ_SUCCESS";
export const PUT_QUIZ_FAIL = "PUT_QUIZ_FAIL";
export const PUT_QUIZ_RESET = "PUT_QUIZ_RESET";
export const DELETE_QUIZ_START = "DELETE_QUIZ_START";
export const DELETE_QUIZ_SUCCESS = "DELETE_QUIZ_SUCCESS";
export const DELETE_QUIZ_FAIL = "DELETE_QUIZ_FAIL";
export const DELETE_QUIZ_RESET = "DELETE_QUIZ_RESET";
export const GET_ALL_QUIZ_START = "GET_ALL_QUIZ_START";
export const GET_ALL_QUIZ_SUCCESS = "GET_ALL_QUIZ_SUCCESS";
export const GET_ALL_QUIZ_FAIL = "GET_ALL_QUIZ_FAIL";
export const GET_ALL_QUIZ_RESET = "GET_ALL_QUIZ_RESET";

export const ADD_QUIZ_QUESTION_START = "ADD_QUIZ_QUESTION_START";
export const ADD_QUIZ_QUESTION_SUCCESS = "ADD_QUIZ_QUESTION_SUCCESS";
export const ADD_QUIZ_QUESTION_FAIL = "ADD_QUIZ_QUESTION_FAIL";
export const ADD_QUIZ_QUESTION_RESET = "ADD_QUIZ_QUESTION_RESET";
export const GET_QUIZ_QUESTION_BY_ID_START = "GET_QUIZ_QUESTION_BY_ID_START";
export const GET_QUIZ_QUESTION_BY_ID_SUCCESS = "GET_QUIZ_QUESTION_BY_ID_SUCCESS";
export const GET_QUIZ_QUESTION_BY_ID_FAIL = "GET_QUIZ_QUESTION_BY_ID_FAIL";
export const GET_QUIZ_QUESTION_BY_ID_RESET = "GET_QUIZ_QUESTION_BY_ID_RESET";
export const GET_QUIZ_QUESTION_BY_QUIZ_ID_START = "GET_QUIZ_QUESTION_BY_QUIZ_ID_START";
export const GET_QUIZ_QUESTION_BY_QUIZ_ID_SUCCESS = "GET_QUIZ_QUESTION_BY_QUIZ_ID_SUCCESS";
export const GET_QUIZ_QUESTION_BY_QUIZ_ID_FAIL = "GET_QUIZ_QUESTION_BY_QUIZ_ID_FAIL";
export const GET_QUIZ_QUESTION_BY_QUIZ_ID_RESET = "GET_QUIZ_QUESTION_BY_QUIZ_ID_RESET";
export const PUT_QUIZ_QUESTION_START = "PUT_QUIZ_QUESTION_START";
export const PUT_QUIZ_QUESTION_SUCCESS = "PUT_QUIZ_QUESTION_SUCCESS";
export const PUT_QUIZ_QUESTION_FAIL = "PUT_QUIZ_QUESTION_FAIL";
export const PUT_QUIZ_QUESTION_RESET = "PUT_QUIZ_QUESTION_RESET";
export const DELETE_QUIZ_QUESTION_START = "DELETE_QUIZ_QUESTION_START";
export const DELETE_QUIZ_QUESTION_SUCCESS = "DELETE_QUIZ_QUESTION_SUCCESS";
export const DELETE_QUIZ_QUESTION_FAIL = "DELETE_QUIZ_QUESTION_FAIL";
export const DELETE_QUIZ_QUESTION_RESET = "DELETE_QUIZ_QUESTION_RESET";

export const ADD_QUIZ_ANSWER_START = "ADD_QUIZ_ANSWER_START";
export const ADD_QUIZ_ANSWER_SUCCESS = "ADD_QUIZ_ANSWER_SUCCESS";
export const ADD_QUIZ_ANSWER_FAIL = "ADD_QUIZ_ANSWER_FAIL";
export const ADD_QUIZ_ANSWER_RESET = "ADD_QUIZ_ANSWER_RESET";
export const GET_QUIZ_ANSWER_BY_QUESTION_ID_START = "GET_QUIZ_ANSWER_BY_QUESTION_ID_START";
export const GET_QUIZ_ANSWER_BY_QUESTION_ID_SUCCESS = "GET_QUIZ_ANSWER_BY_QUESTION_ID_SUCCESS";
export const GET_QUIZ_ANSWER_BY_QUESTION_ID_FAIL = "GET_QUIZ_ANSWER_BY_QUESTION_ID_FAIL";
export const GET_QUIZ_ANSWER_BY_QUESTION_ID_RESET = "GET_QUIZ_ANSWER_BY_QUESTION_ID_RESET";
export const PUT_QUIZ_ANSWER_START = "PUT_QUIZ_ANSWER_START";
export const PUT_QUIZ_ANSWER_SUCCESS = "PUT_QUIZ_ANSWER_SUCCESS";
export const PUT_QUIZ_ANSWER_FAIL = "PUT_QUIZ_ANSWER_FAIL";
export const PUT_QUIZ_ANSWER_RESET = "PUT_QUIZ_ANSWER_RESET";
export const GET_QUIZ_ANSWER_BY_EDUCATION_ID_START = "GET_QUIZ_ANSWER_BY_EDUCATION_ID_START";
export const GET_QUIZ_ANSWER_BY_EDUCATION_ID_SUCCESS = "GET_QUIZ_ANSWER_BY_EDUCATION_ID_SUCCESS";
export const GET_QUIZ_ANSWER_BY_EDUCATION_ID_FAIL = "GET_QUIZ_ANSWER_BY_EDUCATION_ID_FAIL";
export const GET_QUIZ_ANSWER_BY_EDUCATION_ID_RESET = "GET_QUIZ_ANSWER_BY_EDUCATION_ID_RESET";
export const GET_QUIZ_ANSWER_BY_QUIZ_ID_START = "GET_QUIZ_ANSWER_BY_QUIZ_ID_START";
export const GET_QUIZ_ANSWER_BY_QUIZ_ID_SUCCESS = "GET_QUIZ_ANSWER_BY_QUIZ_ID_SUCCESS";
export const GET_QUIZ_ANSWER_BY_QUIZ_ID_FAIL = "GET_QUIZ_ANSWER_BY_QUIZ_ID_FAIL";
export const GET_QUIZ_ANSWER_BY_QUIZ_ID_RESET = "GET_QUIZ_ANSWER_BY_QUIZ_ID_RESET";
export const GET_QUIZ_ANSWER_BY_QUIZ_ASSIGNMENT_ID_START = "GET_QUIZ_ANSWER_BY_QUIZ_ASSIGNMENT_ID_START";
export const GET_QUIZ_ANSWER_BY_QUIZ_ASSIGNMENT_ID_SUCCESS = "GET_QUIZ_ANSWER_BY_QUIZ_ASSIGNMENT_ID_SUCCESS";
export const GET_QUIZ_ANSWER_BY_QUIZ_ASSIGNMENT_ID_FAIL = "GET_QUIZ_ANSWER_BY_QUIZ_ASSIGNMENT_ID_FAIL";
export const GET_QUIZ_ANSWER_BY_QUIZ_ASSIGNMENT_ID_RESET = "GET_QUIZ_ANSWER_BY_QUIZ_ASSIGNMENT_ID_RESET";

export const ADD_USER_QUIZ_START = "ADD_USER_QUIZ_START";
export const ADD_USER_QUIZ_SUCCESS = "ADD_USER_QUIZ_SUCCESS";
export const ADD_USER_QUIZ_FAIL = "ADD_USER_QUIZ_FAIL";
export const ADD_USER_QUIZ_RESET = "ADD_USER_QUIZ_RESET";
export const GET_USER_QUIZ_BY_USER_ID_START = "GET_USER_QUIZ_BY_USER_ID_START";
export const GET_USER_QUIZ_BY_USER_ID_SUCCESS = "GET_USER_QUIZ_BY_USER_ID_SUCCESS";
export const GET_USER_QUIZ_BY_USER_ID_FAIL = "GET_USER_QUIZ_BY_USER_ID_FAIL";
export const GET_USER_QUIZ_BY_USER_ID_RESET = "GET_USER_QUIZ_BY_USER_ID_RESET";
export const PUT_USER_QUIZ_START = "PUT_USER_QUIZ_START";
export const PUT_USER_QUIZ_SUCCESS = "PUT_USER_QUIZ_SUCCESS";
export const PUT_USER_QUIZ_FAIL = "PUT_USER_QUIZ_FAIL";
export const PUT_USER_QUIZ_RESET = "PUT_USER_QUIZ_RESET";

export const ADD_QUIZ_ASSIGNMENT_START = "ADD_QUIZ_ASSIGNMENT_START";
export const ADD_QUIZ_ASSIGNMENT_SUCCESS = "ADD_QUIZ_ASSIGNMENT_SUCCESS";
export const ADD_QUIZ_ASSIGNMENT_FAIL = "ADD_QUIZ_ASSIGNMENT_FAIL";
export const ADD_QUIZ_ASSIGNMENT_RESET = "ADD_QUIZ_ASSIGNMENT_RESET";
export const GET_QUIZ_ASSIGNMENT_BY_USER_ID_START = "GET_QUIZ_ASSIGNMENT_BY_USER_ID_START";
export const GET_QUIZ_ASSIGNMENT_BY_USER_ID_SUCCESS = "GET_QUIZ_ASSIGNMENT_BY_USER_ID_SUCCESS";
export const GET_QUIZ_ASSIGNMENT_BY_USER_ID_FAIL = "GET_QUIZ_ASSIGNMENT_BY_USER_ID_FAIL";
export const GET_QUIZ_ASSIGNMENT_BY_USER_ID_RESET = "GET_QUIZ_ASSIGNMENT_BY_USER_ID_RESET";
export const PUT_QUIZ_ASSIGNMENT_START = "PUT_QUIZ_ASSIGNMENT_START";
export const PUT_QUIZ_ASSIGNMENT_SUCCESS = "PUT_QUIZ_ASSIGNMENT_SUCCESS";
export const PUT_QUIZ_ASSIGNMENT_FAIL = "PUT_QUIZ_ASSIGNMENT_FAIL";
export const PUT_QUIZ_ASSIGNMENT_RESET = "PUT_QUIZ_ASSIGNMENT_RESET";
export const COMPLETE_QUIZ_ASSIGNMENT_START = "COMPLETE_QUIZ_ASSIGNMENT_START";
export const COMPLETE_QUIZ_ASSIGNMENT_SUCCESS = "COMPLETE_QUIZ_ASSIGNMENT_SUCCESS";
export const COMPLETE_QUIZ_ASSIGNMENT_FAIL = "COMPLETE_QUIZ_ASSIGNMENT_FAIL";
export const COMPLETE_QUIZ_ASSIGNMENT_RESET = "COMPLETE_QUIZ_ASSIGNMENT_RESET";
export const ASSIGN_QUIZ_TO_DEPARTMENT_START = "ASSIGN_QUIZ_TO_DEPARTMENT_START";
export const ASSIGN_QUIZ_TO_DEPARTMENT_SUCCESS = "ASSIGN_QUIZ_TO_DEPARTMENT_SUCCESS";
export const ASSIGN_QUIZ_TO_DEPARTMENT_FAIL = "ASSIGN_QUIZ_TO_DEPARTMENT_FAIL";
export const ASSIGN_QUIZ_TO_DEPARTMENT_RESET = "ASSIGN_QUIZ_TO_DEPARTMENT_RESET";
export const GET_QUIZ_ASSIGNMENT_BY_UNSTARTED_START = "GET_QUIZ_ASSIGNMENT_BY_UNSTARTED_START";
export const GET_QUIZ_ASSIGNMENT_BY_UNSTARTED_SUCCESS = "GET_QUIZ_ASSIGNMENT_BY_UNSTARTED_SUCCESS";
export const GET_QUIZ_ASSIGNMENT_BY_UNSTARTED_FAIL = "GET_QUIZ_ASSIGNMENT_BY_UNSTARTED_FAIL";
export const GET_QUIZ_ASSIGNMENT_BY_UNSTARTED_RESET = "GET_QUIZ_ASSIGNMENT_BY_UNSTARTED_RESET";
export const BREAK_QUIZ_ASSIGNMENT_START = "BREAK_QUIZ_ASSIGNMENT_START";
export const BREAK_QUIZ_ASSIGNMENT_SUCCESS = "BREAK_QUIZ_ASSIGNMENT_SUCCESS";
export const BREAK_QUIZ_ASSIGNMENT_FAIL = "BREAK_QUIZ_ASSIGNMENT_FAIL";
export const BREAK_QUIZ_ASSIGNMENT_RESET = "BREAK_QUIZ_ASSIGNMENT_RESET";
export const GET_QUIZ_ASSIGNMENT_BY_IS_HALF_START = "GET_QUIZ_ASSIGNMENT_BY_IS_HALF_START";
export const GET_QUIZ_ASSIGNMENT_BY_IS_HALF_SUCCESS = "GET_QUIZ_ASSIGNMENT_BY_IS_HALF_SUCCESS";
export const GET_QUIZ_ASSIGNMENT_BY_IS_HALF_FAIL = "GET_QUIZ_ASSIGNMENT_BY_IS_HALF_FAIL";
export const GET_QUIZ_ASSIGNMENT_BY_IS_HALF_RESET = "GET_QUIZ_ASSIGNMENT_BY_IS_HALF_RESET";
export const FIND_QUIZ_ASSIGNMENT_BY_EDUCATION_ID_START = "FIND_QUIZ_ASSIGNMENT_BY_EDUCATION_ID_START";
export const FIND_QUIZ_ASSIGNMENT_BY_EDUCATION_ID_SUCCESS = "FIND_QUIZ_ASSIGNMENT_BY_EDUCATION_ID_SUCCESS";
export const FIND_QUIZ_ASSIGNMENT_BY_EDUCATION_ID_FAIL = "FIND_QUIZ_ASSIGNMENT_BY_EDUCATION_ID_FAIL";
export const FIND_QUIZ_ASSIGNMENT_BY_EDUCATION_ID_RESET = "FIND_QUIZ_ASSIGNMENT_BY_EDUCATION_ID_RESET";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const CHANGE_PASSWORD_RESET = "CHANGE_PASSWORD_RESET";

export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";

export const UPDATE_PROFILE_PHOTO_START = "UPDATE_PROFILE_PHOTO_START";
export const UPDATE_PROFILE_PHOTO_SUCCESS = "UPDATE_PROFILE_PHOTO_SUCCESS";
export const UPDATE_PROFILE_PHOTO_FAIL = "UPDATE_PROFILE_PHOTO_FAIL";
export const UPDATE_PROFILE_PHOTO_RESET = "UPDATE_PROFILE_PHOTO_RESET";

export const ADD_DEPARTMENT_EDUCATION_START = "ADD_DEPARTMENT_EDUCATION_START";
export const ADD_DEPARTMENT_EDUCATION_SUCCESS = "ADD_DEPARTMENT_EDUCATION_SUCCESS";
export const ADD_DEPARTMENT_EDUCATION_FAIL = "ADD_DEPARTMENT_EDUCATION_FAIL";
export const ADD_DEPARTMENT_EDUCATION_RESET = "ADD_DEPARTMENT_EDUCATION_RESET";
export const PUT_DEPARTMENT_EDUCATION_START = "PUT_DEPARTMENT_EDUCATION_START";
export const PUT_DEPARTMENT_EDUCATION_SUCCESS = "PUT_DEPARTMENT_EDUCATION_SUCCESS";
export const PUT_DEPARTMENT_EDUCATION_FAIL = "PUT_DEPARTMENT_EDUCATION_FAIL";
export const PUT_DEPARTMENT_EDUCATION_RESET = "PUT_DEPARTMENT_EDUCATION_RESET";
export const GET_DEPARTMENT_EDUCATION_BY_DEPARTMENT_ID_START = "GET_DEPARTMENT_EDUCATION_BY_DEPARTMENT_ID_START";
export const GET_DEPARTMENT_EDUCATION_BY_DEPARTMENT_ID_SUCCESS = "GET_DEPARTMENT_EDUCATION_BY_DEPARTMENT_ID_SUCCESS";
export const GET_DEPARTMENT_EDUCATION_BY_DEPARTMENT_ID_FAIL = "GET_DEPARTMENT_EDUCATION_BY_DEPARTMENT_ID_FAIL";
export const GET_DEPARTMENT_EDUCATION_BY_DEPARTMENT_ID_RESET = "GET_DEPARTMENT_EDUCATION_BY_DEPARTMENT_ID_RESET";

export const GIVE_ANSWER_USER_QUIZ_DETAIL_START = "GIVE_ANSWER_USER_QUIZ_DETAIL_START";
export const GIVE_ANSWER_USER_QUIZ_DETAIL_SUCCESS = "GIVE_ANSWER_USER_QUIZ_DETAIL_SUCCESS";
export const GIVE_ANSWER_USER_QUIZ_DETAIL_FAIL = "GIVE_ANSWER_USER_QUIZ_DETAIL_FAIL";
export const GIVE_ANSWER_USER_QUIZ_DETAIL_RESET = "GIVE_ANSWER_USER_QUIZ_DETAIL_RESET";

export const GET_DEPARTMENT_QUIZ_BY_DEPARTMENT_ID_START = "GET_DEPARTMENT_QUIZ_BY_DEPARTMENT_ID_START";
export const GET_DEPARTMENT_QUIZ_BY_DEPARTMENT_ID_SUCCESS = "GET_DEPARTMENT_QUIZ_BY_DEPARTMENT_ID_SUCCESS";
export const GET_DEPARTMENT_QUIZ_BY_DEPARTMENT_ID_FAIL = "GET_DEPARTMENT_QUIZ_BY_DEPARTMENT_ID_FAIL";
export const GET_DEPARTMENT_QUIZ_BY_DEPARTMENT_ID_RESET = "GET_DEPARTMENT_QUIZ_BY_DEPARTMENT_ID_RESET";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_RESET = "REGISTER_RESET";

export const USER_REGISTER_START = "USER_REGISTER_START";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const SAVE_USER_INFO = "SAVE_USER_INFO";
export const SAVE_USER_INFO_RESET = "SAVE_USER_INFO_RESET";

export const SAVE_ADDRESS_INFO = "SAVE_ADDRESS_INFO";
export const SAVE_ADDRESS_INFO_RESET = "SAVE_ADDRESS_INFO_RESET";

export const GET_ALL_NOTIFICATIONS_START = 'GET_ALL_NOTIFICATIONS_START';
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'GET_ALL_NOTIFICATIONS_SUCCESS';
export const GET_ALL_NOTIFICATIONS_FAIL = 'GET_ALL_NOTIFICATIONS_FAIL';
export const GET_ALL_NOTIFICATIONS_RESET = 'GET_ALL_NOTIFICATIONS_RESET';

export const READ_ALL_NOTIFICATIONS_START = 'READ_ALL_NOTIFICATIONS_START';
export const READ_ALL_NOTIFICATIONS_SUCCESS = 'READ_ALL_NOTIFICATIONS_SUCCESS';
export const READ_ALL_NOTIFICATIONS_FAIL = 'READ_ALL_NOTIFICATIONS_FAIL';
export const READ_ALL_NOTIFICATIONS_RESET = 'READ_ALL_NOTIFICATIONS_RESET';

export const PUSH_NOTIFICATION_SUCCESS = 'PUSH_NOTIFICATION_SUCCESS';
export const PUSH_NOTIFICATION_RESET = 'PUSH_NOTIFICATION_RESET';

export const OPEN_MENU = 'OPEN_MENU';

export const ADD_SETTING_START = 'ADD_SUPPORT_START';
export const ADD_SETTING_SUCCESS = 'ADD_SETTING_SUCCESS';
export const ADD_SETTING_FAIL = 'ADD_SETTING_FAIL';
export const ADD_SETTING_RESET = 'ADD_SETTING_RESET';

export const GET_SETTING_START = 'GET_SETTING_START';
export const GET_SETTING_SUCCESS = 'GET_SETTING_SUCCESS';
export const GET_SETTING_FAIL = 'GET_SETTING_FAIL';
export const GET_SETTING_RESET = 'GET_SETTING_RESET';

export const GET_ALL_SETTING_START = 'GET_ALL_SETTING_START';
export const GET_ALL_SETTING_SUCCESS = 'GET_ALL_SETTING_SUCCESS';
export const GET_ALL_SETTING_FAIL = 'GET_ALL_SETTING_FAIL';
export const GET_ALL_SETTING_RESET = 'GET_ALL_SETTING_RESET';

export const PUT_SETTING_START = 'PUT_SETTING_START';
export const PUT_SETTING_SUCCESS = 'PUT_SETTING_SUCCESS';
export const PUT_SETTING_FAIL = 'PUT_SETTING_FAIL';
export const PUT_SETTING_RESET = 'PUT_SETTING_RESET';

export const GET_SETTING_BY_KEY_START = 'GET_SETTING_BY_KEY_START';
export const GET_SETTING_BY_KEY_SUCCESS = 'GET_SETTING_BY_KEY_SUCCESS';
export const GET_SETTING_BY_KEY_FAIL = 'GET_SETTING_BY_KEY_FAIL';
export const GET_SETTING_BY_KEY_RESET = 'GET_SETTING_BY_KEY_RESET';

export const ADD_DOCUMENT_START = 'ADD_DOCUMENT_START';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_FAIL = 'ADD_DOCUMENT_FAIL';
export const ADD_DOCUMENT_RESET = 'ADD_DOCUMENT_RESET';

export const GET_DOCUMENT_START = 'GET_DOCUMENT_START';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAIL = 'GET_DOCUMENT_FAIL';
export const GET_DOCUMENT_RESET = 'GET_DOCUMENT_RESET';

export const ADD_DOCUMENT_PERMISSION_START = 'ADD_DOCUMENT_PERMISSION_START';
export const ADD_DOCUMENT_PERMISSION_SUCCESS = 'ADD_DOCUMENT_PERMISSION_SUCCESS';
export const ADD_DOCUMENT_PERMISSION_FAIL = 'ADD_DOCUMENT_PERMISSION_FAIL';
export const ADD_DOCUMENT_PERMISSION_RESET = 'ADD_DOCUMENT_PERMISSION_RESET';

export const UPDATE_DOCUMENT_PERMISSION_START = 'UPDATE_DOCUMENT_PERMISSION_START';
export const UPDATE_DOCUMENT_PERMISSION_SUCCESS = 'UPDATE_DOCUMENT_PERMISSION_SUCCESS';
export const UPDATE_DOCUMENT_PERMISSION_FAIL = 'UPDATE_DOCUMENT_PERMISSION_FAIL';
export const UPDATE_DOCUMENT_PERMISSION_RESET = 'UPDATE_DOCUMENT_PERMISSION_RESET';

export const DELETE_DOCUMENT_PERMISSION_START = 'DELETE_DOCUMENT_PERMISSION_START';
export const DELETE_DOCUMENT_PERMISSION_SUCCESS = 'DELETE_DOCUMENT_PERMISSION_SUCCESS';
export const DELETE_DOCUMENT_PERMISSION_FAIL = 'DELETE_DOCUMENT_PERMISSION_FAIL';
export const DELETE_DOCUMENT_PERMISSION_RESET = 'DELETE_DOCUMENT_PERMISSION_RESET';

export const GET_USER_DOCUMENT_PERMISSIONS_START = 'GET_USER_DOCUMENT_PERMISSIONS_START';
export const GET_USER_DOCUMENT_PERMISSIONS_SUCCESS = 'GET_USER_DOCUMENT_PERMISSIONS_SUCCESS';
export const GET_USER_DOCUMENT_PERMISSIONS_FAIL = 'GET_USER_DOCUMENT_PERMISSIONS_FAIL';
export const GET_USER_DOCUMENT_PERMISSIONS_RESET = 'GET_USER_DOCUMENT_PERMISSIONS_RESET';

export const GET_ALL_DOCUMENT_PERMISSIONS_START = 'GET_ALL_DOCUMENT_PERMISSIONS_START';
export const GET_ALL_DOCUMENT_PERMISSIONS_SUCCESS = 'GET_ALL_DOCUMENT_PERMISSIONS_SUCCESS';
export const GET_ALL_DOCUMENT_PERMISSIONS_FAIL = 'GET_ALL_DOCUMENT_PERMISSIONS_FAIL';
export const GET_ALL_DOCUMENT_PERMISSIONS_RESET = 'GET_ALL_DOCUMENT_PERMISSIONS_RESET';

export const GET_USER_DOCUMENT_PERMISSION_START = "GET_USER_DOCUMENT_PERMISSION_START";
export const GET_USER_DOCUMENT_PERMISSION_SUCCESS = "GET_USER_DOCUMENT_PERMISSION_SUCCESS";
export const GET_USER_DOCUMENT_PERMISSION_FAIL = "GET_USER_DOCUMENT_PERMISSION_FAIL";
export const GET_USER_DOCUMENT_PERMISSION_RESET = "GET_USER_DOCUMENT_PERMISSION_RESET";

export const GET_WEATHER_START = 'GET_WEATHER_START';
export const GET_WEATHER_SUCCESS = 'GET_WEATHER_SUCCESS';
export const GET_WEATHER_FAIL = 'GET_WEATHER_FAIL';
export const GET_WEATHER_RESET = 'GET_WEATHER_RESET';

export const GET_FOREIGN_CURRENCIES_START = 'GET_FOREIGN_CURRENCIES_START';
export const GET_FOREIGN_CURRENCIES_SUCCESS = 'GET_FOREIGN_CURRENCIES_SUCCESS';
export const GET_FOREIGN_CURRENCIES_FAIL = 'GET_FOREIGN_CURRENCIES_FAIL';
export const GET_FOREIGN_CURRENCIES_RESET = 'GET_FOREIGN_CURRENCIES_RESET';

export const GET_GENERAL_INFO_START = 'GET_GENERAL_INFO_START';
export const GET_GENERAL_INFO_SUCCESS = 'GET_GENERAL_INFO_SUCCESS';
export const GET_GENERAL_INFO_FAIL = 'GET_GENERAL_INFO_FAIL';
export const GET_GENERAL_INFO_RESET = 'GET_GENERAL_INFO_RESET';

export const ADD_ANNOUNCEMENT_START = 'ADD_ANNOUNCEMENT_START';
export const ADD_ANNOUNCEMENT_SUCCESS = 'ADD_ANNOUNCEMENT_SUCCESS';
export const ADD_ANNOUNCEMENT_FAIL = 'ADD_ANNOUNCEMENT_FAIL';
export const ADD_ANNOUNCEMENT_RESET = 'ADD_ANNOUNCEMENT_RESET';

export const UPDATE_ANNOUNCEMENT_START = 'UPDATE_ANNOUNCEMENT_START';
export const UPDATE_ANNOUNCEMENT_SUCCESS = 'UPDATE_ANNOUNCEMENT_SUCCESS';
export const UPDATE_ANNOUNCEMENT_FAIL = 'UPDATE_ANNOUNCEMENT_FAIL';
export const UPDATE_ANNOUNCEMENT_RESET = 'UPDATE_ANNOUNCEMENT_RESET';

export const DELETE_ANNOUNCEMENT_START = 'DELETE_ANNOUNCEMENT_START';
export const DELETE_ANNOUNCEMENT_SUCCESS = 'DELETE_ANNOUNCEMENT_SUCCESS';
export const DELETE_ANNOUNCEMENT_FAIL = 'DELETE_ANNOUNCEMENT_FAIL';
export const DELETE_ANNOUNCEMENT_RESET = 'DELETE_ANNOUNCEMENT_RESET';

export const GET_ANNOUNCEMENT_START = 'GET_ANNOUNCEMENT_START';
export const GET_ANNOUNCEMENT_SUCCESS = 'GET_ANNOUNCEMENT_SUCCESS';
export const GET_ANNOUNCEMENT_FAIL = 'GET_ANNOUNCEMENT_FAIL';
export const GET_ANNOUNCEMENT_RESET = 'GET_ANNOUNCEMENT_RESET';

export const GET_ANNOUNCEMENTS_START = 'GET_ANNOUNCEMENTS_START';
export const GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS';
export const GET_ANNOUNCEMENTS_FAIL = 'GET_ANNOUNCEMENTS_FAIL';
export const GET_ANNOUNCEMENTS_RESET = 'GET_ANNOUNCEMENTS_RESET';

export const GET_ANNOUNCEMENTS_BY_USER_START = 'GET_ANNOUNCEMENT_BY_USER_START';
export const GET_ANNOUNCEMENTS_BY_USER_SUCCESS = 'GET_ANNOUNCEMENT_BY_USER_SUCCESS';
export const GET_ANNOUNCEMENTS_BY_USER_FAIL = 'GET_ANNOUNCEMENT_BY_USER_FAIL';
export const GET_ANNOUNCEMENTS_BY_USER_RESET = 'GET_ANNOUNCEMENT_BY_USER_RESET';

export const UPDATE_ANNOUNCEMENT_STATUS_START = 'UPDATE_ANNOUNCEMENT_STATUS_START';
export const UPDATE_ANNOUNCEMENT_STATUS_SUCCESS = 'UPDATE_ANNOUNCEMENT_STATUS_SUCCESS';
export const UPDATE_ANNOUNCEMENT_STATUS_FAIL = 'UPDATE_ANNOUNCEMENT_STATUS_FAIL';
export const UPDATE_ANNOUNCEMENT_STATUS_RESET = 'UPDATE_ANNOUNCEMENT_STATUS_RESET';

export const READY_ANNOUNCEMENT_FOR_UPDATE_START = 'READY_ANNOUNCEMENT_FOR_UPDATE_START';
export const READY_ANNOUNCEMENT_FOR_UPDATE_STOP = 'READY_ANNOUNCEMENT_FOR_UPDATE_STOP';
export const READY_ANNOUNCEMENT_FOR_UPDATE_RESET = 'READY_ANNOUNCEMENT_FOR_UPDATE_RESET';

export const ASSIGN_SURVEY_TO_DEPARTMENT_START = 'ASSIGN_SURVEY_TO_DEPARTMENT_START';
export const ASSIGN_SURVEY_TO_DEPARTMENT_SUCCESS = 'ASSIGN_SURVEY_TO_DEPARTMENT_SUCCESS';
export const ASSIGN_SURVEY_TO_DEPARTMENT_FAIL = 'ASSIGN_SURVEY_TO_DEPARTMENT_FAIL';
export const ASSIGN_SURVEY_TO_DEPARTMENT_RESET = 'ASSIGN_SURVEY_TO_DEPARTMENT_RESET';

export const GET_DEPARTMENT_SURVEY_START = 'GET_DEPARTMENT_SURVEY_START';
export const GET_DEPARTMENT_SURVEY_SUCCESS = 'GET_DEPARTMENT_SURVEY_SUCCESS';
export const GET_DEPARTMENT_SURVEY_FAIL = 'GET_DEPARTMENT_SURVEY_FAIL';
export const GET_DEPARTMENT_SURVEY_RESET = 'GET_DEPARTMENT_SURVEY_RESET';

export const ADD_USER_SURVEY_ASSIGNMENT_START = 'ADD_USER_SURVEY_ASSIGNMENT_START';
export const ADD_USER_SURVEY_ASSIGNMENT_SUCCESS = 'ADD_USER_SURVEY_ASSIGNMENT_SUCCESS';
export const ADD_USER_SURVEY_ASSIGNMENT_FAIL = 'ADD_USER_SURVEY_ASSIGNMENT_FAIL';
export const ADD_USER_SURVEY_ASSIGNMENT_RESET = 'ADD_USER_SURVEY_ASSIGNMENT_RESET';

export const UPDATE_USER_SURVEY_ASSIGNMENT_STATUS_START = 'UPDATE_USER_SURVEY_ASSIGNMENT_STATUS_START';
export const UPDATE_USER_SURVEY_ASSIGNMENT_STATUS_SUCCESS = 'UPDATE_USER_SURVEY_ASSIGNMENT_STATUS_SUCCESS';
export const UPDATE_USER_SURVEY_ASSIGNMENT_STATUS_FAIL = 'UPDATE_USER_SURVEY_ASSIGNMENT_STATUS_FAIL';
export const UPDATE_USER_SURVEY_ASSIGNMENT_STATUS_RESET = 'UPDATE_USER_SURVEY_ASSIGNMENT_STATUS_RESET';

export const GET_USER_SURVEY_ASSIGNMENT_BY_USER_START = 'GET_USER_SURVEY_ASSIGNMENT_BY_USER_START';
export const GET_USER_SURVEY_ASSIGNMENT_BY_USER_SUCCESS = 'GET_USER_SURVEY_ASSIGNMENT_BY_USER_SUCCESS';
export const GET_USER_SURVEY_ASSIGNMENT_BY_USER_FAIL = 'GET_USER_SURVEY_ASSIGNMENT_BY_USER_FAIL';
export const GET_USER_SURVEY_ASSIGNMENT_BY_USER_RESET = 'GET_USER_SURVEY_ASSIGNMENT_BY_USER_RESET';

export const GET_USER_SURVEY_ASSIGNMENT_BY_USER_AND_BY_STATUS_START = 'GET_USER_SURVEY_ASSIGNMENT_BY_USER_AND_BY_STATUS_START';
export const GET_USER_SURVEY_ASSIGNMENT_BY_USER_AND_BY_STATUS_SUCCESS = 'GET_USER_SURVEY_ASSIGNMENT_BY_USER_AND_BY_STATUS_SUCCESS';
export const GET_USER_SURVEY_ASSIGNMENT_BY_USER_AND_BY_STATUS_FAIL = 'GET_USER_SURVEY_ASSIGNMENT_BY_USER_AND_BY_STATUS_FAIL';
export const GET_USER_SURVEY_ASSIGNMENT_BY_USER_AND_BY_STATUS_RESET = 'GET_USER_SURVEY_ASSIGNMENT_BY_USER_AND_BY_STATUS_RESET';

export const ADD_DOCUMENT_DRIVE_START = 'ADD_DOCUMENT_DRIVE_START';
export const ADD_DOCUMENT_DRIVE_SUCCESS = 'ADD_DOCUMENT_DRIVE_SUCCESS';
export const ADD_DOCUMENT_DRIVE_FAIL = 'ADD_DOCUMENT_DRIVE_FAIL';
export const ADD_DOCUMENT_DRIVE_RESET = 'ADD_DOCUMENT_DRIVE_RESET';

export const GET_DOCUMENT_DRIVE_BY_CODE_START = 'GET_DOCUMENT_DRIVE_BY_CODE_START';
export const GET_DOCUMENT_DRIVE_BY_CODE_SUCCESS = 'GET_DOCUMENT_DRIVE_BY_CODE_SUCCESS';
export const GET_DOCUMENT_DRIVE_BY_CODE_FAIL = 'GET_DOCUMENT_DRIVE_BY_CODE_FAIL';
export const GET_DOCUMENT_DRIVE_BY_CODE_RESET = 'GET_DOCUMENT_DRIVE_BY_CODE_RESET';

export const GET_DOCUMENTS_DRIVE_BY_SUB_CODE_START = 'GET_DOCUMENTS_DRIVE_BY_SUB_CODE_START';
export const GET_DOCUMENTS_DRIVE_BY_SUB_CODE_SUCCESS = 'GET_DOCUMENTS_DRIVE_BY_SUB_CODE_SUCCESS';
export const GET_DOCUMENTS_DRIVE_BY_SUB_CODE_FAIL = 'GET_DOCUMENTS_DRIVE_BY_SUB_CODE_FAIL';
export const GET_DOCUMENTS_DRIVE_BY_SUB_CODE_RESET = 'GET_DOCUMENTS_DRIVE_BY_SUB_CODE_RESET';

export const GET_ROOT_DOCUMENTS_START = 'GET_ROOT_DOCUMENTS_START';
export const GET_ROOT_DOCUMENTS_SUCCESS = 'GET_ROOT_DOCUMENTS_SUCCESS';
export const GET_ROOT_DOCUMENTS_FAIL = 'GET_ROOT_DOCUMENTS_FAIL';
export const GET_ROOT_DOCUMENTS_RESET = 'GET_ROOT_DOCUMENTS_RESET';

export const GET_UP_DOCUMENTS_START = 'GET_UP_DOCUMENTS_START';
export const GET_UP_DOCUMENTS_SUCCESS = 'GET_UP_DOCUMENTS_SUCCESS';
export const GET_UP_DOCUMENTS_FAIL = 'GET_UP_DOCUMENTS_FAIL';
export const GET_UP_DOCUMENTS_RESET = 'GET_UP_DOCUMENTS_RESET';

export const DELETE_DOCUMENT_START = 'DELETE_DOCUMENT_START';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAIL = 'DELETE_DOCUMENT_FAIL';
export const DELETE_DOCUMENT_RESET = 'DELETE_DOCUMENT_RESET';

export const GIVE_PERMISSION_TO_USER_START = 'GIVE_PERMISSION_TO_USER_START';
export const GIVE_PERMISSION_TO_USER_SUCCESS = 'GIVE_PERMISSION_TO_USER_SUCCESS';
export const GIVE_PERMISSION_TO_USER_FAIL = 'GIVE_PERMISSION_TO_USER_FAIL';
export const GIVE_PERMISSION_TO_USER_RESET = 'GIVE_PERMISSION_TO_USER_RESET';

export const GIVE_PERMISSION_TO_DEPARTMENT_START = 'GIVE_PERMISSION_TO_DEPARTMENT_START';
export const GIVE_PERMISSION_TO_DEPARTMENT_SUCCESS = 'GIVE_PERMISSION_TO_DEPARTMENT_SUCCESS';
export const GIVE_PERMISSION_TO_DEPARTMENT_FAIL = 'GIVE_PERMISSION_TO_DEPARTMENT_FAIL';
export const GIVE_PERMISSION_TO_DEPARTMENT_RESET = 'GIVE_PERMISSION_TO_DEPARTMENT_RESET';

export const GIVE_PERMISSION_TO_ALL_USERS_START = 'GIVE_PERMISSION_TO_ALL_USERS_START';
export const GIVE_PERMISSION_TO_ALL_USERS_SUCCESS = 'GIVE_PERMISSION_TO_ALL_USERS_SUCCESS';
export const GIVE_PERMISSION_TO_ALL_USERS_FAIL = 'GIVE_PERMISSION_TO_ALL_USERS_FAIL';
export const GIVE_PERMISSION_TO_ALL_USERS_RESET = 'GIVE_PERMISSION_TO_ALL_USERS_RESET';

export const GET_PROJECT_PERMISSION_BY_USER_START = 'GET_PROJECT_PERMISSION_BY_USER_START';
export const GET_PROJECT_PERMISSION_BY_USER_SUCCESS = 'GET_PROJECT_PERMISSION_BY_USER_SUCCESS';
export const GET_PROJECT_PERMISSION_BY_USER_FAIL = 'GET_PROJECT_PERMISSION_BY_USER_FAIL';
export const GET_PROJECT_PERMISSION_BY_USER_RESET = 'GET_PROJECT_PERMISSION_BY_USER_RESET';

export const GET_ALL_PROJECT_PAGES_START = 'GET_ALL_PROJECT_PAGES_START';
export const GET_ALL_PROJECT_PAGES_SUCCESS = 'GET_ALL_PROJECT_PAGES_SUCCESS';
export const GET_ALL_PROJECT_PAGES_FAIL = 'GET_ALL_PROJECT_PAGES_FAIL';
export const GET_ALL_PROJECT_PAGES_RESET = 'GET_ALL_PROJECT_PAGES_RESET';

export const GET_PROJECT_PAGES_BY_SUB_CODE_START = 'GET_PROJECT_PAGES_BY_SUB_CODE_START';
export const GET_PROJECT_PAGES_BY_SUB_CODE_SUCCESS = 'GET_PROJECT_PAGES_BY_SUB_CODE_SUCCESS';
export const GET_PROJECT_PAGES_BY_SUB_CODE_FAIL = 'GET_PROJECT_PAGES_BY_SUB_CODE_FAIL';
export const GET_PROJECT_PAGES_BY_SUB_CODE_RESET = 'GET_PROJECT_PAGES_BY_SUB_CODE_RESET';

export const REPORT_EDUCATION_ASSIGNMENTS_START = 'REPORT_EDUCATION_ASSIGNMENTS_START';
export const REPORT_EDUCATION_ASSIGNMENTS_SUCCESS = 'REPORT_EDUCATION_ASSIGNMENTS_SUCCESS';
export const REPORT_EDUCATION_ASSIGNMENTS_FAIL = 'REPORT_EDUCATION_ASSIGNMENTS_FAIL';
export const REPORT_EDUCATION_ASSIGNMENTS_RESET = 'REPORT_EDUCATION_ASSIGNMENTS_RESET';

export const REPORT_QUIZ_ASSIGNMENTS_START = 'REPORT_QUIZ_ASSIGNMENTS_START';
export const REPORT_QUIZ_ASSIGNMENTS_SUCCESS = 'REPORT_QUIZ_ASSIGNMENTS_SUCCESS';
export const REPORT_QUIZ_ASSIGNMENTS_FAIL = 'REPORT_QUIZ_ASSIGNMENTS_FAIL';
export const REPORT_QUIZ_ASSIGNMENTS_RESET = 'REPORT_QUIZ_ASSIGNMENTS_RESET';

export const REPORT_SUPPORT_REQUESTS_START = 'REPORT_SUPPORT_REQUESTS_START';
export const REPORT_SUPPORT_REQUESTS_SUCCESS = 'REPORT_SUPPORT_REQUESTS_SUCCESS';
export const REPORT_SUPPORT_REQUESTS_FAIL = 'REPORT_SUPPORT_REQUESTS_FAIL';
export const REPORT_SUPPORT_REQUESTS_RESET = 'REPORT_SUPPORT_REQUESTS_RESET';

export const REPORT_SURVEY_ASSIGNMENTS_START = 'REPORT_SURVEY_ASSIGNMENTS_START';
export const REPORT_SURVEY_ASSIGNMENTS_SUCCESS = 'REPORT_SURVEY_ASSIGNMENTS_SUCCESS';
export const REPORT_SURVEY_ASSIGNMENTS_FAIL = 'REPORT_SURVEY_ASSIGNMENTS_FAIL';
export const REPORT_SURVEY_ASSIGNMENTS_RESET = 'REPORT_SURVEY_ASSIGNMENTS_RESET';