import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForGetByUserId: null,
 };
const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const getByUserIdStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByUserIdSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByUserId: action.isSucceedForGetByUserId,
    });
};
const getByUserIdFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByUserIdReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByUserId: action.isSucceedForGetByUserId,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_USER_SURVEY_START:
            return addStart(state,action);
        case actionTypes.ADD_USER_SURVEY_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_USER_SURVEY_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_USER_SURVEY_RESET:
            return addReset(state,action);
        case actionTypes.GET_USER_SURVEY_BY_USER_ID_START:
            return getByUserIdStart(state, action);
        case actionTypes.GET_USER_SURVEY_BY_USER_ID_SUCCESS:
            return getByUserIdSuccess(state, action);
        case actionTypes.GET_USER_SURVEY_BY_USER_ID_FAIL:
            return getByUserIdFail(state, action);
        case actionTypes.GET_USER_SURVEY_BY_USER_ID_RESET:
            return getByUserIdReset(state, action);
        default:
            return state;
    };
};

export default reducer;