import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForGetAll: null,
    pushIsSucceed: null,
    isSucceedForReadAll: null,
};
const getAllStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getAllSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getAllFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getAllReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const pushSuccess = (state, action) => {
    return updateObject(state, {
        pushIsSucceed: action.pushIsSucceed,
    });
}
const pushReset = (state, action) => {
    return updateObject(state, {
        pushIsSucceed: action.pushIsSucceed,
    });
}
const readAllStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const readAllSuccess = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForReadAll: action.isSucceedForReadAll,
    });
};
const readAllFail = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const readAllReset = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForReadAll: action.isSucceedForReadAll,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_NOTIFICATIONS_START:
            return getAllStart(state, action);
        case actionTypes.GET_ALL_NOTIFICATIONS_SUCCESS:
            return getAllSuccess(state, action);
        case actionTypes.GET_ALL_NOTIFICATIONS_FAIL:
            return getAllFail(state, action);
        case actionTypes.GET_ALL_NOTIFICATIONS_RESET:
            return getAllReset(state, action);
        case actionTypes.PUSH_NOTIFICATION_SUCCESS:
            return pushSuccess(state, action);
        case actionTypes.PUSH_NOTIFICATION_RESET:
            return pushReset(state, action);
        case actionTypes.READ_ALL_NOTIFICATIONS_START:
            return readAllStart(state, action);
        case actionTypes.READ_ALL_NOTIFICATIONS_SUCCESS:
            return readAllSuccess(state, action);
        case actionTypes.READ_ALL_NOTIFICATIONS_FAIL:
            return readAllFail(state, action);
        case actionTypes.READ_ALL_NOTIFICATIONS_RESET:
            return readAllReset(state, action);
        default:
            return state;
    };
};

export default reducer;