import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForGet: null,
    isSucceedForGetAll: null,
    isSucceedForPut: null,
    isSucceedForComplete: null,
    isSucceedForBreak: null
};
const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const getByUserCodeStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByUserCodeSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getByUserCodeFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByUserCodeReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const putStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const putSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForPut: action.isSucceedForPut,
    });
};
const putFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const putReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForPut: action.isSucceedForPut,
    });
};
const completeStart = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForComplete: action.isSucceedForComplete
    });
};
const completeSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForComplete: action.isSucceedForComplete
    });
};
const completeFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForComplete: action.isSucceedForComplete
    });
};
const completeReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForComplete: action.isSucceedForComplete
    });
};
const assignQuizToDepartmentStart = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAssign: action.isSucceedForAssign
    });
};
const assignQuizToDepartmentSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAssign: action.isSucceedForAssign
    });
};
const assignQuizToDepartmentFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAssign: action.isSucceedForAssign
    });
};
const assignQuizToDepartmentReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAssign: action.isSucceedForAssign
    });
};
const getByUnStartedStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByUnStartedSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getByUnStartedFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByUnStartedReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const breakQuizStart = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForBreak: action.isSucceedForBreak
    });
};
const breakQuizSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForBreak: action.isSucceedForBreak
    });
};
const breakQuizFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForBreak: action.isSucceedForBreak
    });
};
const breakQuizReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForBreak: action.isSucceedForBreak
    });
};
const getByIsHalfStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByIsHalfSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getByIsHalfFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByIsHalfReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const findQuizAssignmentByEducationIdStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const findQuizAssignmentByEducationIdSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const findQuizAssignmentByEducationIdFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const findQuizAssignmentByEducationIdReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_QUIZ_ASSIGNMENT_START:
            return addStart(state,action);
        case actionTypes.ADD_QUIZ_ASSIGNMENT_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_QUIZ_ASSIGNMENT_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_QUIZ_ASSIGNMENT_RESET:
            return addReset(state,action);
        case actionTypes.GET_QUIZ_ASSIGNMENT_BY_USER_ID_START:
            return getByUserCodeStart(state, action);
        case actionTypes.GET_QUIZ_ASSIGNMENT_BY_USER_ID_SUCCESS:
            return getByUserCodeSuccess(state, action);
        case actionTypes.GET_QUIZ_ASSIGNMENT_BY_USER_ID_FAIL:
            return getByUserCodeFail(state, action);
        case actionTypes.GET_QUIZ_ASSIGNMENT_BY_USER_ID_RESET:
            return getByUserCodeReset(state, action);
        case actionTypes.PUT_QUIZ_ASSIGNMENT_START:
            return putStart(state, action);
        case actionTypes.PUT_QUIZ_ASSIGNMENT_SUCCESS:
            return putSuccess(state, action);
        case actionTypes.PUT_QUIZ_ASSIGNMENT_FAIL:
            return putFail(state, action);
        case actionTypes.PUT_QUIZ_ASSIGNMENT_RESET:
            return putReset(state, action);
        case actionTypes.COMPLETE_QUIZ_ASSIGNMENT_START:
            return completeStart(state, action);
        case actionTypes.COMPLETE_QUIZ_ASSIGNMENT_SUCCESS:
            return completeSuccess(state, action);
        case actionTypes.COMPLETE_QUIZ_ASSIGNMENT_FAIL:
            return completeFail(state, action);
        case actionTypes.COMPLETE_QUIZ_ASSIGNMENT_RESET:
            return completeReset(state, action);
        case actionTypes.ASSIGN_QUIZ_TO_DEPARTMENT_START:
            return assignQuizToDepartmentStart(state, action);
        case actionTypes.ASSIGN_QUIZ_TO_DEPARTMENT_SUCCESS:
            return assignQuizToDepartmentSuccess(state, action);
        case actionTypes.ASSIGN_QUIZ_TO_DEPARTMENT_FAIL:
            return assignQuizToDepartmentFail(state, action);
        case actionTypes.ASSIGN_QUIZ_TO_DEPARTMENT_RESET:
            return assignQuizToDepartmentReset(state, action);
        case actionTypes.GET_QUIZ_ASSIGNMENT_BY_UNSTARTED_START:
            return getByUnStartedStart(state, action);
        case actionTypes.GET_QUIZ_ASSIGNMENT_BY_UNSTARTED_SUCCESS:
            return getByUnStartedSuccess(state, action);
        case actionTypes.GET_QUIZ_ASSIGNMENT_BY_UNSTARTED_FAIL:
            return getByUnStartedFail(state, action);
        case actionTypes.GET_QUIZ_ASSIGNMENT_BY_UNSTARTED_RESET:
            return getByUnStartedReset(state, action);
        case actionTypes.BREAK_QUIZ_ASSIGNMENT_START:
            return breakQuizStart(state, action);
        case actionTypes.BREAK_QUIZ_ASSIGNMENT_SUCCESS:
            return breakQuizSuccess(state, action);
        case actionTypes.BREAK_QUIZ_ASSIGNMENT_FAIL:
            return breakQuizFail(state, action);
        case actionTypes.BREAK_QUIZ_ASSIGNMENT_RESET:
            return breakQuizReset(state, action);
        case actionTypes.GET_QUIZ_ASSIGNMENT_BY_IS_HALF_START:
            return getByIsHalfStart(state, action);
        case actionTypes.GET_QUIZ_ASSIGNMENT_BY_IS_HALF_SUCCESS:
            return getByIsHalfSuccess(state, action);
        case actionTypes.GET_QUIZ_ASSIGNMENT_BY_IS_HALF_FAIL:
            return getByIsHalfFail(state, action);
        case actionTypes.GET_QUIZ_ASSIGNMENT_BY_IS_HALF_RESET:
            return getByIsHalfReset(state, action);
        case actionTypes.FIND_QUIZ_ASSIGNMENT_BY_EDUCATION_ID_START:
            return findQuizAssignmentByEducationIdStart(state, action);
        case actionTypes.FIND_QUIZ_ASSIGNMENT_BY_EDUCATION_ID_SUCCESS:
            return findQuizAssignmentByEducationIdSuccess(state, action);
        case actionTypes.FIND_QUIZ_ASSIGNMENT_BY_EDUCATION_ID_FAIL:
            return findQuizAssignmentByEducationIdFail(state, action);
        case actionTypes.FIND_QUIZ_ASSIGNMENT_BY_EDUCATION_ID_RESET:
            return findQuizAssignmentByEducationIdReset(state, action);
        default:
            return state;
    };
};

export default reducer;
