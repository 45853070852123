import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const AppUserAccountProfile = Loadable(lazy(() => import('views/application/users/account-profile/Profile3')));
const BranchOffice = Loadable(lazy(() => import('views/definitions/branchoffice/BranchOffice')));
const Department = Loadable(lazy(() => import('views/definitions/department/Department')));
const DepartmentCard = Loadable(lazy(() => import('views/definitions/departmentcard/DepartmentCard')));
const Job = Loadable(lazy(() => import('views/definitions/job/Job')));
const PermissionType = Loadable(lazy(() => import('views/definitions/permission/PermissionType')));
const AddingPastPermission = Loadable(lazy(() => import('views/definitions/permission/AddingPastPermission')));
const SupportType = Loadable(lazy(() => import('views/definitions/support/SupportType')));
const SurveyType = Loadable(lazy(() => import('views/definitions/survey/SurveyType')));
const Survey = Loadable(lazy(() => import('views/definitions/survey/Survey')));
const SurveyQuestion = Loadable(lazy(() => import('views/definitions/survey/SurveyQuestion')));
const SurveyAnswer = Loadable(lazy(() => import('views/definitions/survey/SurveyAnswer')));
const EducationType = Loadable(lazy(() => import('views/definitions/education/EducationType')));
const Education = Loadable(lazy(() => import('views/definitions/education/Education')));
const UserEducation = Loadable(lazy(() => import('views/definitions/education/UserEducation')));
const DepartmentEducation = Loadable(lazy(() => import('views/definitions/education/DepartmentEducation')));
const Quiz = Loadable(lazy(() => import('views/definitions/quiz/Quiz')));
const QuizQuestion = Loadable(lazy(() => import('views/definitions/quiz/QuizQuestion')));
const QuizAnswer = Loadable(lazy(() => import('views/definitions/quiz/QuizAnswer')));
const QuizAssignment = Loadable(lazy(() => import('views/definitions/quiz/QuizAssignment')));
const QuizAssignmentToDepartment = Loadable(lazy(() => import('views/definitions/quiz/QuizAssignmentToDepartment')));
const NewPersonnel = Loadable(lazy(() => import('views/definitions/personnel/NewPersonnel')));
const Personnels = Loadable(lazy(() => import('views/definitions/personnel/Personnels')));

const CompleteEducation = Loadable(lazy(() => import('views/mypages/myeducation/CompleteEducation')));
const ContinueEducation = Loadable(lazy(() => import('views/mypages/myeducation/ContinueEducation')));
const CompletedEducation = Loadable(lazy(() => import('views/mypages/myeducation/CompletedEducation')));
const MyEducations = Loadable(lazy(() => import('views/mypages/myeducation/MyEducations')));

const MyQuizies = Loadable(lazy(() => import('views/mypages/myquizies/MyQuizies')));
const CompleteQuiz = Loadable(lazy(() => import('views/mypages/myquizies/CompleteQuiz')));
const CompletedQuiz = Loadable(lazy(() => import('views/mypages/myquizies/CompletedQuiz')));
const InCompletedQuiz = Loadable(lazy(() => import('views/mypages/myquizies/InCompletedQuiz')));
const ContinueQuiz = Loadable(lazy(() => import('views/mypages/myquizies/ContinueQuiz')));

const MyPermissions = Loadable(lazy(() => import('views/mypages/mypermissions/MyPermissions')));
const RequestPermission = Loadable(lazy(() => import('views/mypages/mypermissions/RequestPermission')));
const ApprovedPermissions = Loadable(lazy(() => import('views/mypages/mypermissions/ApprovedPermissions')));
const RejectedPermissions = Loadable(lazy(() => import('views/mypages/mypermissions/RejectedPermissions')));
const WaitingPermissions = Loadable(lazy(() => import('views/mypages/mypermissions/WaitingPermissions')));

const MySupportRequests = Loadable(lazy(() => import('views/mypages/mysupport/MySupportRequests')));
const RequestSupport = Loadable(lazy(() => import('views/mypages/mysupport/RequestSupport')));
const WaitingSupportRequests = Loadable(lazy(() => import('views/mypages/mysupport/WaitingSupportRequests')));
const CompletedSupportRequests = Loadable(lazy(() => import('views/mypages/mysupport/CompletedSupportRequests')));
const ContinueSupportRequests = Loadable(lazy(() => import('views/mypages/mysupport/ContinueSupportRequests')));
const RejectedSupportRequests = Loadable(lazy(() => import('views/mypages/mysupport/RejectedSupportRequests')));
const CanceledSupportRequests = Loadable(lazy(() => import('views/mypages/mysupport/CanceledSupportRequests')));

const FillSurvey = Loadable(lazy(() => import('views/mypages/mysurvey/FillSurvey')));
const FilledSuveies = Loadable(lazy(() => import('views/mypages/mysurvey/FilledSurveies')));

const AssignSurveyToUser = Loadable(lazy(() => import('views/definitions/survey/AssignSurveyToUser')));
const AssignSurveyToDepartment = Loadable(lazy(() => import('views/definitions/survey/AssignSurveyToDepartment')));

const Documents = Loadable(lazy(() => import('views/mypages/mydocuments/Documents')));

const ComingSupports = Loadable(lazy(() => import('views/inbox/support/ComingSupports')));
const ComingPermissions = Loadable(lazy(() => import('views/inbox/permission/ComingPermissions')));
const ContinueSupports = Loadable(lazy(() => import('views/inbox/support/ContinueSupports')));
const CompletedSupports = Loadable(lazy(() => import('views/inbox/support/CompletedSupports')));
const RejectedSupports = Loadable(lazy(() => import('views/inbox/support/RejectedSupports')));

const DocumentPermissions = Loadable(lazy(() => import('views/definitions/documentmanagement/DocumentPermissions')));
const AllDocumentPermissions = Loadable(lazy(() => import('views/definitions/documentmanagement/AllDocumentPermissions')));

const Announcement = Loadable(lazy(() => import('views/definitions/announcement/Announcement')));
const Announcements = Loadable(lazy(() => import('views/definitions/announcement/Announcements')));
const AnnouncementList = Loadable(lazy(() => import('views/definitions/announcement/AnnouncementList')));

const PagePermission = Loadable(lazy(() => import('views/definitions/pagepermission/PagePermission')));
// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

const EducationReport = Loadable(lazy(() => import('views/reports/education/EducationReport')));
const QuizReport = Loadable(lazy(() => import('views/reports/quiz/QuizReport')));
const SurveyReport = Loadable(lazy(() => import('views/reports/survey/SurveyReport')));
const SupportReport = Loadable(lazy(() => import('views/reports/support/SupportReport')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'account-profile',
            element: <AppUserAccountProfile />
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'branch-office',
                    element: <BranchOffice />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'department',
                    element: <Department />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'departmentcard',
                    element: <DepartmentCard />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'job',
                    element: <Job />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'permissiontype',
                    element: <PermissionType />
                },
                {
                    path: 'addingPastPermission',
                    element: <AddingPastPermission />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'supporttype',
                    element: <SupportType />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'surveytype',
                    element: <SurveyType />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'survey',
                    element: <Survey />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'surveyquestion',
                    element: <SurveyQuestion />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'surveyanswer',
                    element: <SurveyAnswer />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'assignSurveyToUser',
                    element: <AssignSurveyToUser />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'assignSurveyToDepartment',
                    element: <AssignSurveyToDepartment />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'educationtype',
                    element: <EducationType />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'education',
                    element: <Education />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'usereducation',
                    element: <UserEducation />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'departmenteducation',
                    element: <DepartmentEducation />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'quiz',
                    element: <Quiz />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'quizquestion',
                    element: <QuizQuestion />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'quizanswer',
                    element: <QuizAnswer />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'assignmentquiz',
                    element: <QuizAssignment />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'quizassignmenttodepartment',
                    element: <QuizAssignmentToDepartment />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'newpersonnel',
                    element: <NewPersonnel />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'personnels',
                    element: <Personnels />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'documentpermissions',
                    element: <DocumentPermissions />
                },
                {
                    path: 'alldocumentpermissions',
                    element: <AllDocumentPermissions />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'announcement',
                    element: <Announcement />
                },
                {
                    path: 'announcementList',
                    element: <AnnouncementList />
                },
                {
                    path: 'announcements',
                    element: <Announcements />
                }
            ]
        },
        {
            path: 'definitions',
            children: [
                {
                    path: 'pagepermission',
                    element: <PagePermission />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'completeeducation',
                    element: <CompleteEducation />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'continueeducation',
                    element: <ContinueEducation />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'completededucation',
                    element: <CompletedEducation />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'myeducations',
                    element: <MyEducations />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'myquizies',
                    element: <MyQuizies />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'completequiz',
                    element: <CompleteQuiz />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'completedquiz',
                    element: <CompletedQuiz />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'incompletedquiz',
                    element: <InCompletedQuiz />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'continuequiz',
                    element: <ContinueQuiz />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'requestpermission',
                    element: <RequestPermission />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'mypermissions',
                    element: <MyPermissions />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'approvedpermissions',
                    element: <ApprovedPermissions />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'rejectedpermissions',
                    element: <RejectedPermissions />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'waitingpermissions',
                    element: <WaitingPermissions />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'mysupportrequests',
                    element: <MySupportRequests />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'requestsupport',
                    element: <RequestSupport />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'waitingsupportrequests',
                    element: <WaitingSupportRequests />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'continuesupportrequests',
                    element: <ContinueSupportRequests />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'completedsupportrequests',
                    element: <CompletedSupportRequests />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'rejectedsupportrequests',
                    element: <RejectedSupportRequests />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'canceledsupportrequests',
                    element: <CanceledSupportRequests />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'fillsurvey',
                    element: <FillSurvey />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'filledsurveies',
                    element: <FilledSuveies />
                }
            ]
        },
        {
            path: 'mypages',
            children: [
                {
                    path: 'documents',
                    element: <Documents />
                }
            ]
        },
        {
            path: 'inbox',
            children: [
                {
                    path: 'comingpermissions',
                    element: <ComingPermissions />
                }
            ]
        },
        {
            path: 'inbox',
            children: [
                {
                    path: 'comingsupports',
                    element: <ComingSupports />
                },
                {
                    path: 'continuesupports',
                    element: <ContinueSupports />
                },
                {
                    path: 'completedsupports',
                    element: <CompletedSupports />
                },
                {
                    path: 'rejectedsupports',
                    element: <RejectedSupports />
                }
            ]
        },
        {
            path: 'reports',
            children: [
                {
                    path: 'educationreport',
                    element: <EducationReport />
                },
                {
                    path: 'quizreport',
                    element: <QuizReport />
                },
                {
                    path: 'surveyreport',
                    element: <SurveyReport />
                },
                {
                    path: 'supportreport',
                    element: <SupportReport />
                }
            ]
        }
    ]
};

export default MainRoutes;
