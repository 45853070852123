import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForGetAll: null,
    isSucceedForGet: null,
    isSucceedForPut: null,
    isSucceedForDelete: null,
};
const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const getByQuizIdStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByQuizIdSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getByQuizIdFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByQuizIdReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const putStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const putSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForPut: action.isSucceedForPut,
    });
};
const putFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const putReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForPut: action.isSucceedForPut,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_QUIZ_QUESTION_START:
            return addStart(state,action);
        case actionTypes.ADD_QUIZ_QUESTION_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_QUIZ_QUESTION_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_QUIZ_QUESTION_RESET:
            return addReset(state,action);
        case actionTypes.GET_QUIZ_QUESTION_BY_QUIZ_ID_START:
            return getByQuizIdStart(state, action);
        case actionTypes.GET_QUIZ_QUESTION_BY_QUIZ_ID_SUCCESS:
            return getByQuizIdSuccess(state, action);
        case actionTypes.GET_QUIZ_QUESTION_BY_QUIZ_ID_FAIL:
            return getByQuizIdFail(state, action);
        case actionTypes.GET_QUIZ_QUESTION_BY_QUIZ_ID_RESET:
            return getByQuizIdReset(state, action);
        case actionTypes.PUT_QUIZ_QUESTION_START:
            return putStart(state, action);
        case actionTypes.PUT_QUIZ_QUESTION_SUCCESS:
            return putSuccess(state, action);
        case actionTypes.PUT_QUIZ_QUESTION_FAIL:
            return putFail(state, action);
        case actionTypes.PUT_QUIZ_QUESTION_RESET:
            return putReset(state, action);
        default:
            return state;
    };
};

export default reducer;