//export const APIURL = 'http://localhost:14846';
//export const DOCUMENT_URL = 'http://localhost:14846/Documents';
//export const APIURL = 'https://apinetportal.safahastanesi.net'; //Safa
//export const DOCUMENT_URL = 'https://apinetportal.safahastanesi.net/Documents'; //Safa

//export const APIURL = 'http://devapi.netportal.com.tr'; //Rami Dev
//export const DOCUMENT_URL = 'http://devapi.netportal.com.tr/Documents'; //Rami Dev Documents

export const APIURL = 'http://demoapi.netportal.com.tr'; //Rami Demo
export const DOCUMENT_URL = 'http://demoapi.netportal.com.tr/Documents'; //Rami Demo Documents

//export const APIURL = 'http://destekapi.netportal.com.tr'; //Rami Destek
//export const DOCUMENT_URL = 'http://destekapi.netportal.com.tr/Documents'; //Rami Destek Documents

//export const APIURL = '';

//export const APIURL = 'http://192.168.10.12'; //demo
//export const DOCUMENT_URL = 'http://192.168.10.12/Documents'; //demo

export const LOGIN_API = APIURL + '/auth/login';
export const REGISTER_API = APIURL + '/auth/register';
export const USER_REGISTER_API = APIURL + '/auth/user-register';
export const CONFIRM_CODE_API = APIURL + '/auth/ConfirmPhone';
export const FORGOT_PASSWORD_API = APIURL + '/auth/forgot-password';

export const NOTIFICATION_HUB_URL = APIURL + '/notification';
export const GET_ALL_NOTIFICATIONS_API = APIURL + '/Notification/get-all';
export const READ_ALL_NOTIFICATIONS_API = APIURL + '/Notification/read-all';

export const ADD_BRANCH_OFFICE_API = APIURL + '/BranchOffice/add';
export const GET_BRANCH_OFFICE_API = APIURL + '/BranchOffice/get';
export const GET_ALL_BRANCH_OFFICE_API = APIURL + '/BranchOffice/get-all';
export const DELETE_BRANCH_OFFICE_API = APIURL + '/BranchOffice/delete';
export const PUT_BRANCH_OFFICE_API = APIURL + '/BranchOffice/put';

export const ADD_DEPARTMENT_API = APIURL + '/Department/add';
export const GET_DEPARTMENT_API = APIURL + '/Department/get';
export const GET_ALL_DEPARTMENT_API = APIURL + '/Department/get-all';
export const DELETE_DEPARTMENT_API = APIURL + '/Department/delete';
export const PUT_DEPARTMENT_API = APIURL + '/Department/put';

export const ADD_DEPARTMENT_CARD_API = APIURL + '/DepartmentCard/add';
export const GET_DEPARTMENT_CARD_API = APIURL + '/DepartmentCard/get';
export const GET_ALL_DEPARTMENT_CARD_API = APIURL + '/DepartmentCard/get-all';
export const DELETE_DEPARTMENT_CARD_API = APIURL + '/DepartmentCard/delete';
export const PUT_DEPARTMENT_CARD_API = APIURL + '/DepartmentCard/put';

export const GET_ALL_USER_API = APIURL + '/User/get-all';
export const ACCEPT_USER_API = APIURL + '/User/accept-user';
export const GET_USER_BY_CODE = APIURL + '/User/get-by-id';
export const UPDATE_USER_API = APIURL + '/User/update-user';

export const ADD_JOB_API = APIURL + '/Job/add';
export const GET_JOB_API = APIURL + '/Job/get';
export const GET_ALL_JOB_API = APIURL + '/Job/get-all';
export const DELETE_JOB_API = APIURL + '/Job/delete';
export const PUT_JOB_API = APIURL + '/Job/put';

export const ADD_PERMISSION_TYPE_API = APIURL + '/PermissionType/add';
export const GET_PERMISSION_TYPE_API = APIURL + '/PermissionType/get';
export const GET_ALL_PERMISSION_TYPE_API = APIURL + '/PermissionType/get-all';
export const DELETE_PERMISSION_TYPE_API = APIURL + '/PermissionType/delete';
export const PUT_PERMISSION_TYPE_API = APIURL + '/PermissionType/put';

export const ADD_PERMISSION_API = APIURL + '/Permission/add';
export const CALCULATE_PERMISSION_API = APIURL + '/Permission/calculate-permissions';
export const APPROVE_PERMISSION_API = APIURL + '/Permission/approve';
export const REJECT_PERMISSION_API = APIURL + '/Permission/reject';
export const GET_PERMISSIONS_BY_MANAGER_API = APIURL + '/Permission/get-by-manager';
export const GET_APPROVED_PERMISSIONS_API = APIURL + '/Permission/get-approved-by-user-id'
export const GET_REJECTED_PERMISSIONS_API = APIURL + '/Permission/get-rejected-by-user-id'
export const GET_WAITING_PERMISSIONS_API = APIURL + '/Permission/get-waiting-by-user-id'
export const GET_ALL_PERMISSIONS_API = APIURL + '/Permission/get-by-user-id';

export const ADD_SUPPORT_TYPE_API = APIURL + '/SupportType/add';
export const GET_SUPPORT_TYPE_API = APIURL + '/SupportType/get';
export const GET_ALL_SUPPORT_TYPE_API = APIURL + '/SupportType/get-all';
export const DELETE_SUPPORT_TYPE_API = APIURL + '/SupportType/delete';
export const PUT_SUPPORT_TYPE_API = APIURL + '/SupportType/put';

export const ADD_SUPPORT_API = APIURL + '/Support/add';
export const PUT_SUPPORT_API = APIURL +'/Support/put';
export const DELETE_SUPPORT_API = APIURL + '/Support/delete';
export const GET_SUPPORT_API = APIURL + '/Support/get';
export const GET_SUPPORT_BY_DEPARTMENT_ID_API = APIURL + '/Support/get-by-department-id';
export const GET_ALL_SUPPORT_API = APIURL + '/Support/get-all';
export const UPDATE_SUPPORT_STATUS_API = APIURL + '/Support/update-status';
export const GET_SUPPORTS_BY_DEPARTMENT_ID_API = APIURL + '/Support/get-by-department_id';

export const GET_SUPPORT_HISTORY_API = APIURL + '/SupportDetail/get-support-history';

export const ADD_SURVEY_TYPE_API = APIURL + '/SurveyType/add';
export const GET_SURVEY_TYPE_API = APIURL + '/SurveyType/get';
export const GET_ALL_SURVEY_TYPE_API = APIURL + '/SurveyType/get-all';
export const DELETE_SURVEY_TYPE_API = APIURL + '/SurveyType/delete';
export const PUT_SURVEY_TYPE_API = APIURL + '/SurveyType/put';

export const ADD_SURVEY_API = APIURL + '/Survey/add';
export const GET_SURVEY_API = APIURL + '/Survey/get';
export const GET_ALL_SURVEY_API = APIURL + '/Survey/get-all';
export const GET_ALL_BY_SURVEY_TYPE_API = APIURL + '/Survey/get-by-survey-type-id';
export const DELETE_SURVEY_API = APIURL + '/Survey/delete';
export const PUT_SURVEY_API = APIURL + '/Survey/put';

export const ADD_USER_SURVEY_API = APIURL + '/UserSurvey/add';
export const GET_USER_SURVEY_BY_USER_ID_API = APIURL + '/UserSurvey/get-by-user-id';

export const ADD_SURVEY_QUESTION_API = APIURL + '/SurveyQuestion/add';
export const GET_SURVEY_QUESTION_API = APIURL + '/SurveyQuestion/get-by-id';
export const GET_SURVEY_QUESTION_BY_SURVEY_ID_API = APIURL + '/SurveyQuestion/get-by-survey-id';
export const GET_ALL_SURVEY_QUESTION_API = APIURL + '/SurveyQuestion/get-all';
export const DELETE_SURVEY_QUESTION_API = APIURL + '/SurveyQuestion/delete';
export const PUT_SURVEY_QUESTION_API = APIURL + '/SurveyQuestion/put';

export const ADD_SURVEY_ANSWER_API = APIURL + '/SurveyAnswer/add';
export const GET_SURVEY_ANSWER_API = APIURL + '/SurveyAnswer/get-by-id';
export const GET_SURVEY_ANSWER_BY_QUESTION_ID_API = APIURL + '/SurveyAnswer/get-by-question-id';
export const GET_SURVEY_ANSWER_BY_SURVEY_ID_API = APIURL + '/SurveyAnswer/get-by-survey-id';
export const GET_ALL_SURVEY_ANSWER_API = APIURL + '/SurveyAnswer/get-all';
export const DELETE_SURVEY_ANSWER_API = APIURL + '/SurveyAnswer/delete';
export const PUT_SURVEY_ANSWER_API = APIURL + '/SurveyAnswer/put';

export const ADD_EDUCATION_TYPE_API = APIURL + '/EducationType/add';
export const GET_EDUCATION_TYPE_API = APIURL + '/EducationType/get';
export const GET_ALL_EDUCATION_TYPE_API = APIURL + '/EducationType/get-all';
export const DELETE_EDUCATION_TYPE_API = APIURL + '/EducationType/delete';
export const PUT_EDUCATION_TYPE_API = APIURL + '/EducationType/put';

export const ADD_EDUCATION_API = APIURL + '/Education/add';
export const GET_EDUCATION_API = APIURL + '/Education/get';
export const GET_ALL_EDUCATION_API = APIURL + '/Education/get-all';
export const PUT_EDUCATION_API = APIURL + '/Education/put';
export const DELETE_EDUCATION_API = APIURL + '/Education/delete';

export const ADD_USER_EDUCATION_API = APIURL + '/UserEducation/add';
export const GET_USER_EDUCATION_API = APIURL + '/UserEducation/get';
export const GET_USER_EDUCATION_BY_EDUCATION_ID_API = APIURL + '/UserEducation/get-by-education-id';
export const GET_USER_EDUCATION_BY_USER_ID_API = APIURL + '/UserEducation/get-by-user-id';
export const PUT_USER_EDUCATION_API = APIURL + '/UserEducation/put';
export const DELETE_USER_EDUCATION_API = APIURL + '/UserEducation/delete';
export const COMPLETE_USER_EDUCATION_API = APIURL + '/UserEducation/complete';
export const GET_INCOMPLETE_USER_EDUCATION_API = APIURL + '/UserEducation/get-incomplete-educations';
export const UPDATE_HALF_USER_EDUCATION_API = APIURL + '/UserEducation/update-half-education';
export const GET_HALF_USER_EDUCATIONS_API = APIURL + '/UserEducation/get-half-educations';
export const GET_COMPLETED_EDUCATIONS_API = APIURL + '/UserEducation/get-completed-educations';
export const ASSIGN_EDUCATION_TO_ALL_USERS_API = APIURL + '/UserEducation/assign-education-to-all-users';

export const ADD_DEPARTMENT_EDUCATION_API = APIURL + '/DepartmentEducation/add';
export const GET_DEPARTMENT_EDUCATION_BY_DEPARTMENT_ID_API = APIURL + '/DepartmentEducation/get-by-department-id';
export const PUT_DEPARTMENT_EDUCATION_API = APIURL + "/DepartmentEducation/put";

export const ADD_QUIZ_API = APIURL + '/Quiz/add';
export const GET_QUIZ_BY_ID_API = APIURL + '/Quiz/get-by-id';
export const DELETE_QUIZ_API = APIURL + '/Quiz/delete';
export const PUT_QUIZ_API = APIURL + '/Quiz/put';
export const GET_QUIZ_BY_EDUCATION_ID_API = APIURL + '/Quiz/get-by-education-id';
export const GET_ALL_QUIZ_API = APIURL + '/Quiz/get-all';

export const ADD_QUIZ_QUESTION_API = APIURL + '/QuizQuestion/add';
export const GET_QUIZ_QUESTION_BY_ID_API = APIURL + '/QuizQuestion/get-by-id';
export const GET_QUIZ_QUESTION_BY_QUIZ_ID_API = APIURL + '/QuizQuestion/get-by-quiz-id';
export const PUT_QUIZ_QUESTION_API = APIURL + '/QuizQuestion/put';
export const DELETE_QUIZ_QUESTION_API = APIURL + '/QuizQuestion/delete';

export const ADD_QUIZ_ANSWER_API = APIURL + '/QuizAnswer/add';
export const GET_QUIZ_ANSWER_BY_QUESTION_ID_API = APIURL + '/QuizAnswer/get-by-question-id';
export const GET_QUIZ_ANSWER_BY_EDUCATION_ID_API = APIURL + '/QuizAnswer/get-by-education-id';
export const GET_QUIZ_ANSWER_BY_QUIZ_ID_API = APIURL + '/QuizAnswer/get-answers-by-quiz-id';
export const GET_QUIZ_ANSWER_BY_QUIZ_ASSIGNMENT_ID_API = APIURL + '/QuizAnswer/get-answers-by-quiz-assignment-id';
export const PUT_QUIZ_ANSWER_API = APIURL + '/QuizAnswer/put';
export const DELETE_QUIZ_ANSWER_API = APIURL + '/QuizAnswer/delete';

export const ADD_QUIZ_ASSIGNMENT_API = APIURL + "/QuizAssignment/add";
export const GET_QUIZ_ASSIGNMENT_BY_USER_ID_API = APIURL + "/QuizAssignment/get-list-by-user-id";
export const PUT_QUIZ_ASSIGNMENT_API = APIURL + "/QuizAssignment/put";
export const COMPLETE_QUIZ_ASSIGNMENT_API = APIURL + "/QuizAssignment/complete";
export const BREAK_QUIZ_ASSIGNMENT_API = APIURL + "/QuizAssignment/break-quiz";
export const ASSIGN_QUIZ_TO_DEPARTMENT_API = APIURL + "/QuizAssignment/assign-quiz-to-department";
export const GET_QUIZ_ASSIGNMENT_BY_UNSTARTED_API = APIURL + "/QuizAssignment/get-list-by-is-unstarted";
export const GET_QUIZ_ASSIGNMENT_BY_IS_HALF_API = APIURL + "/QuizAssignment/get-list-by-is-half";
export const FIND_QUIZ_ASSIGNMENT_ID_BY_EDUCATION_ID_API = APIURL + "/QuizAssignment/find-quiz-assignment-by-education-Id";

export const CHANGE_PASSWORD_API = APIURL + "/Profile/change-password";
export const UPDATE_PROFILE_API = APIURL + "/Profile/update-profile";
export const UPDATE_PROFILE_PHOTO_API = APIURL + "/Profile/update-profile-photo";

export const GIVE_ANSWER_API = APIURL + "/UserQuizDetail/give-answer";

export const GET_DEPARTMENT_QUIZ_BY_DEPARTMENT_ID_API = APIURL + "/DepartmentQuiz/get-by-department_id";

export const ADD_SETTING_API = APIURL + "/Settings/add";
export const GET_SETTING_API = APIURL + "/Settings/get-by-id";
export const GET_ALL_SETTING_API = APIURL + "/Settings/get-all";
export const PUT_SETTING_API = APIURL + "/Settings/update";
export const GET_SETTING_BY_KEY_API = APIURL + "/Settings/get-by-key";

export const ADD_DOCUMENT_API = APIURL + "/Document/add";
export const GET_DOCUMENT_API = APIURL + "/Document/get";

export const ADD_DOCUMENT_PERMISSION_API = APIURL + "/DocumentPermission/add";
export const UPDATE_DOCUMENT_PERMISSION_API = APIURL + "/DocumentPermission/update";
export const DELETE_DOCUMENT_PERMISSION_API = APIURL + "/DocumentPermission/delete";
export const GET_USER_DOCUMENT_PERMISSIONS_API = APIURL + "/DocumentPermission/get-user-document-permissions";
export const GET_ALL_DOCUMENT_PERMISSIONS_API = APIURL + "/DocumentPermission/get-all";
export const GET_USER_DOCUMENT_PERMISSION_API = APIURL + "/DocumentPermission/get-user-document-permission";

export const GET_WEATHER_API = APIURL + "/Dashboard/get-weather";
export const GET_FOREIGN_CURRENCIES_API = APIURL + "/Dashboard/get-foreign-currencies";
export const GET_GENERAL_INFO_API = APIURL + "/Dashboard/get-general-info";

export const ADD_ANNOUNCEMENT_API = APIURL + "/Announcement/add";
export const UPDATE_ANNOUNCEMENT_API = APIURL + "/Announcement/update";
export const DELETE_ANNOUNCEMENT_API = APIURL + "/Announcement/delete";
export const GET_ANNOUNCEMENT_API = APIURL + "/Announcement/get-announcement";
export const GET_ANNOUNCEMENTS_API = APIURL + "/Announcement/get-announcements";
export const GET_ANNOUNCEMENTS_BY_USER_API = APIURL + "/Announcement/get-announcements-by-user";
export const UPDATE_ANNOUNCEMENT_STATUS_API = APIURL + "/Announcement/update-announcement-status";

export const ADD_DEPARTMENT_SURVEY_API = APIURL + "/DepartmentSurvey/add-department-survey";
export const GET_DEPARTMENT_SURVEY_API = APIURL + "/DepartmentSurvey/get-department-survey";

export const ADD_USER_SURVEY_ASSIGNMENT_API = APIURL + "/UserSurveyAssignment/add-user-survey-assignment";
export const UPDATE_USER_SURVEY_ASSIGNMENT_STATUS_API = APIURL + "/UserSurveyAssignment/update-user-survey-assignment-status";
export const GET_USER_SURVEY_ASSIGNMENT_BY_USER_API = APIURL + "/UserSurveyAssignment/get-by-user";
export const GET_USER_SURVEY_ASSIGNMENT_BY_USER_AND_BY_STATUS_API = APIURL + "/UserSurveyAssignment/get-by-user-and-by-status";

export const ADD_DOCUMENT_DRIVE_API = APIURL + "/DocumentDrive/add-document-drive";
export const GET_DOCUMENT_DRIVE_BY_CODE_API = APIURL + "/DocumentDrive/get-by-document-code";
export const GET_DOCUMENTS_DRIVE_BY_SUB_CODE_API = APIURL + "/DocumentDrive/get-by-sub-code";
export const GET_ROOT_DOCUMENTS_API = APIURL + "/DocumentDrive/get-root-documents";
export const GET_UP_DOCUMENTS_API = APIURL + "/DocumentDrive/get-up-documents";
export const DELETE_DOCUMENT_API = APIURL + "/DocumentDrive/delete-document";

export const GIVE_PROJECT_PAGE_PERMISSION_TO_USER_API = APIURL + "/ProjectPagePermission/give-permission-to-user";
export const GIVE_PROJECT_PAGE_PERMISSION_TO_DEPARTMENT_API = APIURL + "/ProjectPagePermission/give-permission-to-department";
export const GET_PROJECT_PERMISSION_BY_USER_API = APIURL + "/ProjectPagePermission/get-page-permissions-by-user";
export const GIVE_PROJECT_PAGE_PERMISSION_TO_ALL_USERS_API = APIURL + "/ProjectPagePermission/give-permission-to-all-users";

export const GET_ALL_PROJECT_PAGES_API = APIURL + "/ProjectPage/get-all";
export const GET_PROJECT_PAGES_BY_SUB_CODE_API = APIURL + "/ProjectPage/get-list-by-sub-id";

export const REPORT_EDUCATION_ASSIGNMENTS_API = APIURL + "/Report/get-education-assignments";
export const REPORT_QUIZ_ASSIGNMENTS_API = APIURL + "/Report/get-quiz-assignments";
export const REPORT_SUPPORT_REQUESTS_API = APIURL + "/Report/get-support-requests";
export const REPORT_SURVEY_ASSIGNMENTS_API = APIURL + "/Report/get-user-survey-assignments";