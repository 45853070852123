import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForGetByDepartmentCode: null,
    isSucceedForPut: null,
};
const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const getByDepartmentCodeStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByDepartmentCodeSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByDepartmentCode: action.isSucceedForGetByDepartmentCode,
    });
};
const getByDepartmentCodeFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByDepartmentCodeReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByDepartmentCode: action.isSucceedForGetByDepartmentCode,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_DEPARTMENT_EDUCATION_START:
            return addStart(state,action);
        case actionTypes.ADD_DEPARTMENT_EDUCATION_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_DEPARTMENT_EDUCATION_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_DEPARTMENT_EDUCATION_RESET:
            return addReset(state,action);
        case actionTypes.GET_DEPARTMENT_EDUCATION_BY_DEPARTMENT_ID_START:
            return getByDepartmentCodeStart(state, action);
        case actionTypes.GET_DEPARTMENT_EDUCATION_BY_DEPARTMENT_ID_SUCCESS:
            return getByDepartmentCodeSuccess(state, action);
        case actionTypes.GET_DEPARTMENT_EDUCATION_BY_DEPARTMENT_ID_FAIL:
            return getByDepartmentCodeFail(state, action);
        case actionTypes.GET_DEPARTMENT_EDUCATION_BY_DEPARTMENT_ID_RESET:
            return getByDepartmentCodeReset(state, action);
        default:
            return state;
    };
};
export default reducer;