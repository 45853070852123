import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    payloadForForeignCurrencies: null,
    errors: null,
    isLoading: false,
    isSucceedForGetWeather: null,
    isSucceedForGetForeignCurrencies: null,
    isSucceedForGetGeneralInfo: null,
    payloadForGeneralInfo: null,
};
const getWeatherStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getWeatherSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetWeather: action.isSucceedForGetWeather,
    });
};
const getWeatherFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getWeatherReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetWeather: action.isSucceedForGetWeather,
    });
};
const getForeignCurrenciesStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getForeignCurrenciesSuccess = (state, action) => {
    return updateObject(state, {
        payloadForForeignCurrencies: action.payloadForForeignCurrencies,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetForeignCurrencies: action.isSucceedForGetForeignCurrencies,
    });
};
const getForeignCurrenciesFail = (state, action) => {
    return updateObject(state, {
        payloadForForeignCurrencies: action.payloadForForeignCurrencies,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getForeignCurrenciesReset = (state, action) => {
    return updateObject(state, {
        payloadForForeignCurrencies: action.payloadForForeignCurrencies,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetForeignCurrencies: action.isSucceedForGetForeignCurrencies,
    });
};
const getGeneralInfoStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getGeneralInfoSuccess = (state, action) => {
    return updateObject(state, {
        payloadForGeneralInfo: action.payloadForGeneralInfo,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetGeneralInfo: action.isSucceedForGetGeneralInfo,
    });
};
const getGeneralInfoFail = (state, action) => {
    return updateObject(state, {
        payloadForGeneralInfo: action.payloadForGeneralInfo,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getGeneralInfoReset = (state, action) => {
    return updateObject(state, {
        payloadForGeneralInfo: action.payloadForGeneralInfo,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetGeneralInfo: action.isSucceedForGetGeneralInfo,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_WEATHER_START:
            return getWeatherStart(state, action);
        case actionTypes.GET_WEATHER_SUCCESS:
            return getWeatherSuccess(state, action);
        case actionTypes.GET_WEATHER_FAIL:
            return getWeatherFail(state, action);
        case actionTypes.GET_WEATHER_RESET:
            return getWeatherReset(state, action);
        case actionTypes.GET_FOREIGN_CURRENCIES_START:
            return getForeignCurrenciesStart(state, action);
        case actionTypes.GET_FOREIGN_CURRENCIES_SUCCESS:
            return getForeignCurrenciesSuccess(state, action);
        case actionTypes.GET_FOREIGN_CURRENCIES_FAIL:
            return getForeignCurrenciesFail(state, action);
        case actionTypes.GET_FOREIGN_CURRENCIES_RESET:
            return getForeignCurrenciesReset(state, action);
        case actionTypes.GET_GENERAL_INFO_START:
            return getGeneralInfoStart(state, action);
        case actionTypes.GET_GENERAL_INFO_SUCCESS:
            return getGeneralInfoSuccess(state, action);
        case actionTypes.GET_GENERAL_INFO_FAIL:
            return getGeneralInfoFail(state, action);
        case actionTypes.GET_GENERAL_INFO_RESET:
            return getGeneralInfoReset(state, action);
        default:
            return state;
    };
};

export default reducer;