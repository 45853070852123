import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForGetAll: null,
    isSucceedForGet: null,
    isSucceedForPut: null,
    isSucceedForDelete: null,
    isSucceed: null,
    isSucceedForGetByCode: null,
    isSucceedUpdate: null,
};
const getAllStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getAllSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getAllFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getAllReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const acceptUserStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const acceptUserSuccess = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const acceptUserFail = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const acceptUserReset = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};

const getUserByCodeStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getUserByCodeSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByCode: action.isSucceedForGetByCode,
    });
};
const getUserByCodeFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getUserByCodeReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByCode: action.isSucceedForGetByCode,
    });
};
const updateUserStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const updateUserSuccess = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedUpdate: action.isSucceedUpdate,
    });
};
const updateUserFail = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedUpdate: action.isSucceedUpdate,
    });
};
const updateUserReset = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedUpdate: action.isSucceedUpdate,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_USER_START:
            return getAllStart(state, action);
        case actionTypes.GET_ALL_USER_SUCCESS:
            return getAllSuccess(state, action);
        case actionTypes.GET_ALL_USER_FAIL:
            return getAllFail(state, action);
        case actionTypes.GET_ALL_USER_RESET:
            return getAllReset(state, action);
        case actionTypes.ACCEPT_USER_START:
            return acceptUserStart(state, action);
        case actionTypes.ACCEPT_USER_SUCCESS:
            return acceptUserSuccess(state, action);
        case actionTypes.ACCEPT_USER_FAIL:
            return acceptUserFail(state, action);
        case actionTypes.ACCEPT_USER_RESET:
            return acceptUserReset(state, action);
        case actionTypes.GET_USER_BY_CODE_START: 
            return getUserByCodeStart(state, action);
        case actionTypes.GET_USER_BY_CODE_SUCCESS: 
            return getUserByCodeSuccess(state, action);
        case actionTypes.GET_USER_BY_CODE_FAIL:
            return getUserByCodeFail(state, action);
        case actionTypes.GET_USER_BY_CODE_RESET:
            return getUserByCodeReset(state, action);
        case actionTypes.UPDATE_USER_START:
            return updateUserStart(state, action);
        case actionTypes.UPDATE_USER_SUCCESS:
            return updateUserSuccess(state, action);
        case actionTypes.UPDATE_USER_FAIL:
            return updateUserFail(state, action);
        case actionTypes.UPDATE_USER_RESET:
            return updateUserReset(state, action);
        default:
            return state;
    };
};

export default reducer;