import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForGetByDepartmentCode: null,
};
const getByDepartmentCodeStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getByDepartmentCodeSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByDepartmentCode: action.isSucceedForGetByDepartmentCode,
    });
};
const getByDepartmentCodeFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getByDepartmentCodeReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetByDepartmentCode: action.isSucceedForGetByDepartmentCode,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DEPARTMENT_QUIZ_BY_DEPARTMENT_ID_START:
            return getByDepartmentCodeStart(state, action);
        case actionTypes.GET_DEPARTMENT_QUIZ_BY_DEPARTMENT_ID_SUCCESS:
            return getByDepartmentCodeSuccess(state, action);
        case actionTypes.GET_DEPARTMENT_QUIZ_BY_DEPARTMENT_ID_FAIL:
            return getByDepartmentCodeFail(state, action);
        case actionTypes.GET_DEPARTMENT_QUIZ_BY_DEPARTMENT_ID_RESET:
            return getByDepartmentCodeReset(state, action);
        default:
            return state;
    };
};
export default reducer;