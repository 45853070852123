import linq from 'linq';
// assets
import { 
    IconBuilding, 
    IconLetterD, 
    IconBooks, 
    IconChefHat, 
    IconHeartHandshake, 
    IconCheckbox, 
    IconCheckupList, 
    IconQuestionMark,
    IconCertificate,
    IconUsers,
    IconConfetti } from '@tabler/icons';

// constant
const icons = {
    IconBuilding,
    IconLetterD,
    IconBooks,
    IconChefHat,
    IconHeartHandshake,
    IconCheckbox,
    IconCheckupList,
    IconQuestionMark,
    IconCertificate,
    IconUsers,
    IconConfetti,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

let inbox = {
    id: 'inbox',
    title: 'Gelen Kutusu',
    type: 'group',
    children: [
        {
            id: 'inboxpermissions',
            title: 'İzin Talepleri',
            type: 'collapse',
            icon: icons.IconConfetti,
            children: [
                {
                    id: 'comingpermissions',
                    title: 'Talep Karşıla',
                    type: 'item',
                    url: '/inbox/comingpermissions',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'inboxsupports',
            title: 'Destek Talepleri',
            type: 'collapse',
            icon: icons.IconHeartHandshake,
            children: [
                {
                    id: 'comingsupports',
                    title: 'Talep Karşıla',
                    type: 'item',
                    url: '/inbox/comingsupports',
                    breadcrumbs: true
                },
                {
                    id: 'continuesupports',
                    title: 'Çözümleniyor',
                    type: 'item',
                    url: '/inbox/continuesupports',
                    breadcrumbs: true
                },
                {
                    id: 'completedsupports',
                    title: 'Tamamlananlar',
                    type: 'item',
                    url: '/inbox/completedsupports',
                    breadcrumbs: true
                },
                {
                    id: 'rejectedsupports',
                    title: 'Reddedilenler',
                    type: 'item',
                    url: '/inbox/rejectedsupports',
                    breadcrumbs: true
                }
            ]
        }
    ]
};

let pagePermissions = [];
pagePermissions = linq.from(JSON.parse(localStorage.getItem('@pagePermissions'))).toArray();

if (pagePermissions.length > 0) {
    let temp = pagePermissions.find((item) => item.title === 'Gelen Kutusu'); 
    let mapForIcons = []
    if (temp !== undefined) {
        mapForIcons = temp.children.map(item => ({
            ...item,
            icon: item.icon === "icons.IconBuilding" && icons.IconBuilding || item.icon === "icons.IconLetterD" && icons.IconLetterD ||
            item.icon === "icons.IconBooks" && icons.IconBooks || item.icon === "icons.IconChefHat" && icons.IconChefHat ||     
            item.icon === "icons.IconHeartHandshake" && icons.IconHeartHandshake ||  item.icon === "icons.IconCheckbox" && icons.IconCheckbox ||
            item.icon ==="icons.IconCheckupList" && icons.IconCheckupList ||  item.icon ==="icons.IconQuestionMark" && icons.IconQuestionMark || item.icon === "icons.IconCertificate" && icons.IconCertificate ||
            item.icon ==="icons.IconUsers" && icons.IconUsers || item.icon === "icons.IconConfetti" && icons.IconConfetti || item.icon === "icons.IconBriefcase" && icons.IconBriefcase      
       }));   
       
        temp.children = mapForIcons;
        inbox = temp;
    }else {
        inbox = {};
    }    
}else{
    inbox = {};
}

export default inbox;
