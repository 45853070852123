import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForGet: null,
};
const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const getStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_DOCUMENT_START:
            return addStart(state,action);
        case actionTypes.ADD_DOCUMENT_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_DOCUMENT_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_DOCUMENT_RESET:
            return addReset(state,action);
        case actionTypes.GET_DOCUMENT_START:
            return getStart(state, action);
        case actionTypes.GET_DOCUMENT_SUCCESS:
            return getSuccess(state, action);
        case actionTypes.GET_DOCUMENT_FAIL:
            return getFail(state, action);
        case actionTypes.GET_DOCUMENT_RESET:
            return getReset(state, action);
        default:
            return state;
    };
};

export default reducer;