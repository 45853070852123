import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForAdd: null,
    isSucceedForGetAll: null,
    isSucceedForGet: null,
    isSucceedForPut: null,
    isSucceedForDelete: null,
};
const addStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const addSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const addFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const addReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForAdd: action.isSucceedForAdd,
    });
};
const getStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGet: action.isSucceedForGet,
    });
};
const getAllStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getAllSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getAllFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getAllReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const putStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const putSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForPut: action.isSucceedForPut,
    });
};
const putFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const putReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForPut: action.isSucceedForPut,
    });
};
const deleteStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const deleteSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForDelete: action.isSucceedForDelete,
    });
};
const deleteFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const deleteReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForDelete: action.isSucceedForDelete,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_SURVEY_QUESTION_START:
            return addStart(state,action);
        case actionTypes.ADD_SURVEY_QUESTION_SUCCESS:
            return addSuccess(state,action);
        case actionTypes.ADD_SURVEY_QUESTION_FAIL:
            return addFail(state,action);
        case actionTypes.ADD_SURVEY_QUESTION_RESET:
            return addReset(state,action);
        case actionTypes.GET_SURVEY_QUESTION_START:
            return getStart(state, action);
        case actionTypes.GET_SURVEY_QUESTION_SUCCESS:
            return getSuccess(state, action);
        case actionTypes.GET_SURVEY_QUESTION_FAIL:
            return getFail(state, action);
        case actionTypes.GET_SURVEY_QUESTION_RESET:
            return getReset(state, action);
        case actionTypes.GET_ALL_SURVEY_QUESTION_START:
            return getAllStart(state, action);
        case actionTypes.GET_ALL_SURVEY_QUESTION_SUCCESS:
            return getAllSuccess(state, action);
        case actionTypes.GET_ALL_SURVEY_QUESTION_FAIL:
            return getAllFail(state, action);
        case actionTypes.GET_ALL_SURVEY_QUESTION_RESET:
            return getAllReset(state, action);
        case actionTypes.GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_START:
                return getAllStart(state, action);
        case actionTypes.GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_SUCCESS:
                return getAllSuccess(state, action);
        case actionTypes.GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_FAIL:
                return getAllFail(state, action);
        case actionTypes.GET_ALL_SURVEY_QUESTION_BY_SURVEY_ID_RESET:
                return getAllReset(state, action);
        case actionTypes.PUT_SURVEY_QUESTION_START:
            return putStart(state, action);
        case actionTypes.PUT_SURVEY_QUESTION_SUCCESS:
            return putSuccess(state, action);
        case actionTypes.PUT_SURVEY_QUESTION_FAIL:
            return putFail(state, action);
        case actionTypes.PUT_SURVEY_QUESTION_RESET:
            return putReset(state, action);
        case actionTypes.DELETE_SURVEY_QUESTION_START:
            return deleteStart(state, action);
        case actionTypes.DELETE_SURVEY_QUESTION_SUCCESS:
            return deleteSuccess(state, action);
        case actionTypes.DELETE_SURVEY_QUESTION_FAIL:
            return deleteFail(state, action);
        case actionTypes.DELETE_SURVEY_QUESTION_RESET:
            return deleteReset(state, action);
        default:
            return state;
    };
};

export default reducer;