import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForGiveAnswer: null,  
};
const giveAnswerStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const giveAnswerSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGiveAnswer: action.isSucceedForGiveAnswer,
    });
};
const giveAnswerFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const giveAnswerReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGiveAnswer: action.isSucceedForGiveAnswer,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GIVE_ANSWER_USER_QUIZ_DETAIL_START:
            return giveAnswerStart(state,action);
        case actionTypes.GIVE_ANSWER_USER_QUIZ_DETAIL_SUCCESS:
            return giveAnswerSuccess(state,action);
        case actionTypes.GIVE_ANSWER_USER_QUIZ_DETAIL_FAIL:
            return giveAnswerFail(state,action);
        case actionTypes.GIVE_ANSWER_USER_QUIZ_DETAIL_RESET:
            return giveAnswerReset(state,action);
        default:
            return state;
    };
};

export default reducer;