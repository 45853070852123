import axios from 'axios';
import * as actionTypes from '../types';
import {LOGIN_API} from '../../_config/env';

export const login = (userName,password) => {
  return (dispatch) => {
    dispatch(loginStart());
    axios
      .post(LOGIN_API, {
        email: userName,
        password: password,
      })
      .then((response) => {
        dispatch(loginSuccess(response));
      })
      // .catch(function (error) {
      //   if (error.response) {
      //     // The request was made and the server responded with a status code
      //     // that falls out of the range of 2xx
      //     if (error.response.data === null) {
      //       dispatch(loginFail(error.response.data.errors));
      //     } else {
      //       // if (error.response.data.errors.Password !== undefined) {
      //       //   dispatch(loginFail(error.response.data.data.errors.Password[0]));  
      //       // }
      //       // if (error.response.data.errors.PhoneNumber !== undefined) {
      //       //   dispatch(loginFail(error.response.data.data.errors.PhoneNumber[0]));  
      //       // }
      //       if (error.response.data !== undefined) {
      //         console.log('error.response.data:',error.response.data);
      //         dispatch(loginFail(error.response.data));  
      //       }
      //     }
      //   } 
      // });
      .catch(function (error) {
        dispatch(loginFail(error));
      });
  };
};
export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START,
    isAuthenticated: null,
    isLoading: true,
  };
};
export const loginSuccess = (data) => {
  if (data.data.data !== null) {
    localStorage.setItem('@token', data.data.data.token);
    localStorage.setItem('@refreshToken', data.data.data.refreshToken);
    localStorage.setItem('@expiration', data.data.data.expiration);
    localStorage.setItem('@firstName', data.data.data.firstName);
    localStorage.setItem('@lastName', data.data.data.lastName);
    localStorage.setItem('@personName', data.data.data.personName);
    localStorage.setItem('@userName', data.data.data.userName);
    localStorage.setItem('@userId', data.data.data.userId);
    localStorage.setItem('@school', data.data.data.school);
    localStorage.setItem('@gender', data.data.data.gender);
    localStorage.setItem('@birthDate', data.data.data.birthDate);
    localStorage.setItem('@profilePhoto', data.data.data.profilePhoto);
    localStorage.setItem('@birthDate', data.data.data.birthDate);
    localStorage.setItem('@email', data.data.data.email);
    localStorage.setItem('@userName', data.data.data.userName);
    localStorage.setItem('@phoneNumber', data.data.data.phoneNumber);
    localStorage.setItem('@bloodGroup', data.data.data.bloodGroup);
    localStorage.setItem('@emergencyPerson', data.data.data.emergencyPerson);
    localStorage.setItem('@emergencyPersonContectInfo', data.data.data.emergencyPersonContactInfo);
    localStorage.setItem('@city', data.data.data.city);
    localStorage.setItem('@district', data.data.data.district);
    localStorage.setItem('@neighbourhood', data.data.data.neighbourhood);
    localStorage.setItem('@street', data.data.data.street);
    localStorage.setItem('@managerId', data.data.data.managerId);
    localStorage.setItem('@departmentId', data.data.data.departmentId);
    localStorage.setItem('@pagePermissions', JSON.stringify(data.data.data.pagePermissions));  
  }
  
  return {
    type: actionTypes.LOGIN_SUCCESS,
    token: data.data.data !== null ? data.data.data.token : null,
    expiration: data.data.data !== null ? data.data.data.expiration : null,
    refreshToken: data.data.data !== null ? data.data.data.refreshToken : null,
    userId: data.data.data !== null ? data.data.data.userId : null,
    userName: data.data.data !== null ? data.data.data.userName : null,
    errors: data.data.errors[0],
    isAuthenticated: data.data.hasError === false ? true : false,
    isLoading: false,
  };
};
export const loginFail = (errorMessage) => {
  return {
    type: actionTypes.LOGIN_FAIL,
    errors: errorMessage,
    isAuthenticated: null,
    isLoading: false,
  };
};
export const loginReset = () => {
  return {
    type: actionTypes.LOGIN_RESET,
    isAuthenticated: null,
    isLoading: false,
    errors: null
  };
};
export const logOut = () => {
  return {
    type: actionTypes.LOGOUT,
    token: null,
    expiration: null,
    refreshToken: null,
    userId: null,
    userName: null,
    errors: null,
    isAuthenticated: null,
    isLoading: false,
  };
};
