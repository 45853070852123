import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

export const setAuthorizationToken = (token) => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = `*`;
        axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
        axios.defaults.headers.common["Access-Control-Allow-Methods"] = `GET, PUT, POST, DELETE, OPTIONS`;
        axios.defaults.maxBodyLength = Infinity;
        axios.defaults.maxContentLength = Infinity;
        
        axios.interceptors.response.use(
            (response) => {
                if (response.data.hasError) {
                    toast.error(response.data.errors[0], {position: "top-center", closeOnClick: true, autoClose:3000, theme:"colored"});
                }
                return response;
            },
            (error) => {
                if (error.response) {
                    if (error.response.status === 404) {
                        toast.error("Sayfa bulunamadı!", {position:"top-center", closeOnClick: true, autoClose:3000, theme:"colored"})
                    }
                    if (error.response.status === 500) {
                        if (error.response.message === null) {
                            toast.error("Ağ bağlantınız ile ilgili sorun!", {position:"top-center", closeOnClick: true, autoClose:3000, theme:"colored"})
                        }else {
                            toast.error(error.response.Message.split("\r\n")[0], {position:"top-center", closeOnClick: true, autoClose:3000, theme:"colored"})
                        }
                    }
                    if (!error.response) {
                        toast.error(`Error 500: Network Error 2`, {position:"top-center", closeOnClick: true, autoClose:3000, theme:"colored"})
                    }
                    if (error.response.status === 401) {
                        localStorage.removeItem('@token')
                        toast.error(`Oturumunuz kapanmıştır!`, {position:"top-center", closeOnClick: true, autoClose:3000, theme:"colored"})
                    }
                    if (error.response.status === 400) {
                        toast.error(`Alanları eksiksiz doldurunuz!`, {position:"top-center", closeOnClick: true, autoClose:3000, theme:"colored"})
                    }
                    if (error.response.status === 403) {
                        toast.error(`Yetkiniz bu işlemi kapsamıyor!`, {position:"top-center", closeOnClick: true, autoClose:3000, theme:"colored"})
                    }
                } else if(error.request){
                    toast.error(`Error 503: Server not responding!`, {position:"top-center", closeOnClick: true, autoClose:3000, theme:"colored"})
                } else {
                    toast.error(`Sistem yöneticiniz ile iletişime geçiniz!`, {position:"top-center", closeOnClick: true, autoClose:3000, theme:"colored"})
                }
                return error;
            } 
        )
    }
    if (token === null || token === undefined) {
        delete axios.defaults.headers.common["Authorization"];
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = `*`;
        axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
        axios.defaults.headers.common["Access-Control-Allow-Methods"] = `GET, PUT, POST, DELETE, OPTIONS`;
    } 
}