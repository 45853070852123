import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceedForGetAll: null,
    isSucceedForGetBySubCode: null,
};
const getAllStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getAllSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getAllFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getAllReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetAll: action.isSucceedForGetAll,
    });
};
const getListBySubCodeStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getListBySubCodeSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetBySubCode: action.isSucceedForGetBySubCode,
    });
};
const getListBySubCodeFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getListBySubCodeReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceedForGetBySubCode: action.isSucceedForGetBySubCode,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_PROJECT_PAGES_START:
            return getAllStart(state,action);
        case actionTypes.GET_ALL_PROJECT_PAGES_SUCCESS:
            return getAllSuccess(state,action);
        case actionTypes.GET_ALL_PROJECT_PAGES_FAIL:
            return getAllFail(state,action);
        case actionTypes.GET_ALL_PROJECT_PAGES_RESET:
            return getAllReset(state,action);
        case actionTypes.GET_PROJECT_PAGES_BY_SUB_CODE_START:
            return getListBySubCodeStart(state,action);
        case actionTypes.GET_PROJECT_PAGES_BY_SUB_CODE_SUCCESS:
            return getListBySubCodeSuccess(state,action);
        case actionTypes.GET_PROJECT_PAGES_BY_SUB_CODE_FAIL:
            return getListBySubCodeFail(state,action);
        case actionTypes.GET_PROJECT_PAGES_BY_SUB_CODE_RESET:
            return getListBySubCodeReset(state,action);
        default:
            return state;
    };
};

export default reducer;