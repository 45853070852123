import * as actionTypes from '../types'
import {updateObject} from '../../utils/utility'

const initialState = {
    payload: null,
    errors: null,
    isLoading: false,
    isSucceed: null,    
};
const getEducationAssignmentsStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getEducationAssignmentsSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const getEducationAssignmentsFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getEducationAssignmentsReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const getQuizAssignmentsStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getQuizAssignmentsSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const getQuizAssignmentsFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getQuizAssignmentsReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const getSurveyAssignmentsStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getSurveyAssignmentsSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const getSurveyAssignmentsFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getSurveyAssignmentsReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const getSupportRequestsStart = (state, action) => {
    return updateObject(state, {
        isLoading: action.isLoading,
    });
};
const getSupportRequestsSuccess = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};
const getSupportRequestsFail = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
    });
};
const getSupportRequestsReset = (state, action) => {
    return updateObject(state, {
        payload: action.payload,
        errors: action.errors,
        isLoading: action.isLoading,
        isSucceed: action.isSucceed,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REPORT_EDUCATION_ASSIGNMENTS_START:
            return getEducationAssignmentsStart(state,action);
        case actionTypes.REPORT_EDUCATION_ASSIGNMENTS_SUCCESS:
            return getEducationAssignmentsSuccess(state,action);
        case actionTypes.REPORT_EDUCATION_ASSIGNMENTS_FAIL:
            return getEducationAssignmentsFail(state,action);
        case actionTypes.REPORT_EDUCATION_ASSIGNMENTS_RESET:
            return getEducationAssignmentsReset(state,action);
        case actionTypes.REPORT_QUIZ_ASSIGNMENTS_START:
            return getQuizAssignmentsStart(state, action);
        case actionTypes.REPORT_QUIZ_ASSIGNMENTS_SUCCESS:
            return getQuizAssignmentsSuccess(state, action);
        case actionTypes.REPORT_QUIZ_ASSIGNMENTS_FAIL:
            return getQuizAssignmentsFail(state, action);
        case actionTypes.REPORT_QUIZ_ASSIGNMENTS_RESET:
            return getQuizAssignmentsReset(state, action);
        case actionTypes.REPORT_SURVEY_ASSIGNMENTS_START:
            return getSurveyAssignmentsStart(state, action);
        case actionTypes.REPORT_SURVEY_ASSIGNMENTS_SUCCESS:
            return getSurveyAssignmentsSuccess(state, action);
        case actionTypes.REPORT_SURVEY_ASSIGNMENTS_FAIL:
            return getSurveyAssignmentsFail(state, action);
        case actionTypes.REPORT_SURVEY_ASSIGNMENTS_RESET:
            return getSurveyAssignmentsReset(state, action);
        case actionTypes.REPORT_SUPPORT_REQUESTS_START:
            return getSupportRequestsStart(state, action);
        case actionTypes.REPORT_SUPPORT_REQUESTS_SUCCESS:
            return getSupportRequestsSuccess(state, action);
        case actionTypes.REPORT_SUPPORT_REQUESTS_FAIL:
            return getSupportRequestsFail(state, action);
        case actionTypes.REPORT_SUPPORT_REQUESTS_RESET:
            return getSupportRequestsReset(state, action);
        default:
            return state;
    };
};

export default reducer;